import React from 'react';
import ComponentCreator from '@docusaurus/ComponentCreator';

export default [
  {
    path: '/docs/',
    component: ComponentCreator('/docs/', '840'),
    exact: true
  },
  {
    path: '/docs/api/',
    component: ComponentCreator('/docs/api/', '82f'),
    exact: true
  },
  {
    path: '/docs/api',
    component: ComponentCreator('/docs/api', '03c'),
    routes: [
      {
        path: '/docs/api',
        component: ComponentCreator('/docs/api', '294'),
        routes: [
          {
            path: '/docs/api',
            component: ComponentCreator('/docs/api', '2ba'),
            routes: [
              {
                path: '/docs/api/access/endpoints/application-metadata/ListAccessControlledResources',
                component: ComponentCreator('/docs/api/access/endpoints/application-metadata/ListAccessControlledResources', 'c23'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policies/AddToPolicyCollection',
                component: ComponentCreator('/docs/api/access/endpoints/policies/AddToPolicyCollection', 'aed'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policies/CreatePolicy',
                component: ComponentCreator('/docs/api/access/endpoints/policies/CreatePolicy', 'fe9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policies/CreatePolicyCollection',
                component: ComponentCreator('/docs/api/access/endpoints/policies/CreatePolicyCollection', '27d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policies/DeletePolicy',
                component: ComponentCreator('/docs/api/access/endpoints/policies/DeletePolicy', 'f9a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policies/DeletePolicyCollection',
                component: ComponentCreator('/docs/api/access/endpoints/policies/DeletePolicyCollection', '61a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policies/Evaluate',
                component: ComponentCreator('/docs/api/access/endpoints/policies/Evaluate', '896'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policies/GetOwnPolicies',
                component: ComponentCreator('/docs/api/access/endpoints/policies/GetOwnPolicies', 'bc8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policies/GetPolicy',
                component: ComponentCreator('/docs/api/access/endpoints/policies/GetPolicy', '38c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policies/GetPolicyCollection',
                component: ComponentCreator('/docs/api/access/endpoints/policies/GetPolicyCollection', '592'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policies/ListPolicies',
                component: ComponentCreator('/docs/api/access/endpoints/policies/ListPolicies', '111'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policies/ListPolicyCollections',
                component: ComponentCreator('/docs/api/access/endpoints/policies/ListPolicyCollections', '1ad'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policies/PagePolicies',
                component: ComponentCreator('/docs/api/access/endpoints/policies/PagePolicies', '9ec'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policies/PagePolicyCollections',
                component: ComponentCreator('/docs/api/access/endpoints/policies/PagePolicyCollections', 'bc8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policies/RemoveFromPolicyCollection',
                component: ComponentCreator('/docs/api/access/endpoints/policies/RemoveFromPolicyCollection', '975'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policies/UpdatePolicy',
                component: ComponentCreator('/docs/api/access/endpoints/policies/UpdatePolicy', '12f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policies/UpdatePolicyCollection',
                component: ComponentCreator('/docs/api/access/endpoints/policies/UpdatePolicyCollection', 'd82'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policy-templates/CreatePolicyTemplate',
                component: ComponentCreator('/docs/api/access/endpoints/policy-templates/CreatePolicyTemplate', '8e3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policy-templates/DeletePolicyTemplate',
                component: ComponentCreator('/docs/api/access/endpoints/policy-templates/DeletePolicyTemplate', 'fe8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policy-templates/GeneratePolicyFromTemplate',
                component: ComponentCreator('/docs/api/access/endpoints/policy-templates/GeneratePolicyFromTemplate', '733'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policy-templates/GetPolicyTemplate',
                component: ComponentCreator('/docs/api/access/endpoints/policy-templates/GetPolicyTemplate', '932'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policy-templates/ListPolicyTemplates',
                component: ComponentCreator('/docs/api/access/endpoints/policy-templates/ListPolicyTemplates', 'f05'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/policy-templates/UpdatePolicyTemplate',
                component: ComponentCreator('/docs/api/access/endpoints/policy-templates/UpdatePolicyTemplate', '6e4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/roles/AddPolicyCollectionToRole',
                component: ComponentCreator('/docs/api/access/endpoints/roles/AddPolicyCollectionToRole', 'e9b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/roles/CreateRole',
                component: ComponentCreator('/docs/api/access/endpoints/roles/CreateRole', '27d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/roles/DeleteRole',
                component: ComponentCreator('/docs/api/access/endpoints/roles/DeleteRole', '84f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/roles/GetRole',
                component: ComponentCreator('/docs/api/access/endpoints/roles/GetRole', 'b52'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/roles/ListRoles',
                component: ComponentCreator('/docs/api/access/endpoints/roles/ListRoles', '48e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/roles/RemovePolicyCollectionFromRole',
                component: ComponentCreator('/docs/api/access/endpoints/roles/RemovePolicyCollectionFromRole', 'c6e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/roles/UpdateRole',
                component: ComponentCreator('/docs/api/access/endpoints/roles/UpdateRole', '64b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/user-roles/AddPolicyCollectionToUserRole',
                component: ComponentCreator('/docs/api/access/endpoints/user-roles/AddPolicyCollectionToUserRole', '5f3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/user-roles/AddPolicyToUserRole',
                component: ComponentCreator('/docs/api/access/endpoints/user-roles/AddPolicyToUserRole', '04d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/user-roles/CreateUserRole',
                component: ComponentCreator('/docs/api/access/endpoints/user-roles/CreateUserRole', 'f9f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/user-roles/DeleteUserRole',
                component: ComponentCreator('/docs/api/access/endpoints/user-roles/DeleteUserRole', '9ff'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/user-roles/GetUserRole',
                component: ComponentCreator('/docs/api/access/endpoints/user-roles/GetUserRole', '47b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/user-roles/ListUserRoles',
                component: ComponentCreator('/docs/api/access/endpoints/user-roles/ListUserRoles', '265'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/user-roles/RemovePolicyCollectionFromUserRole',
                component: ComponentCreator('/docs/api/access/endpoints/user-roles/RemovePolicyCollectionFromUserRole', '452'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/user-roles/RemovePolicyFromUserRole',
                component: ComponentCreator('/docs/api/access/endpoints/user-roles/RemovePolicyFromUserRole', '444'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/endpoints/user-roles/UpdateUserRole',
                component: ComponentCreator('/docs/api/access/endpoints/user-roles/UpdateUserRole', '0d4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/error-codes',
                component: ComponentCreator('/docs/api/access/error-codes', 'eb3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/intro',
                component: ComponentCreator('/docs/api/access/intro', '703'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/AccessControlledAction',
                component: ComponentCreator('/docs/api/access/schemas/AccessControlledAction', 'a6f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/AccessControlledResource',
                component: ComponentCreator('/docs/api/access/schemas/AccessControlledResource', 'bd6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/ActionId',
                component: ComponentCreator('/docs/api/access/schemas/ActionId', '1dc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/AddPolicyCollectionToRoleRequest',
                component: ComponentCreator('/docs/api/access/schemas/AddPolicyCollectionToRoleRequest', '431'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/AddPolicyToRoleRequest',
                component: ComponentCreator('/docs/api/access/schemas/AddPolicyToRoleRequest', 'e32'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/AddToPolicyCollectionRequest',
                component: ComponentCreator('/docs/api/access/schemas/AddToPolicyCollectionRequest', '606'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/AsAtPredicateContract',
                component: ComponentCreator('/docs/api/access/schemas/AsAtPredicateContract', 'b83'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/AsAtRangeForSpec',
                component: ComponentCreator('/docs/api/access/schemas/AsAtRangeForSpec', '2de'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/AsAtRelative',
                component: ComponentCreator('/docs/api/access/schemas/AsAtRelative', '4cf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/AttachedPolicyDefinitionResponse',
                component: ComponentCreator('/docs/api/access/schemas/AttachedPolicyDefinitionResponse', '10e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/DateQuality',
                component: ComponentCreator('/docs/api/access/schemas/DateQuality', '6cb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/DateUnit',
                component: ComponentCreator('/docs/api/access/schemas/DateUnit', 'c93'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/EffectiveDateHasQuality',
                component: ComponentCreator('/docs/api/access/schemas/EffectiveDateHasQuality', '53e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/EffectiveDateRelative',
                component: ComponentCreator('/docs/api/access/schemas/EffectiveDateRelative', '808'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/EffectiveRange',
                component: ComponentCreator('/docs/api/access/schemas/EffectiveRange', '8ed'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/EntitlementMetadata',
                component: ComponentCreator('/docs/api/access/schemas/EntitlementMetadata', 'e31'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/EvaluationRequest',
                component: ComponentCreator('/docs/api/access/schemas/EvaluationRequest', '04e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/EvaluationResponse',
                component: ComponentCreator('/docs/api/access/schemas/EvaluationResponse', 'd3e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/EvaluationResult',
                component: ComponentCreator('/docs/api/access/schemas/EvaluationResult', '462'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/ForSpec',
                component: ComponentCreator('/docs/api/access/schemas/ForSpec', '512'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/GeneratedPolicyComponents',
                component: ComponentCreator('/docs/api/access/schemas/GeneratedPolicyComponents', 'e1a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/GeneratePolicyFromTemplateRequest',
                component: ComponentCreator('/docs/api/access/schemas/GeneratePolicyFromTemplateRequest', '064'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/Grant',
                component: ComponentCreator('/docs/api/access/schemas/Grant', '88c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/HowSpec',
                component: ComponentCreator('/docs/api/access/schemas/HowSpec', '0c1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/IdentifierPartSchema',
                component: ComponentCreator('/docs/api/access/schemas/IdentifierPartSchema', '1a3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/IdSelectorDefinition',
                component: ComponentCreator('/docs/api/access/schemas/IdSelectorDefinition', 'f6b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/IfExpression',
                component: ComponentCreator('/docs/api/access/schemas/IfExpression', '0ea'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/IfFeatureChainExpression',
                component: ComponentCreator('/docs/api/access/schemas/IfFeatureChainExpression', '2d9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/IfIdentityClaimExpression',
                component: ComponentCreator('/docs/api/access/schemas/IfIdentityClaimExpression', 'fee'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/IfIdentityScopeExpression',
                component: ComponentCreator('/docs/api/access/schemas/IfIdentityScopeExpression', 'fcc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/IfRequestHeaderExpression',
                component: ComponentCreator('/docs/api/access/schemas/IfRequestHeaderExpression', '4a8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/KeyValuePairOfStringToString',
                component: ComponentCreator('/docs/api/access/schemas/KeyValuePairOfStringToString', '31a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/Link',
                component: ComponentCreator('/docs/api/access/schemas/Link', 'b14'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/LusidProblemDetails',
                component: ComponentCreator('/docs/api/access/schemas/LusidProblemDetails', '451'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/LusidValidationProblemDetails',
                component: ComponentCreator('/docs/api/access/schemas/LusidValidationProblemDetails', '06c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/MatchAllSelectorDefinition',
                component: ComponentCreator('/docs/api/access/schemas/MatchAllSelectorDefinition', 'a29'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/MetadataExpression',
                component: ComponentCreator('/docs/api/access/schemas/MetadataExpression', 'ec2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/MetadataSelectorDefinition',
                component: ComponentCreator('/docs/api/access/schemas/MetadataSelectorDefinition', 'c3f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/NonTransitiveSupervisorRoleResource',
                component: ComponentCreator('/docs/api/access/schemas/NonTransitiveSupervisorRoleResource', 'd6f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/Operator',
                component: ComponentCreator('/docs/api/access/schemas/Operator', '3ef'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/PointInTimeSpecification',
                component: ComponentCreator('/docs/api/access/schemas/PointInTimeSpecification', '375'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/PolicyCollectionCreationRequest',
                component: ComponentCreator('/docs/api/access/schemas/PolicyCollectionCreationRequest', '2de'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/PolicyCollectionId',
                component: ComponentCreator('/docs/api/access/schemas/PolicyCollectionId', '3a9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/PolicyCollectionResponse',
                component: ComponentCreator('/docs/api/access/schemas/PolicyCollectionResponse', 'a64'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/PolicyCollectionUpdateRequest',
                component: ComponentCreator('/docs/api/access/schemas/PolicyCollectionUpdateRequest', '31b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/PolicyCreationRequest',
                component: ComponentCreator('/docs/api/access/schemas/PolicyCreationRequest', '5c0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/PolicyId',
                component: ComponentCreator('/docs/api/access/schemas/PolicyId', '662'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/PolicyIdRoleResource',
                component: ComponentCreator('/docs/api/access/schemas/PolicyIdRoleResource', '163'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/PolicyResponse',
                component: ComponentCreator('/docs/api/access/schemas/PolicyResponse', 'b6d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/PolicySelectorDefinition',
                component: ComponentCreator('/docs/api/access/schemas/PolicySelectorDefinition', '0a9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/PolicyTemplateCreationRequest',
                component: ComponentCreator('/docs/api/access/schemas/PolicyTemplateCreationRequest', '2c9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/PolicyTemplatedSelector',
                component: ComponentCreator('/docs/api/access/schemas/PolicyTemplatedSelector', '388'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/PolicyTemplateResponse',
                component: ComponentCreator('/docs/api/access/schemas/PolicyTemplateResponse', '2c9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/PolicyTemplateUpdateRequest',
                component: ComponentCreator('/docs/api/access/schemas/PolicyTemplateUpdateRequest', '727'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/PolicyType',
                component: ComponentCreator('/docs/api/access/schemas/PolicyType', '328'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/PolicyUpdateRequest',
                component: ComponentCreator('/docs/api/access/schemas/PolicyUpdateRequest', '6a0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/RelativeToDateTime',
                component: ComponentCreator('/docs/api/access/schemas/RelativeToDateTime', '836'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/RemoveFromPolicyCollectionRequest',
                component: ComponentCreator('/docs/api/access/schemas/RemoveFromPolicyCollectionRequest', '338'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/RequestDetails',
                component: ComponentCreator('/docs/api/access/schemas/RequestDetails', '087'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/RequestedActionKey',
                component: ComponentCreator('/docs/api/access/schemas/RequestedActionKey', '3dd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/ResourceDetails',
                component: ComponentCreator('/docs/api/access/schemas/ResourceDetails', '51e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/ResourceListOfAccessControlledResource',
                component: ComponentCreator('/docs/api/access/schemas/ResourceListOfAccessControlledResource', '7be'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/ResourceListOfPolicyCollectionResponse',
                component: ComponentCreator('/docs/api/access/schemas/ResourceListOfPolicyCollectionResponse', '705'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/ResourceListOfPolicyResponse',
                component: ComponentCreator('/docs/api/access/schemas/ResourceListOfPolicyResponse', '4de'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/ResourceListOfPolicyTemplateResponse',
                component: ComponentCreator('/docs/api/access/schemas/ResourceListOfPolicyTemplateResponse', '344'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/ResourceListOfUserRoleResponse',
                component: ComponentCreator('/docs/api/access/schemas/ResourceListOfUserRoleResponse', '446'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/RoleCreationRequest',
                component: ComponentCreator('/docs/api/access/schemas/RoleCreationRequest', '23a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/RoleId',
                component: ComponentCreator('/docs/api/access/schemas/RoleId', '85c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/RoleResourceRequest',
                component: ComponentCreator('/docs/api/access/schemas/RoleResourceRequest', '72f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/RoleResponse',
                component: ComponentCreator('/docs/api/access/schemas/RoleResponse', 'b8f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/RoleUpdateRequest',
                component: ComponentCreator('/docs/api/access/schemas/RoleUpdateRequest', 'a83'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/SelectorDefinition',
                component: ComponentCreator('/docs/api/access/schemas/SelectorDefinition', '7b9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/TemplateMetadata',
                component: ComponentCreator('/docs/api/access/schemas/TemplateMetadata', '3b0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/TemplateSelection',
                component: ComponentCreator('/docs/api/access/schemas/TemplateSelection', 'e6d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/TextOperator',
                component: ComponentCreator('/docs/api/access/schemas/TextOperator', 'c00'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/UserRoleCreationRequest',
                component: ComponentCreator('/docs/api/access/schemas/UserRoleCreationRequest', 'a7f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/UserRoleResponse',
                component: ComponentCreator('/docs/api/access/schemas/UserRoleResponse', '7dd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/UserRoleUpdateRequest',
                component: ComponentCreator('/docs/api/access/schemas/UserRoleUpdateRequest', '2f1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/access/schemas/WhenSpec',
                component: ComponentCreator('/docs/api/access/schemas/WhenSpec', '402'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/endpoints/application-metadata/ListAccessControlledResources',
                component: ComponentCreator('/docs/api/configuration/endpoints/application-metadata/ListAccessControlledResources', '6c5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/endpoints/configuration-sets/AddConfigurationToSet',
                component: ComponentCreator('/docs/api/configuration/endpoints/configuration-sets/AddConfigurationToSet', '220'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/endpoints/configuration-sets/CheckAccessTokenExists',
                component: ComponentCreator('/docs/api/configuration/endpoints/configuration-sets/CheckAccessTokenExists', '52b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/endpoints/configuration-sets/CreateConfigurationSet',
                component: ComponentCreator('/docs/api/configuration/endpoints/configuration-sets/CreateConfigurationSet', '8e9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/endpoints/configuration-sets/DeleteAccessToken',
                component: ComponentCreator('/docs/api/configuration/endpoints/configuration-sets/DeleteAccessToken', '0dc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/endpoints/configuration-sets/DeleteConfigurationItem',
                component: ComponentCreator('/docs/api/configuration/endpoints/configuration-sets/DeleteConfigurationItem', 'fe7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/endpoints/configuration-sets/DeleteConfigurationSet',
                component: ComponentCreator('/docs/api/configuration/endpoints/configuration-sets/DeleteConfigurationSet', '693'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/endpoints/configuration-sets/GenerateAccessToken',
                component: ComponentCreator('/docs/api/configuration/endpoints/configuration-sets/GenerateAccessToken', '694'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/endpoints/configuration-sets/GetConfigurationItem',
                component: ComponentCreator('/docs/api/configuration/endpoints/configuration-sets/GetConfigurationItem', 'f58'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/endpoints/configuration-sets/GetConfigurationSet',
                component: ComponentCreator('/docs/api/configuration/endpoints/configuration-sets/GetConfigurationSet', 'd1e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/endpoints/configuration-sets/GetSystemConfigurationItems',
                component: ComponentCreator('/docs/api/configuration/endpoints/configuration-sets/GetSystemConfigurationItems', 'baa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/endpoints/configuration-sets/GetSystemConfigurationSets',
                component: ComponentCreator('/docs/api/configuration/endpoints/configuration-sets/GetSystemConfigurationSets', 'a72'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/endpoints/configuration-sets/ListConfigurationSets',
                component: ComponentCreator('/docs/api/configuration/endpoints/configuration-sets/ListConfigurationSets', '275'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/endpoints/configuration-sets/UpdateConfigurationItem',
                component: ComponentCreator('/docs/api/configuration/endpoints/configuration-sets/UpdateConfigurationItem', 'ac3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/endpoints/configuration-sets/UpdateConfigurationSet',
                component: ComponentCreator('/docs/api/configuration/endpoints/configuration-sets/UpdateConfigurationSet', '2b5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/error-codes',
                component: ComponentCreator('/docs/api/configuration/error-codes', '753'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/intro',
                component: ComponentCreator('/docs/api/configuration/intro', '79d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/AccessControlledAction',
                component: ComponentCreator('/docs/api/configuration/schemas/AccessControlledAction', 'c46'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/AccessControlledResource',
                component: ComponentCreator('/docs/api/configuration/schemas/AccessControlledResource', 'bb3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/ActionId',
                component: ComponentCreator('/docs/api/configuration/schemas/ActionId', '3e1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/ConfigurationItem',
                component: ComponentCreator('/docs/api/configuration/schemas/ConfigurationItem', '951'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/ConfigurationItemSummary',
                component: ComponentCreator('/docs/api/configuration/schemas/ConfigurationItemSummary', 'cfc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/ConfigurationSet',
                component: ComponentCreator('/docs/api/configuration/schemas/ConfigurationSet', '2d1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/ConfigurationSetSummary',
                component: ComponentCreator('/docs/api/configuration/schemas/ConfigurationSetSummary', '9cb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/CreateConfigurationItem',
                component: ComponentCreator('/docs/api/configuration/schemas/CreateConfigurationItem', 'a15'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/CreateConfigurationSet',
                component: ComponentCreator('/docs/api/configuration/schemas/CreateConfigurationSet', '6cc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/IdentifierPartSchema',
                component: ComponentCreator('/docs/api/configuration/schemas/IdentifierPartSchema', 'b4f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/IdSelectorDefinition',
                component: ComponentCreator('/docs/api/configuration/schemas/IdSelectorDefinition', '8a9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/Link',
                component: ComponentCreator('/docs/api/configuration/schemas/Link', 'f0c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/LusidProblemDetails',
                component: ComponentCreator('/docs/api/configuration/schemas/LusidProblemDetails', '48a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/LusidValidationProblemDetails',
                component: ComponentCreator('/docs/api/configuration/schemas/LusidValidationProblemDetails', '1de'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/PersonalAccessToken',
                component: ComponentCreator('/docs/api/configuration/schemas/PersonalAccessToken', '419'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/ResourceId',
                component: ComponentCreator('/docs/api/configuration/schemas/ResourceId', 'c6b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/ResourceListOfAccessControlledResource',
                component: ComponentCreator('/docs/api/configuration/schemas/ResourceListOfAccessControlledResource', '503'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/ResourceListOfConfigurationItem',
                component: ComponentCreator('/docs/api/configuration/schemas/ResourceListOfConfigurationItem', '5e3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/ResourceListOfConfigurationSet',
                component: ComponentCreator('/docs/api/configuration/schemas/ResourceListOfConfigurationSet', '51a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/ResourceListOfConfigurationSetSummary',
                component: ComponentCreator('/docs/api/configuration/schemas/ResourceListOfConfigurationSetSummary', '532'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/UpdateConfigurationItem',
                component: ComponentCreator('/docs/api/configuration/schemas/UpdateConfigurationItem', '601'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/configuration/schemas/UpdateConfigurationSet',
                component: ComponentCreator('/docs/api/configuration/schemas/UpdateConfigurationSet', '98c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/endpoints/application-metadata/ListAccessControlledResources',
                component: ComponentCreator('/docs/api/drive/endpoints/application-metadata/ListAccessControlledResources', '680'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/endpoints/files/CreateFile',
                component: ComponentCreator('/docs/api/drive/endpoints/files/CreateFile', '2d5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/endpoints/files/DeleteFile',
                component: ComponentCreator('/docs/api/drive/endpoints/files/DeleteFile', '29f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/endpoints/files/DownloadFile',
                component: ComponentCreator('/docs/api/drive/endpoints/files/DownloadFile', 'aa4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/endpoints/files/GetFile',
                component: ComponentCreator('/docs/api/drive/endpoints/files/GetFile', 'c3c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/endpoints/files/UpdateFileContents',
                component: ComponentCreator('/docs/api/drive/endpoints/files/UpdateFileContents', '5c8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/endpoints/files/UpdateFileMetadata',
                component: ComponentCreator('/docs/api/drive/endpoints/files/UpdateFileMetadata', '552'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/endpoints/folders/CreateFolder',
                component: ComponentCreator('/docs/api/drive/endpoints/folders/CreateFolder', '289'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/endpoints/folders/DeleteFolder',
                component: ComponentCreator('/docs/api/drive/endpoints/folders/DeleteFolder', '21a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/endpoints/folders/GetFolder',
                component: ComponentCreator('/docs/api/drive/endpoints/folders/GetFolder', '233'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/endpoints/folders/GetFolderContents',
                component: ComponentCreator('/docs/api/drive/endpoints/folders/GetFolderContents', 'ed9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/endpoints/folders/GetRootFolder',
                component: ComponentCreator('/docs/api/drive/endpoints/folders/GetRootFolder', 'c13'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/endpoints/folders/MoveFolder',
                component: ComponentCreator('/docs/api/drive/endpoints/folders/MoveFolder', '1d6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/endpoints/folders/UpdateFolder',
                component: ComponentCreator('/docs/api/drive/endpoints/folders/UpdateFolder', 'b5e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/endpoints/search/Search',
                component: ComponentCreator('/docs/api/drive/endpoints/search/Search', '328'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/error-codes',
                component: ComponentCreator('/docs/api/drive/error-codes', '5ab'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/intro',
                component: ComponentCreator('/docs/api/drive/intro', '966'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/schemas/AccessControlledAction',
                component: ComponentCreator('/docs/api/drive/schemas/AccessControlledAction', '76a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/schemas/AccessControlledResource',
                component: ComponentCreator('/docs/api/drive/schemas/AccessControlledResource', '10b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/schemas/ActionId',
                component: ComponentCreator('/docs/api/drive/schemas/ActionId', '72b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/schemas/CreateFolder',
                component: ComponentCreator('/docs/api/drive/schemas/CreateFolder', 'e67'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/schemas/IdentifierPartSchema',
                component: ComponentCreator('/docs/api/drive/schemas/IdentifierPartSchema', '541'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/schemas/IdSelectorDefinition',
                component: ComponentCreator('/docs/api/drive/schemas/IdSelectorDefinition', 'af8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/schemas/Link',
                component: ComponentCreator('/docs/api/drive/schemas/Link', '9ae'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/schemas/LusidProblemDetails',
                component: ComponentCreator('/docs/api/drive/schemas/LusidProblemDetails', 'b44'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/schemas/LusidValidationProblemDetails',
                component: ComponentCreator('/docs/api/drive/schemas/LusidValidationProblemDetails', '1e9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/schemas/PagedResourceListOfStorageObject',
                component: ComponentCreator('/docs/api/drive/schemas/PagedResourceListOfStorageObject', '876'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/schemas/ResourceListOfAccessControlledResource',
                component: ComponentCreator('/docs/api/drive/schemas/ResourceListOfAccessControlledResource', 'ca6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/schemas/SearchBody',
                component: ComponentCreator('/docs/api/drive/schemas/SearchBody', 'cd2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/schemas/StorageObject',
                component: ComponentCreator('/docs/api/drive/schemas/StorageObject', '9f5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/schemas/UpdateFile',
                component: ComponentCreator('/docs/api/drive/schemas/UpdateFile', '281'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/drive/schemas/UpdateFolder',
                component: ComponentCreator('/docs/api/drive/schemas/UpdateFolder', 'b2a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/instrument/CreateInstrument',
                component: ComponentCreator('/docs/api/horizon/endpoints/instrument/CreateInstrument', '9f6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/instrument/EnrichInstrument',
                component: ComponentCreator('/docs/api/horizon/endpoints/instrument/EnrichInstrument', '875'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/instrument/GetOpenFigiParameterOption',
                component: ComponentCreator('/docs/api/horizon/endpoints/instrument/GetOpenFigiParameterOption', 'e5f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/instrument/RetrievePermIdResult',
                component: ComponentCreator('/docs/api/horizon/endpoints/instrument/RetrievePermIdResult', 'ac0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/instrument/SearchOpenFigi',
                component: ComponentCreator('/docs/api/horizon/endpoints/instrument/SearchOpenFigi', 'd23'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/instrument/Vendors',
                component: ComponentCreator('/docs/api/horizon/endpoints/instrument/Vendors', 'e0d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/integrations/CreateInstance',
                component: ComponentCreator('/docs/api/horizon/endpoints/integrations/CreateInstance', '4ea'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/integrations/DeleteInstance',
                component: ComponentCreator('/docs/api/horizon/endpoints/integrations/DeleteInstance', '6fb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/integrations/ExecuteInstance',
                component: ComponentCreator('/docs/api/horizon/endpoints/integrations/ExecuteInstance', 'd1e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/integrations/ExecuteInstanceWithParams',
                component: ComponentCreator('/docs/api/horizon/endpoints/integrations/ExecuteInstanceWithParams', '42b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/integrations/GetExecutionIdsForInstance',
                component: ComponentCreator('/docs/api/horizon/endpoints/integrations/GetExecutionIdsForInstance', '9bb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/integrations/GetInstanceOptionalPropertyMapping',
                component: ComponentCreator('/docs/api/horizon/endpoints/integrations/GetInstanceOptionalPropertyMapping', '745'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/integrations/GetIntegrationConfiguration',
                component: ComponentCreator('/docs/api/horizon/endpoints/integrations/GetIntegrationConfiguration', '21f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/integrations/GetSchema',
                component: ComponentCreator('/docs/api/horizon/endpoints/integrations/GetSchema', 'a12'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/integrations/ListInstances',
                component: ComponentCreator('/docs/api/horizon/endpoints/integrations/ListInstances', 'f09'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/integrations/ListIntegrations',
                component: ComponentCreator('/docs/api/horizon/endpoints/integrations/ListIntegrations', 'a2e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/integrations/SetInstanceOptionalPropertyMapping',
                component: ComponentCreator('/docs/api/horizon/endpoints/integrations/SetInstanceOptionalPropertyMapping', '529'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/integrations/UpdateInstance',
                component: ComponentCreator('/docs/api/horizon/endpoints/integrations/UpdateInstance', 'dd7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/logs/GetIntegrationLogResults',
                component: ComponentCreator('/docs/api/horizon/endpoints/logs/GetIntegrationLogResults', '18f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/logs/InsertExternalLogs',
                component: ComponentCreator('/docs/api/horizon/endpoints/logs/InsertExternalLogs', 'bd9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/process-history/CreateCompleteEvent',
                component: ComponentCreator('/docs/api/horizon/endpoints/process-history/CreateCompleteEvent', '5bd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/process-history/CreateUpdateEvent',
                component: ComponentCreator('/docs/api/horizon/endpoints/process-history/CreateUpdateEvent', 'ef2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/process-history/GetLatestRuns',
                component: ComponentCreator('/docs/api/horizon/endpoints/process-history/GetLatestRuns', '077'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/process-history/ProcessEntryUpdates',
                component: ComponentCreator('/docs/api/horizon/endpoints/process-history/ProcessEntryUpdates', 'feb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/process-history/ProcessHistoryEntries',
                component: ComponentCreator('/docs/api/horizon/endpoints/process-history/ProcessHistoryEntries', 'a59'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/runs/CancelInstance',
                component: ComponentCreator('/docs/api/horizon/endpoints/runs/CancelInstance', 'bf4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/runs/GetRunResults',
                component: ComponentCreator('/docs/api/horizon/endpoints/runs/GetRunResults', '657'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/runs/RerunInstance',
                component: ComponentCreator('/docs/api/horizon/endpoints/runs/RerunInstance', '968'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/runs/StopInstanceExecution',
                component: ComponentCreator('/docs/api/horizon/endpoints/runs/StopInstanceExecution', '28e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/vendor/GetCoreFieldMappingsForProductEntity',
                component: ComponentCreator('/docs/api/horizon/endpoints/vendor/GetCoreFieldMappingsForProductEntity', 'c45'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/vendor/GetOptionalMappingsForProductEntity',
                component: ComponentCreator('/docs/api/horizon/endpoints/vendor/GetOptionalMappingsForProductEntity', '8bb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/vendor/GetPropertyMappingsForProductEntity',
                component: ComponentCreator('/docs/api/horizon/endpoints/vendor/GetPropertyMappingsForProductEntity', 'dcd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/vendor/QueryVendors',
                component: ComponentCreator('/docs/api/horizon/endpoints/vendor/QueryVendors', '6dd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/endpoints/vendor/SetOptionalMappingsForProductEntity',
                component: ComponentCreator('/docs/api/horizon/endpoints/vendor/SetOptionalMappingsForProductEntity', 'bdb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/intro',
                component: ComponentCreator('/docs/api/horizon/intro', '1dd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/AllowedParameterValue',
                component: ComponentCreator('/docs/api/horizon/schemas/AllowedParameterValue', '221'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/AuditCompleteRequest',
                component: ComponentCreator('/docs/api/horizon/schemas/AuditCompleteRequest', '937'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/AuditCompleteResponse',
                component: ComponentCreator('/docs/api/horizon/schemas/AuditCompleteResponse', 'f01'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/AuditCompleteStatus',
                component: ComponentCreator('/docs/api/horizon/schemas/AuditCompleteStatus', '851'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/AuditFileDetails',
                component: ComponentCreator('/docs/api/horizon/schemas/AuditFileDetails', 'ed0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/AuditFileType',
                component: ComponentCreator('/docs/api/horizon/schemas/AuditFileType', 'f84'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/AuditUpdateRequest',
                component: ComponentCreator('/docs/api/horizon/schemas/AuditUpdateRequest', '56d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/AuditUpdateResponse',
                component: ComponentCreator('/docs/api/horizon/schemas/AuditUpdateResponse', 'ffd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/CreateInstanceRequest',
                component: ComponentCreator('/docs/api/horizon/schemas/CreateInstanceRequest', '9b2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/EnrichmentResponse',
                component: ComponentCreator('/docs/api/horizon/schemas/EnrichmentResponse', '05a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/ExecuteInstanceResponse',
                component: ComponentCreator('/docs/api/horizon/schemas/ExecuteInstanceResponse', 'eca'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/ExternalLogInsertionRequest',
                component: ComponentCreator('/docs/api/horizon/schemas/ExternalLogInsertionRequest', '847'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/ExternalLogInsertionResponse',
                component: ComponentCreator('/docs/api/horizon/schemas/ExternalLogInsertionResponse', 'a3d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/ExternalLogRecord',
                component: ComponentCreator('/docs/api/horizon/schemas/ExternalLogRecord', '7b8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/FieldMapping',
                component: ComponentCreator('/docs/api/horizon/schemas/FieldMapping', '232'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/FileDetails',
                component: ComponentCreator('/docs/api/horizon/schemas/FileDetails', 'dbe'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/Identifiers',
                component: ComponentCreator('/docs/api/horizon/schemas/Identifiers', '053'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/IIntegrationLogResponse',
                component: ComponentCreator('/docs/api/horizon/schemas/IIntegrationLogResponse', '324'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/InstanceDetails',
                component: ComponentCreator('/docs/api/horizon/schemas/InstanceDetails', 'c86'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/InstanceExecutionReferenceId',
                component: ComponentCreator('/docs/api/horizon/schemas/InstanceExecutionReferenceId', 'ed8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/InstanceIdentifier',
                component: ComponentCreator('/docs/api/horizon/schemas/InstanceIdentifier', 'd2d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/IntegrationCancellationResponse',
                component: ComponentCreator('/docs/api/horizon/schemas/IntegrationCancellationResponse', '9d3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/IntegrationDescription',
                component: ComponentCreator('/docs/api/horizon/schemas/IntegrationDescription', '499'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/IntegrationEndResponse',
                component: ComponentCreator('/docs/api/horizon/schemas/IntegrationEndResponse', 'd85'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/IntegrationInstance',
                component: ComponentCreator('/docs/api/horizon/schemas/IntegrationInstance', '033'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/IntegrationLogActivity',
                component: ComponentCreator('/docs/api/horizon/schemas/IntegrationLogActivity', '78e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/IntegrationLogRecord',
                component: ComponentCreator('/docs/api/horizon/schemas/IntegrationLogRecord', 'a1d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/IntegrationLogTargetRecord',
                component: ComponentCreator('/docs/api/horizon/schemas/IntegrationLogTargetRecord', '9b9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/IntegrationPropertyConfiguration',
                component: ComponentCreator('/docs/api/horizon/schemas/IntegrationPropertyConfiguration', '6d3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/IntegrationRerunResponse',
                component: ComponentCreator('/docs/api/horizon/schemas/IntegrationRerunResponse', '680'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/IntegrationRunIntegration',
                component: ComponentCreator('/docs/api/horizon/schemas/IntegrationRunIntegration', '735'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/IntegrationRunLog',
                component: ComponentCreator('/docs/api/horizon/schemas/IntegrationRunLog', '8df'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/IntegrationRunLogLink',
                component: ComponentCreator('/docs/api/horizon/schemas/IntegrationRunLogLink', 'a70'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/IntegrationRunResponse',
                component: ComponentCreator('/docs/api/horizon/schemas/IntegrationRunResponse', '5a7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/IntegrationRunVersion',
                component: ComponentCreator('/docs/api/horizon/schemas/IntegrationRunVersion', 'fca'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/JSchema',
                component: ComponentCreator('/docs/api/horizon/schemas/JSchema', 'a7b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/JSchemaType',
                component: ComponentCreator('/docs/api/horizon/schemas/JSchemaType', 'e39'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/JsonValidator',
                component: ComponentCreator('/docs/api/horizon/schemas/JsonValidator', '9e4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/Link',
                component: ComponentCreator('/docs/api/horizon/schemas/Link', 'd78'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/LusidEntity',
                component: ComponentCreator('/docs/api/horizon/schemas/LusidEntity', 'e8f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/LusidField',
                component: ComponentCreator('/docs/api/horizon/schemas/LusidField', '050'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/LusidProblemDetails',
                component: ComponentCreator('/docs/api/horizon/schemas/LusidProblemDetails', '1db'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/LusidPropertyDefinition',
                component: ComponentCreator('/docs/api/horizon/schemas/LusidPropertyDefinition', 'a49'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/LusidPropertyDefinitionOverrides',
                component: ComponentCreator('/docs/api/horizon/schemas/LusidPropertyDefinitionOverrides', 'e1e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/LusidPropertyDefinitionOverridesByType',
                component: ComponentCreator('/docs/api/horizon/schemas/LusidPropertyDefinitionOverridesByType', 'e1f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/LusidPropertyDefinitionOverridesResponse',
                component: ComponentCreator('/docs/api/horizon/schemas/LusidPropertyDefinitionOverridesResponse', '33e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/LusidPropertyToVendorFieldMapping',
                component: ComponentCreator('/docs/api/horizon/schemas/LusidPropertyToVendorFieldMapping', '503'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/LusidValidationProblemDetails',
                component: ComponentCreator('/docs/api/horizon/schemas/LusidValidationProblemDetails', '86b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/OnboardInstrumentRequest',
                component: ComponentCreator('/docs/api/horizon/schemas/OnboardInstrumentRequest', 'f91'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/OnboardInstrumentResponse',
                component: ComponentCreator('/docs/api/horizon/schemas/OnboardInstrumentResponse', '44b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/OpenFigiData',
                component: ComponentCreator('/docs/api/horizon/schemas/OpenFigiData', '907'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/OpenFigiParameterOptionName',
                component: ComponentCreator('/docs/api/horizon/schemas/OpenFigiParameterOptionName', 'a10'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/OpenFigiPermIdResult',
                component: ComponentCreator('/docs/api/horizon/schemas/OpenFigiPermIdResult', '74f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/OpenFigiSearchResult',
                component: ComponentCreator('/docs/api/horizon/schemas/OpenFigiSearchResult', '33e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/Optionality',
                component: ComponentCreator('/docs/api/horizon/schemas/Optionality', '730'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/PagedResourceListOfIIntegrationLogResponse',
                component: ComponentCreator('/docs/api/horizon/schemas/PagedResourceListOfIIntegrationLogResponse', '3fd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/PagedResourceListOfIntegrationRunResponse',
                component: ComponentCreator('/docs/api/horizon/schemas/PagedResourceListOfIntegrationRunResponse', 'e0e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/PagedResourceListOfProcessInformation',
                component: ComponentCreator('/docs/api/horizon/schemas/PagedResourceListOfProcessInformation', 'ea4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/PagedResourceListOfProcessUpdateResult',
                component: ComponentCreator('/docs/api/horizon/schemas/PagedResourceListOfProcessUpdateResult', '955'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/PagedResourceListOfVendorProduct',
                component: ComponentCreator('/docs/api/horizon/schemas/PagedResourceListOfVendorProduct', '4d7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/PermIdData',
                component: ComponentCreator('/docs/api/horizon/schemas/PermIdData', '195'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/ProcessInformation',
                component: ComponentCreator('/docs/api/horizon/schemas/ProcessInformation', '077'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/ProcessSummary',
                component: ComponentCreator('/docs/api/horizon/schemas/ProcessSummary', 'c1e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/ProcessUpdateResult',
                component: ComponentCreator('/docs/api/horizon/schemas/ProcessUpdateResult', 'ccb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/PropertyMapping',
                component: ComponentCreator('/docs/api/horizon/schemas/PropertyMapping', '190'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/QueryRequest',
                component: ComponentCreator('/docs/api/horizon/schemas/QueryRequest', 'bdf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/QuerySpecification',
                component: ComponentCreator('/docs/api/horizon/schemas/QuerySpecification', '138'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/ResourceId',
                component: ComponentCreator('/docs/api/horizon/schemas/ResourceId', '5cc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/RowDetails',
                component: ComponentCreator('/docs/api/horizon/schemas/RowDetails', 'c66'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/Trigger',
                component: ComponentCreator('/docs/api/horizon/schemas/Trigger', '632'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/UpdateInstanceRequest',
                component: ComponentCreator('/docs/api/horizon/schemas/UpdateInstanceRequest', '0fa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/VendorField',
                component: ComponentCreator('/docs/api/horizon/schemas/VendorField', '92a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/horizon/schemas/VendorProduct',
                component: ComponentCreator('/docs/api/horizon/schemas/VendorProduct', '7ec'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/application-metadata/ListAccessControlledResources',
                component: ComponentCreator('/docs/api/identity/endpoints/application-metadata/ListAccessControlledResources', '368'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/applications/CreateApplication',
                component: ComponentCreator('/docs/api/identity/endpoints/applications/CreateApplication', 'bd0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/applications/DeleteApplication',
                component: ComponentCreator('/docs/api/identity/endpoints/applications/DeleteApplication', '10f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/applications/GetApplication',
                component: ComponentCreator('/docs/api/identity/endpoints/applications/GetApplication', 'c74'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/applications/ListApplications',
                component: ComponentCreator('/docs/api/identity/endpoints/applications/ListApplications', 'd75'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/applications/RotateApplicationSecrets',
                component: ComponentCreator('/docs/api/identity/endpoints/applications/RotateApplicationSecrets', 'c97'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/authentication/GetAuthenticationInformation',
                component: ComponentCreator('/docs/api/identity/endpoints/authentication/GetAuthenticationInformation', 'cd0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/authentication/GetPasswordPolicy',
                component: ComponentCreator('/docs/api/identity/endpoints/authentication/GetPasswordPolicy', '048'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/authentication/GetSupportAccessHistory',
                component: ComponentCreator('/docs/api/identity/endpoints/authentication/GetSupportAccessHistory', 'aae'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/authentication/GetSupportRoles',
                component: ComponentCreator('/docs/api/identity/endpoints/authentication/GetSupportRoles', 'b0a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/authentication/GrantSupportAccess',
                component: ComponentCreator('/docs/api/identity/endpoints/authentication/GrantSupportAccess', '0a8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/authentication/InvalidateSupportAccess',
                component: ComponentCreator('/docs/api/identity/endpoints/authentication/InvalidateSupportAccess', 'e03'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/authentication/UpdatePasswordPolicy',
                component: ComponentCreator('/docs/api/identity/endpoints/authentication/UpdatePasswordPolicy', '236'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/identity-logs/ListLogs',
                component: ComponentCreator('/docs/api/identity/endpoints/identity-logs/ListLogs', 'c1d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/identity-logs/ListUserLogs',
                component: ComponentCreator('/docs/api/identity/endpoints/identity-logs/ListUserLogs', 'f40'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/identity-provider/AddScim',
                component: ComponentCreator('/docs/api/identity/endpoints/identity-provider/AddScim', '463'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/identity-provider/RemoveScim',
                component: ComponentCreator('/docs/api/identity/endpoints/identity-provider/RemoveScim', '6d3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/me/GetUserInfo',
                component: ComponentCreator('/docs/api/identity/endpoints/me/GetUserInfo', 'a6d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/me/SetPassword',
                component: ComponentCreator('/docs/api/identity/endpoints/me/SetPassword', '62b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/network-zones/CreateNetworkZone',
                component: ComponentCreator('/docs/api/identity/endpoints/network-zones/CreateNetworkZone', '18e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/network-zones/DeleteNetworkZone',
                component: ComponentCreator('/docs/api/identity/endpoints/network-zones/DeleteNetworkZone', 'd8b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/network-zones/GetNetworkZone',
                component: ComponentCreator('/docs/api/identity/endpoints/network-zones/GetNetworkZone', '6e4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/network-zones/ListNetworkZones',
                component: ComponentCreator('/docs/api/identity/endpoints/network-zones/ListNetworkZones', '8d4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/network-zones/UpdateNetworkZone',
                component: ComponentCreator('/docs/api/identity/endpoints/network-zones/UpdateNetworkZone', '487'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/personal-authentication-tokens/CreateApiKey',
                component: ComponentCreator('/docs/api/identity/endpoints/personal-authentication-tokens/CreateApiKey', '1c5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/personal-authentication-tokens/DeleteApiKey',
                component: ComponentCreator('/docs/api/identity/endpoints/personal-authentication-tokens/DeleteApiKey', 'b76'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/personal-authentication-tokens/ListOwnApiKeys',
                component: ComponentCreator('/docs/api/identity/endpoints/personal-authentication-tokens/ListOwnApiKeys', '176'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/roles/AddUserToRole',
                component: ComponentCreator('/docs/api/identity/endpoints/roles/AddUserToRole', '84e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/roles/CreateRole',
                component: ComponentCreator('/docs/api/identity/endpoints/roles/CreateRole', '9e7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/roles/DeleteRole',
                component: ComponentCreator('/docs/api/identity/endpoints/roles/DeleteRole', 'b0e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/roles/GetRole',
                component: ComponentCreator('/docs/api/identity/endpoints/roles/GetRole', '464'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/roles/ListRoles',
                component: ComponentCreator('/docs/api/identity/endpoints/roles/ListRoles', 'b86'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/roles/ListUsersInRole',
                component: ComponentCreator('/docs/api/identity/endpoints/roles/ListUsersInRole', '0b8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/roles/RemoveUserFromRole',
                component: ComponentCreator('/docs/api/identity/endpoints/roles/RemoveUserFromRole', 'd61'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/roles/UpdateRole',
                component: ComponentCreator('/docs/api/identity/endpoints/roles/UpdateRole', '4e5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/tokens/InvalidateToken',
                component: ComponentCreator('/docs/api/identity/endpoints/tokens/InvalidateToken', 'b94'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/users/CreateUser',
                component: ComponentCreator('/docs/api/identity/endpoints/users/CreateUser', 'a27'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/users/DeleteUser',
                component: ComponentCreator('/docs/api/identity/endpoints/users/DeleteUser', '228'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/users/ExpirePassword',
                component: ComponentCreator('/docs/api/identity/endpoints/users/ExpirePassword', '85c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/users/FindUsersById',
                component: ComponentCreator('/docs/api/identity/endpoints/users/FindUsersById', 'f2d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/users/GetUser',
                component: ComponentCreator('/docs/api/identity/endpoints/users/GetUser', '548'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/users/GetUserSchema',
                component: ComponentCreator('/docs/api/identity/endpoints/users/GetUserSchema', '9ca'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/users/ListRunnableUsers',
                component: ComponentCreator('/docs/api/identity/endpoints/users/ListRunnableUsers', '9aa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/users/ListUsers',
                component: ComponentCreator('/docs/api/identity/endpoints/users/ListUsers', '80e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/users/ResetFactors',
                component: ComponentCreator('/docs/api/identity/endpoints/users/ResetFactors', 'a06'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/users/ResetPassword',
                component: ComponentCreator('/docs/api/identity/endpoints/users/ResetPassword', '1e8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/users/SendActivationEmail',
                component: ComponentCreator('/docs/api/identity/endpoints/users/SendActivationEmail', 'b47'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/users/UnlockUser',
                component: ComponentCreator('/docs/api/identity/endpoints/users/UnlockUser', '2b2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/users/UnsuspendUser',
                component: ComponentCreator('/docs/api/identity/endpoints/users/UnsuspendUser', 'a2c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/users/UpdateUser',
                component: ComponentCreator('/docs/api/identity/endpoints/users/UpdateUser', '64d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/endpoints/users/UpdateUserSchema',
                component: ComponentCreator('/docs/api/identity/endpoints/users/UpdateUserSchema', 'ce3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/error-codes',
                component: ComponentCreator('/docs/api/identity/error-codes', '26a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/intro',
                component: ComponentCreator('/docs/api/identity/intro', '941'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/AccessControlledAction',
                component: ComponentCreator('/docs/api/identity/schemas/AccessControlledAction', 'ec3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/AccessControlledResource',
                component: ComponentCreator('/docs/api/identity/schemas/AccessControlledResource', 'd63'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/ActionId',
                component: ComponentCreator('/docs/api/identity/schemas/ActionId', 'b1b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/AddScimResponse',
                component: ComponentCreator('/docs/api/identity/schemas/AddScimResponse', 'f44'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/ApiKey',
                component: ComponentCreator('/docs/api/identity/schemas/ApiKey', 'fe0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/AuthenticationInformation',
                component: ComponentCreator('/docs/api/identity/schemas/AuthenticationInformation', 'c8b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/CreateApiKey',
                component: ComponentCreator('/docs/api/identity/schemas/CreateApiKey', '4d3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/CreateApplicationRequest',
                component: ComponentCreator('/docs/api/identity/schemas/CreateApplicationRequest', '6a5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/CreatedApiKey',
                component: ComponentCreator('/docs/api/identity/schemas/CreatedApiKey', 'bd8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/CreateNetworkZoneRequest',
                component: ComponentCreator('/docs/api/identity/schemas/CreateNetworkZoneRequest', 'de7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/CreateRoleRequest',
                component: ComponentCreator('/docs/api/identity/schemas/CreateRoleRequest', '3e3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/CreateUserRequest',
                component: ComponentCreator('/docs/api/identity/schemas/CreateUserRequest', 'ef5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/CurrentUserResponse',
                component: ComponentCreator('/docs/api/identity/schemas/CurrentUserResponse', 'ac8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/ErrorDetail',
                component: ComponentCreator('/docs/api/identity/schemas/ErrorDetail', '3b8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/IdentifierPartSchema',
                component: ComponentCreator('/docs/api/identity/schemas/IdentifierPartSchema', '111'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/IdSelectorDefinition',
                component: ComponentCreator('/docs/api/identity/schemas/IdSelectorDefinition', 'f74'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/IpAddressDefinition',
                component: ComponentCreator('/docs/api/identity/schemas/IpAddressDefinition', '56d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/Link',
                component: ComponentCreator('/docs/api/identity/schemas/Link', '693'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/ListUsersResponse',
                component: ComponentCreator('/docs/api/identity/schemas/ListUsersResponse', '1ca'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/LogActor',
                component: ComponentCreator('/docs/api/identity/schemas/LogActor', '903'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/LogAuthenticationContext',
                component: ComponentCreator('/docs/api/identity/schemas/LogAuthenticationContext', '738'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/LogClientInfo',
                component: ComponentCreator('/docs/api/identity/schemas/LogClientInfo', '22d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/LogDebugContext',
                component: ComponentCreator('/docs/api/identity/schemas/LogDebugContext', 'db8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/LogGeographicalContext',
                component: ComponentCreator('/docs/api/identity/schemas/LogGeographicalContext', 'be9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/LogGeolocation',
                component: ComponentCreator('/docs/api/identity/schemas/LogGeolocation', '823'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/LogIpChainEntry',
                component: ComponentCreator('/docs/api/identity/schemas/LogIpChainEntry', '41f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/LogIssuer',
                component: ComponentCreator('/docs/api/identity/schemas/LogIssuer', 'e01'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/LogOutcome',
                component: ComponentCreator('/docs/api/identity/schemas/LogOutcome', '92d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/LogRequest',
                component: ComponentCreator('/docs/api/identity/schemas/LogRequest', 'ca7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/LogSecurityContext',
                component: ComponentCreator('/docs/api/identity/schemas/LogSecurityContext', '9ce'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/LogSeverity',
                component: ComponentCreator('/docs/api/identity/schemas/LogSeverity', '9cd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/LogTarget',
                component: ComponentCreator('/docs/api/identity/schemas/LogTarget', '87a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/LogTransaction',
                component: ComponentCreator('/docs/api/identity/schemas/LogTransaction', 'a0e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/LogUserAgent',
                component: ComponentCreator('/docs/api/identity/schemas/LogUserAgent', 'a26'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/LusidProblemDetails',
                component: ComponentCreator('/docs/api/identity/schemas/LusidProblemDetails', '0c4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/LusidValidationProblemDetails',
                component: ComponentCreator('/docs/api/identity/schemas/LusidValidationProblemDetails', '701'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/NetworkZoneDefinitionResponse',
                component: ComponentCreator('/docs/api/identity/schemas/NetworkZoneDefinitionResponse', '273'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/NetworkZonesApplyRules',
                component: ComponentCreator('/docs/api/identity/schemas/NetworkZonesApplyRules', '452'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/OAuthApplication',
                component: ComponentCreator('/docs/api/identity/schemas/OAuthApplication', 'f5e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/PasswordPolicyResponse',
                component: ComponentCreator('/docs/api/identity/schemas/PasswordPolicyResponse', '104'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/PasswordPolicyResponseAge',
                component: ComponentCreator('/docs/api/identity/schemas/PasswordPolicyResponseAge', '695'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/PasswordPolicyResponseComplexity',
                component: ComponentCreator('/docs/api/identity/schemas/PasswordPolicyResponseComplexity', 'd23'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/PasswordPolicyResponseConditions',
                component: ComponentCreator('/docs/api/identity/schemas/PasswordPolicyResponseConditions', '851'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/PasswordPolicyResponseLockout',
                component: ComponentCreator('/docs/api/identity/schemas/PasswordPolicyResponseLockout', '6b4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/ResourceListOfAccessControlledResource',
                component: ComponentCreator('/docs/api/identity/schemas/ResourceListOfAccessControlledResource', '63d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/ResourceListOfSystemLog',
                component: ComponentCreator('/docs/api/identity/schemas/ResourceListOfSystemLog', '503'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/RoleId',
                component: ComponentCreator('/docs/api/identity/schemas/RoleId', '53b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/RoleResponse',
                component: ComponentCreator('/docs/api/identity/schemas/RoleResponse', '6c0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/SetPassword',
                component: ComponentCreator('/docs/api/identity/schemas/SetPassword', '04d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/SetPasswordResponse',
                component: ComponentCreator('/docs/api/identity/schemas/SetPasswordResponse', '3c7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/SupportAccessExpiry',
                component: ComponentCreator('/docs/api/identity/schemas/SupportAccessExpiry', '0af'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/SupportAccessExpiryWithRole',
                component: ComponentCreator('/docs/api/identity/schemas/SupportAccessExpiryWithRole', '9ed'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/SupportAccessRequest',
                component: ComponentCreator('/docs/api/identity/schemas/SupportAccessRequest', '93d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/SupportAccessResponse',
                component: ComponentCreator('/docs/api/identity/schemas/SupportAccessResponse', 'c59'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/SupportRole',
                component: ComponentCreator('/docs/api/identity/schemas/SupportRole', 'ce7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/SupportRolesResponse',
                component: ComponentCreator('/docs/api/identity/schemas/SupportRolesResponse', 'ad1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/SystemLog',
                component: ComponentCreator('/docs/api/identity/schemas/SystemLog', 'f99'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/TemporaryPassword',
                component: ComponentCreator('/docs/api/identity/schemas/TemporaryPassword', 'b8c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/UpdateNetworkZoneRequest',
                component: ComponentCreator('/docs/api/identity/schemas/UpdateNetworkZoneRequest', '7c3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/UpdatePasswordPolicyRequest',
                component: ComponentCreator('/docs/api/identity/schemas/UpdatePasswordPolicyRequest', 'f1e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/UpdatePasswordPolicyRequestAge',
                component: ComponentCreator('/docs/api/identity/schemas/UpdatePasswordPolicyRequestAge', '45f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/UpdatePasswordPolicyRequestComplexity',
                component: ComponentCreator('/docs/api/identity/schemas/UpdatePasswordPolicyRequestComplexity', '7f2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/UpdatePasswordPolicyRequestConditions',
                component: ComponentCreator('/docs/api/identity/schemas/UpdatePasswordPolicyRequestConditions', '492'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/UpdatePasswordPolicyRequestLockout',
                component: ComponentCreator('/docs/api/identity/schemas/UpdatePasswordPolicyRequestLockout', '2a7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/UpdateRoleRequest',
                component: ComponentCreator('/docs/api/identity/schemas/UpdateRoleRequest', '98f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/UpdateUserRequest',
                component: ComponentCreator('/docs/api/identity/schemas/UpdateUserRequest', 'b98'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/UpdateUserSchemaRequest',
                component: ComponentCreator('/docs/api/identity/schemas/UpdateUserSchemaRequest', '585'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/UserResponse',
                component: ComponentCreator('/docs/api/identity/schemas/UserResponse', '25d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/UserSchemaProperty',
                component: ComponentCreator('/docs/api/identity/schemas/UserSchemaProperty', '202'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/UserSchemaResponse',
                component: ComponentCreator('/docs/api/identity/schemas/UserSchemaResponse', 'e99'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/identity/schemas/UserSummary',
                component: ComponentCreator('/docs/api/identity/schemas/UserSummary', 'b83'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/endpoints/access-evaluations/GetAccessEvaluationLog',
                component: ComponentCreator('/docs/api/insights/endpoints/access-evaluations/GetAccessEvaluationLog', 'fb2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/endpoints/access-evaluations/ListAccessEvaluationLogs',
                component: ComponentCreator('/docs/api/insights/endpoints/access-evaluations/ListAccessEvaluationLogs', 'c20'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/endpoints/application-metadata/ListAccessControlledResources',
                component: ComponentCreator('/docs/api/insights/endpoints/application-metadata/ListAccessControlledResources', 'd7e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/endpoints/auditing/CreateEntry',
                component: ComponentCreator('/docs/api/insights/endpoints/auditing/CreateEntry', '5a9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/endpoints/auditing/GetProcesses',
                component: ComponentCreator('/docs/api/insights/endpoints/auditing/GetProcesses', '045'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/endpoints/auditing/ListEntries',
                component: ComponentCreator('/docs/api/insights/endpoints/auditing/ListEntries', '2ad'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/endpoints/requests/GetRequest',
                component: ComponentCreator('/docs/api/insights/endpoints/requests/GetRequest', 'cbb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/endpoints/requests/GetRequestLog',
                component: ComponentCreator('/docs/api/insights/endpoints/requests/GetRequestLog', '3ce'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/endpoints/requests/GetResponse',
                component: ComponentCreator('/docs/api/insights/endpoints/requests/GetResponse', '4cd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/endpoints/requests/ListRequestLogs',
                component: ComponentCreator('/docs/api/insights/endpoints/requests/ListRequestLogs', '9bf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/endpoints/vendor-logs/GetVendorLog',
                component: ComponentCreator('/docs/api/insights/endpoints/vendor-logs/GetVendorLog', '4c4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/endpoints/vendor-logs/GetVendorRequest',
                component: ComponentCreator('/docs/api/insights/endpoints/vendor-logs/GetVendorRequest', 'd44'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/endpoints/vendor-logs/GetVendorResponse',
                component: ComponentCreator('/docs/api/insights/endpoints/vendor-logs/GetVendorResponse', '587'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/endpoints/vendor-logs/ListVendorLogs',
                component: ComponentCreator('/docs/api/insights/endpoints/vendor-logs/ListVendorLogs', '500'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/error-codes',
                component: ComponentCreator('/docs/api/insights/error-codes', '0e3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/intro',
                component: ComponentCreator('/docs/api/insights/intro', 'ab0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/AccessControlledAction',
                component: ComponentCreator('/docs/api/insights/schemas/AccessControlledAction', '731'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/AccessControlledResource',
                component: ComponentCreator('/docs/api/insights/schemas/AccessControlledResource', 'eb0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/AccessEvaluationLog',
                component: ComponentCreator('/docs/api/insights/schemas/AccessEvaluationLog', '456'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/ActionId',
                component: ComponentCreator('/docs/api/insights/schemas/ActionId', '3df'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/AuditData',
                component: ComponentCreator('/docs/api/insights/schemas/AuditData', '204'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/AuditDataSummary',
                component: ComponentCreator('/docs/api/insights/schemas/AuditDataSummary', 'f8e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/AuditEntry',
                component: ComponentCreator('/docs/api/insights/schemas/AuditEntry', '3c6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/AuditEntryNote',
                component: ComponentCreator('/docs/api/insights/schemas/AuditEntryNote', 'f96'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/AuditProcess',
                component: ComponentCreator('/docs/api/insights/schemas/AuditProcess', 'b9c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/AuditProcessSummary',
                component: ComponentCreator('/docs/api/insights/schemas/AuditProcessSummary', 'e2e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/Bucket',
                component: ComponentCreator('/docs/api/insights/schemas/Bucket', '164'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/CreateAuditEntry',
                component: ComponentCreator('/docs/api/insights/schemas/CreateAuditEntry', '056'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/Histogram',
                component: ComponentCreator('/docs/api/insights/schemas/Histogram', '736'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/IdentifierPartSchema',
                component: ComponentCreator('/docs/api/insights/schemas/IdentifierPartSchema', '8e0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/IdSelectorDefinition',
                component: ComponentCreator('/docs/api/insights/schemas/IdSelectorDefinition', 'c28'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/Link',
                component: ComponentCreator('/docs/api/insights/schemas/Link', '6f3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/LusidProblemDetails',
                component: ComponentCreator('/docs/api/insights/schemas/LusidProblemDetails', 'b50'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/LusidValidationProblemDetails',
                component: ComponentCreator('/docs/api/insights/schemas/LusidValidationProblemDetails', '926'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/ProblemDetails',
                component: ComponentCreator('/docs/api/insights/schemas/ProblemDetails', '030'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/Request',
                component: ComponentCreator('/docs/api/insights/schemas/Request', '1ce'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/RequestLog',
                component: ComponentCreator('/docs/api/insights/schemas/RequestLog', '245'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/Resource',
                component: ComponentCreator('/docs/api/insights/schemas/Resource', '64d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/ResourceListOfAccessControlledResource',
                component: ComponentCreator('/docs/api/insights/schemas/ResourceListOfAccessControlledResource', '076'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/ResourceListOfAuditProcessSummary',
                component: ComponentCreator('/docs/api/insights/schemas/ResourceListOfAuditProcessSummary', '7a7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/ResourceListWithHistogramOfAccessEvaluationLog',
                component: ComponentCreator('/docs/api/insights/schemas/ResourceListWithHistogramOfAccessEvaluationLog', '71c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/ResourceListWithHistogramOfRequestLog',
                component: ComponentCreator('/docs/api/insights/schemas/ResourceListWithHistogramOfRequestLog', '945'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/ResourceListWithHistogramOfVendorLog',
                component: ComponentCreator('/docs/api/insights/schemas/ResourceListWithHistogramOfVendorLog', 'e2e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/Response',
                component: ComponentCreator('/docs/api/insights/schemas/Response', '3be'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/ScrollableCollectionOfAuditEntry',
                component: ComponentCreator('/docs/api/insights/schemas/ScrollableCollectionOfAuditEntry', '184'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/VendorLog',
                component: ComponentCreator('/docs/api/insights/schemas/VendorLog', '497'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/VendorRequest',
                component: ComponentCreator('/docs/api/insights/schemas/VendorRequest', '32f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/insights/schemas/VendorResponse',
                component: ComponentCreator('/docs/api/insights/schemas/VendorResponse', 'e4b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/intro',
                component: ComponentCreator('/docs/api/intro', 'dbf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/application-metadata/GetServicesAsAccessControlledResources',
                component: ComponentCreator('/docs/api/luminesce/endpoints/application-metadata/GetServicesAsAccessControlledResources', 'a38'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/binary-downloading/DownloadBinary',
                component: ComponentCreator('/docs/api/luminesce/endpoints/binary-downloading/DownloadBinary', 'ae2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/binary-downloading/GetBinaryVersions',
                component: ComponentCreator('/docs/api/luminesce/endpoints/binary-downloading/GetBinaryVersions', 'bb2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/certificate-management/DownloadCertificate',
                component: ComponentCreator('/docs/api/luminesce/endpoints/certificate-management/DownloadCertificate', '77e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/certificate-management/ListCertificates',
                component: ComponentCreator('/docs/api/luminesce/endpoints/certificate-management/ListCertificates', '36d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/certificate-management/ManageCertificate',
                component: ComponentCreator('/docs/api/luminesce/endpoints/certificate-management/ManageCertificate', 'e7a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/current-table-field-catalog/GetCatalog',
                component: ComponentCreator('/docs/api/luminesce/endpoints/current-table-field-catalog/GetCatalog', '2b2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/current-table-field-catalog/GetFields',
                component: ComponentCreator('/docs/api/luminesce/endpoints/current-table-field-catalog/GetFields', 'c2b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/current-table-field-catalog/GetProviders',
                component: ComponentCreator('/docs/api/luminesce/endpoints/current-table-field-catalog/GetProviders', '161'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/historically-executed-queries/CancelHistory',
                component: ComponentCreator('/docs/api/luminesce/endpoints/historically-executed-queries/CancelHistory', 'd73'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/historically-executed-queries/FetchHistoryResultHistogram',
                component: ComponentCreator('/docs/api/luminesce/endpoints/historically-executed-queries/FetchHistoryResultHistogram', '9fc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/historically-executed-queries/FetchHistoryResultJson',
                component: ComponentCreator('/docs/api/luminesce/endpoints/historically-executed-queries/FetchHistoryResultJson', '74c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/historically-executed-queries/GetHistory',
                component: ComponentCreator('/docs/api/luminesce/endpoints/historically-executed-queries/GetHistory', '486'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/historically-executed-queries/GetProgressOfHistory',
                component: ComponentCreator('/docs/api/luminesce/endpoints/historically-executed-queries/GetProgressOfHistory', 'b4a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/multi-query-execution/CancelMultiQuery',
                component: ComponentCreator('/docs/api/luminesce/endpoints/multi-query-execution/CancelMultiQuery', 'f42'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/multi-query-execution/GetProgressOfMultiQuery',
                component: ComponentCreator('/docs/api/luminesce/endpoints/multi-query-execution/GetProgressOfMultiQuery', '81d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/multi-query-execution/StartQueries',
                component: ComponentCreator('/docs/api/luminesce/endpoints/multi-query-execution/StartQueries', 'a0d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-background-execution/CancelQuery',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-background-execution/CancelQuery', '374'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-background-execution/FetchQueryResultCsv',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-background-execution/FetchQueryResultCsv', '82f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-background-execution/FetchQueryResultExcel',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-background-execution/FetchQueryResultExcel', 'd6a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-background-execution/FetchQueryResultHistogram',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-background-execution/FetchQueryResultHistogram', '95d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-background-execution/FetchQueryResultJson',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-background-execution/FetchQueryResultJson', 'd08'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-background-execution/FetchQueryResultJsonProper',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-background-execution/FetchQueryResultJsonProper', '242'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-background-execution/FetchQueryResultParquet',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-background-execution/FetchQueryResultParquet', '261'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-background-execution/FetchQueryResultPipe',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-background-execution/FetchQueryResultPipe', 'b3c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-background-execution/FetchQueryResultSqlite',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-background-execution/FetchQueryResultSqlite', 'a63'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-background-execution/FetchQueryResultXml',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-background-execution/FetchQueryResultXml', '404'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-background-execution/GetProgressOf',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-background-execution/GetProgressOf', '1aa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-background-execution/StartQuery',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-background-execution/StartQuery', 'c27'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-design/GetProviderTemplateForExport',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-design/GetProviderTemplateForExport', '2b6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-design/PutCaseStatementDesignSqlToDesign',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-design/PutCaseStatementDesignSqlToDesign', '063'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-design/PutCaseStatementDesignToSql',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-design/PutCaseStatementDesignToSql', 'f73'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-design/PutFileReadDesignToSql',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-design/PutFileReadDesignToSql', 'e7b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-design/PutInlinedPropertiesDesignSqlToDesign',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-design/PutInlinedPropertiesDesignSqlToDesign', 'a80'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-design/PutInlinedPropertiesDesignToSql',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-design/PutInlinedPropertiesDesignToSql', 'ac2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-design/PutIntellisense',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-design/PutIntellisense', '706'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-design/PutIntellisenseError',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-design/PutIntellisenseError', '809'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-design/PutLusidGridToQuery',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-design/PutLusidGridToQuery', 'b71'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-design/PutQueryDesignToSql',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-design/PutQueryDesignToSql', 'f3f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-design/PutQueryToFormat',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-design/PutQueryToFormat', 'c08'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-design/PutSqlToExtractScalarParameters',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-design/PutSqlToExtractScalarParameters', '123'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-design/PutSqlToFileReadDesign',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-design/PutSqlToFileReadDesign', 'fbd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-design/PutSqlToQueryDesign',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-design/PutSqlToQueryDesign', '886'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-design/PutSqlToViewDesign',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-design/PutSqlToViewDesign', '64b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-design/PutSqlToWriterDesign',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-design/PutSqlToWriterDesign', 'da8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-design/PutViewDesignToSql',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-design/PutViewDesignToSql', 'b6a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-design/PutWriterDesignToSql',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-design/PutWriterDesignToSql', 'c3e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-execution/GetByQueryCsv',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-execution/GetByQueryCsv', '3c5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-execution/GetByQueryExcel',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-execution/GetByQueryExcel', 'e14'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-execution/GetByQueryJson',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-execution/GetByQueryJson', 'ef7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-execution/GetByQueryParquet',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-execution/GetByQueryParquet', '3df'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-execution/GetByQueryPipe',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-execution/GetByQueryPipe', '623'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-execution/GetByQuerySqlite',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-execution/GetByQuerySqlite', 'de9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-execution/GetByQueryXml',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-execution/GetByQueryXml', '77b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-execution/PutByQueryCsv',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-execution/PutByQueryCsv', '729'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-execution/PutByQueryExcel',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-execution/PutByQueryExcel', '901'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-execution/PutByQueryJson',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-execution/PutByQueryJson', '25b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-execution/PutByQueryParquet',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-execution/PutByQueryParquet', '36f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-execution/PutByQueryPipe',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-execution/PutByQueryPipe', '9e4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-execution/PutByQuerySqlite',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-execution/PutByQuerySqlite', 'ef3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/endpoints/sql-execution/PutByQueryXml',
                component: ComponentCreator('/docs/api/luminesce/endpoints/sql-execution/PutByQueryXml', '88a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/error-codes',
                component: ComponentCreator('/docs/api/luminesce/error-codes', '2f7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/intro',
                component: ComponentCreator('/docs/api/luminesce/intro', '567'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/AccessControlledAction',
                component: ComponentCreator('/docs/api/luminesce/schemas/AccessControlledAction', '8ad'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/AccessControlledResource',
                component: ComponentCreator('/docs/api/luminesce/schemas/AccessControlledResource', '6f8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/AccessControlledResourceIdentifierPartSchemaAttribute',
                component: ComponentCreator('/docs/api/luminesce/schemas/AccessControlledResourceIdentifierPartSchemaAttribute', 'c35'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/ActionId',
                component: ComponentCreator('/docs/api/luminesce/schemas/ActionId', '358'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/ActionResult',
                component: ComponentCreator('/docs/api/luminesce/schemas/ActionResult', 'de7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/AggregateFunction',
                component: ComponentCreator('/docs/api/luminesce/schemas/AggregateFunction', 'aa8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/Aggregation',
                component: ComponentCreator('/docs/api/luminesce/schemas/Aggregation', 'e81'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/AutoDetectType',
                component: ComponentCreator('/docs/api/luminesce/schemas/AutoDetectType', 'adf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/AvailableField',
                component: ComponentCreator('/docs/api/luminesce/schemas/AvailableField', '694'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/AvailableParameter',
                component: ComponentCreator('/docs/api/luminesce/schemas/AvailableParameter', '3d4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/BackgroundMultiQueryProgressResponse',
                component: ComponentCreator('/docs/api/luminesce/schemas/BackgroundMultiQueryProgressResponse', '810'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/BackgroundMultiQueryResponse',
                component: ComponentCreator('/docs/api/luminesce/schemas/BackgroundMultiQueryResponse', '7be'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/BackgroundQueryCancelResponse',
                component: ComponentCreator('/docs/api/luminesce/schemas/BackgroundQueryCancelResponse', '5ff'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/BackgroundQueryProgressResponse',
                component: ComponentCreator('/docs/api/luminesce/schemas/BackgroundQueryProgressResponse', 'be3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/BackgroundQueryResponse',
                component: ComponentCreator('/docs/api/luminesce/schemas/BackgroundQueryResponse', '28d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/BackgroundQueryState',
                component: ComponentCreator('/docs/api/luminesce/schemas/BackgroundQueryState', 'ec3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/CaseStatementDesign',
                component: ComponentCreator('/docs/api/luminesce/schemas/CaseStatementDesign', '71a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/CaseStatementItem',
                component: ComponentCreator('/docs/api/luminesce/schemas/CaseStatementItem', 'e16'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/CertificateAction',
                component: ComponentCreator('/docs/api/luminesce/schemas/CertificateAction', '5ec'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/CertificateFileType',
                component: ComponentCreator('/docs/api/luminesce/schemas/CertificateFileType', 'a74'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/CertificateState',
                component: ComponentCreator('/docs/api/luminesce/schemas/CertificateState', '6b1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/CertificateStatus',
                component: ComponentCreator('/docs/api/luminesce/schemas/CertificateStatus', '037'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/CertificateType',
                component: ComponentCreator('/docs/api/luminesce/schemas/CertificateType', '62b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/Column',
                component: ComponentCreator('/docs/api/luminesce/schemas/Column', 'eea'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/ColumnInfo',
                component: ComponentCreator('/docs/api/luminesce/schemas/ColumnInfo', 'e80'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/ColumnStateType',
                component: ComponentCreator('/docs/api/luminesce/schemas/ColumnStateType', 'a69'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/ConditionAttributes',
                component: ComponentCreator('/docs/api/luminesce/schemas/ConditionAttributes', '18d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/ConvertToViewData',
                component: ComponentCreator('/docs/api/luminesce/schemas/ConvertToViewData', 'ffd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/CursorPosition',
                component: ComponentCreator('/docs/api/luminesce/schemas/CursorPosition', '08d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/DashboardType',
                component: ComponentCreator('/docs/api/luminesce/schemas/DashboardType', '20f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/DataType',
                component: ComponentCreator('/docs/api/luminesce/schemas/DataType', '4d0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/DateParameters',
                component: ComponentCreator('/docs/api/luminesce/schemas/DateParameters', '1bf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/ErrorHighlightItem',
                component: ComponentCreator('/docs/api/luminesce/schemas/ErrorHighlightItem', 'db4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/ErrorHighlightRequest',
                component: ComponentCreator('/docs/api/luminesce/schemas/ErrorHighlightRequest', '5a2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/ErrorHighlightResponse',
                component: ComponentCreator('/docs/api/luminesce/schemas/ErrorHighlightResponse', '318'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/ExpressionWithAlias',
                component: ComponentCreator('/docs/api/luminesce/schemas/ExpressionWithAlias', '5aa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/FeedbackEventArgs',
                component: ComponentCreator('/docs/api/luminesce/schemas/FeedbackEventArgs', '8ee'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/FeedbackLevel',
                component: ComponentCreator('/docs/api/luminesce/schemas/FeedbackLevel', '2c9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/FieldDesign',
                component: ComponentCreator('/docs/api/luminesce/schemas/FieldDesign', '8e9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/FieldType',
                component: ComponentCreator('/docs/api/luminesce/schemas/FieldType', 'ff1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/FileReaderBuilderDef',
                component: ComponentCreator('/docs/api/luminesce/schemas/FileReaderBuilderDef', '8d4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/FileReaderBuilderResponse',
                component: ComponentCreator('/docs/api/luminesce/schemas/FileReaderBuilderResponse', '73c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/FilterModel',
                component: ComponentCreator('/docs/api/luminesce/schemas/FilterModel', 'bb3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/FilterTermDesign',
                component: ComponentCreator('/docs/api/luminesce/schemas/FilterTermDesign', 'c4a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/FilterType',
                component: ComponentCreator('/docs/api/luminesce/schemas/FilterType', 'b3b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/IdSelectorDefinition',
                component: ComponentCreator('/docs/api/luminesce/schemas/IdSelectorDefinition', '2e2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/InlinedPropertyDesign',
                component: ComponentCreator('/docs/api/luminesce/schemas/InlinedPropertyDesign', '0b5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/InlinedPropertyItem',
                component: ComponentCreator('/docs/api/luminesce/schemas/InlinedPropertyItem', '0f3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/IntellisenseItem',
                component: ComponentCreator('/docs/api/luminesce/schemas/IntellisenseItem', 'a5c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/IntellisenseRequest',
                component: ComponentCreator('/docs/api/luminesce/schemas/IntellisenseRequest', '1f7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/IntellisenseResponse',
                component: ComponentCreator('/docs/api/luminesce/schemas/IntellisenseResponse', 'c4e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/IntellisenseType',
                component: ComponentCreator('/docs/api/luminesce/schemas/IntellisenseType', '733'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/Link',
                component: ComponentCreator('/docs/api/luminesce/schemas/Link', '3a7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/LuminesceBinaryType',
                component: ComponentCreator('/docs/api/luminesce/schemas/LuminesceBinaryType', '757'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/LusidGridData',
                component: ComponentCreator('/docs/api/luminesce/schemas/LusidGridData', '09e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/LusidProblemDetails',
                component: ComponentCreator('/docs/api/luminesce/schemas/LusidProblemDetails', 'b4e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/MappableField',
                component: ComponentCreator('/docs/api/luminesce/schemas/MappableField', '691'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/MappingFlags',
                component: ComponentCreator('/docs/api/luminesce/schemas/MappingFlags', '950'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/MultiQueryDefinitionType',
                component: ComponentCreator('/docs/api/luminesce/schemas/MultiQueryDefinitionType', '128'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/OptionsCsv',
                component: ComponentCreator('/docs/api/luminesce/schemas/OptionsCsv', '436'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/OptionsExcel',
                component: ComponentCreator('/docs/api/luminesce/schemas/OptionsExcel', '5e6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/OptionsParquet',
                component: ComponentCreator('/docs/api/luminesce/schemas/OptionsParquet', '546'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/OptionsSqLite',
                component: ComponentCreator('/docs/api/luminesce/schemas/OptionsSqLite', 'fea'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/OptionsXml',
                component: ComponentCreator('/docs/api/luminesce/schemas/OptionsXml', '46d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/OrderByDirection',
                component: ComponentCreator('/docs/api/luminesce/schemas/OrderByDirection', '9c8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/OrderByTermDesign',
                component: ComponentCreator('/docs/api/luminesce/schemas/OrderByTermDesign', 'ee9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/QueryDesign',
                component: ComponentCreator('/docs/api/luminesce/schemas/QueryDesign', 'cc8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/QueryDesignerBinaryOperator',
                component: ComponentCreator('/docs/api/luminesce/schemas/QueryDesignerBinaryOperator', 'e94'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/ResourceId',
                component: ComponentCreator('/docs/api/luminesce/schemas/ResourceId', '1cc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/ResourceListOfAccessControlledResource',
                component: ComponentCreator('/docs/api/luminesce/schemas/ResourceListOfAccessControlledResource', '4ea'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/ScalarParameter',
                component: ComponentCreator('/docs/api/luminesce/schemas/ScalarParameter', '7a0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/Source',
                component: ComponentCreator('/docs/api/luminesce/schemas/Source', '3d7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/SourceType',
                component: ComponentCreator('/docs/api/luminesce/schemas/SourceType', 'f3a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/TableMeta',
                component: ComponentCreator('/docs/api/luminesce/schemas/TableMeta', '28d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/TableView',
                component: ComponentCreator('/docs/api/luminesce/schemas/TableView', '0ec'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/TaskStatus',
                component: ComponentCreator('/docs/api/luminesce/schemas/TaskStatus', '5a6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/Type',
                component: ComponentCreator('/docs/api/luminesce/schemas/Type', '056'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/ViewParameter',
                component: ComponentCreator('/docs/api/luminesce/schemas/ViewParameter', 'a3c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/luminesce/schemas/WriterDesign',
                component: ComponentCreator('/docs/api/luminesce/schemas/WriterDesign', '5cd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/abor-configuration/CreateAborConfiguration',
                component: ComponentCreator('/docs/api/lusid/endpoints/abor-configuration/CreateAborConfiguration', '801'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/abor-configuration/DeleteAborConfiguration',
                component: ComponentCreator('/docs/api/lusid/endpoints/abor-configuration/DeleteAborConfiguration', '1d5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/abor-configuration/GetAborConfiguration',
                component: ComponentCreator('/docs/api/lusid/endpoints/abor-configuration/GetAborConfiguration', 'a08'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/abor-configuration/GetAborConfigurationProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/abor-configuration/GetAborConfigurationProperties', 'c08'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/abor-configuration/ListAborConfigurations',
                component: ComponentCreator('/docs/api/lusid/endpoints/abor-configuration/ListAborConfigurations', '00c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/abor-configuration/UpsertAborConfigurationProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/abor-configuration/UpsertAborConfigurationProperties', '947'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/abor/AddDiaryEntry',
                component: ComponentCreator('/docs/api/lusid/endpoints/abor/AddDiaryEntry', '18d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/abor/ClosePeriod',
                component: ComponentCreator('/docs/api/lusid/endpoints/abor/ClosePeriod', 'd4d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/abor/CreateAbor',
                component: ComponentCreator('/docs/api/lusid/endpoints/abor/CreateAbor', '9d3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/abor/DeleteAbor',
                component: ComponentCreator('/docs/api/lusid/endpoints/abor/DeleteAbor', '222'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/abor/GetAbor',
                component: ComponentCreator('/docs/api/lusid/endpoints/abor/GetAbor', '1a6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/abor/GetAborProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/abor/GetAborProperties', '6cc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/abor/GetJournalEntryLines',
                component: ComponentCreator('/docs/api/lusid/endpoints/abor/GetJournalEntryLines', 'e04'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/abor/GetTrialBalance',
                component: ComponentCreator('/docs/api/lusid/endpoints/abor/GetTrialBalance', '829'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/abor/ListAbors',
                component: ComponentCreator('/docs/api/lusid/endpoints/abor/ListAbors', '747'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/abor/ListDiaryEntries',
                component: ComponentCreator('/docs/api/lusid/endpoints/abor/ListDiaryEntries', '1de'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/abor/LockPeriod',
                component: ComponentCreator('/docs/api/lusid/endpoints/abor/LockPeriod', '30f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/abor/PatchAbor',
                component: ComponentCreator('/docs/api/lusid/endpoints/abor/PatchAbor', '47b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/abor/ReOpenPeriods',
                component: ComponentCreator('/docs/api/lusid/endpoints/abor/ReOpenPeriods', 'd3a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/abor/UpsertAborProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/abor/UpsertAborProperties', 'a3a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/address-key-definition/CreateAddressKeyDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/address-key-definition/CreateAddressKeyDefinition', '8bc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/address-key-definition/GetAddressKeyDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/address-key-definition/GetAddressKeyDefinition', '804'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/address-key-definition/ListAddressKeyDefinitions',
                component: ComponentCreator('/docs/api/lusid/endpoints/address-key-definition/ListAddressKeyDefinitions', 'ef4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/aggregation/GenerateConfigurationRecipe',
                component: ComponentCreator('/docs/api/lusid/endpoints/aggregation/GenerateConfigurationRecipe', '78f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/aggregation/GetQueryableKeys',
                component: ComponentCreator('/docs/api/lusid/endpoints/aggregation/GetQueryableKeys', '69c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/aggregation/GetValuation',
                component: ComponentCreator('/docs/api/lusid/endpoints/aggregation/GetValuation', '9b8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/aggregation/GetValuationOfWeightedInstruments',
                component: ComponentCreator('/docs/api/lusid/endpoints/aggregation/GetValuationOfWeightedInstruments', 'e32'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/allocations/DeleteAllocation',
                component: ComponentCreator('/docs/api/lusid/endpoints/allocations/DeleteAllocation', 'd2f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/allocations/GetAllocation',
                component: ComponentCreator('/docs/api/lusid/endpoints/allocations/GetAllocation', '40b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/allocations/ListAllocations',
                component: ComponentCreator('/docs/api/lusid/endpoints/allocations/ListAllocations', 'ab3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/allocations/UpsertAllocations',
                component: ComponentCreator('/docs/api/lusid/endpoints/allocations/UpsertAllocations', 'fb9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/amortisation-rule-sets/CreateAmortisationRuleSet',
                component: ComponentCreator('/docs/api/lusid/endpoints/amortisation-rule-sets/CreateAmortisationRuleSet', '89e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/amortisation-rule-sets/DeleteAmortisationRuleset',
                component: ComponentCreator('/docs/api/lusid/endpoints/amortisation-rule-sets/DeleteAmortisationRuleset', '322'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/amortisation-rule-sets/GetAmortisationRuleSet',
                component: ComponentCreator('/docs/api/lusid/endpoints/amortisation-rule-sets/GetAmortisationRuleSet', '934'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/amortisation-rule-sets/ListAmortisationRuleSets',
                component: ComponentCreator('/docs/api/lusid/endpoints/amortisation-rule-sets/ListAmortisationRuleSets', 'f53'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/amortisation-rule-sets/SetAmortisationRules',
                component: ComponentCreator('/docs/api/lusid/endpoints/amortisation-rule-sets/SetAmortisationRules', '424'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/amortisation-rule-sets/UpdateAmortisationRuleSetDetails',
                component: ComponentCreator('/docs/api/lusid/endpoints/amortisation-rule-sets/UpdateAmortisationRuleSetDetails', 'e2d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/application-metadata/GetExcelAddin',
                component: ComponentCreator('/docs/api/lusid/endpoints/application-metadata/GetExcelAddin', '89d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/application-metadata/GetLusidVersions',
                component: ComponentCreator('/docs/api/lusid/endpoints/application-metadata/GetLusidVersions', 'a52'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/application-metadata/ListAccessControlledResources',
                component: ComponentCreator('/docs/api/lusid/endpoints/application-metadata/ListAccessControlledResources', '488'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/blocks/DeleteBlock',
                component: ComponentCreator('/docs/api/lusid/endpoints/blocks/DeleteBlock', '744'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/blocks/GetBlock',
                component: ComponentCreator('/docs/api/lusid/endpoints/blocks/GetBlock', '8e8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/blocks/ListBlocks',
                component: ComponentCreator('/docs/api/lusid/endpoints/blocks/ListBlocks', '1fa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/blocks/UpsertBlocks',
                component: ComponentCreator('/docs/api/lusid/endpoints/blocks/UpsertBlocks', 'c3e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/calendars/AddBusinessDaysToDate',
                component: ComponentCreator('/docs/api/lusid/endpoints/calendars/AddBusinessDaysToDate', 'e26'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/calendars/AddDateToCalendar',
                component: ComponentCreator('/docs/api/lusid/endpoints/calendars/AddDateToCalendar', '886'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/calendars/BatchUpsertDatesForCalendar',
                component: ComponentCreator('/docs/api/lusid/endpoints/calendars/BatchUpsertDatesForCalendar', 'dd2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/calendars/CreateCalendar',
                component: ComponentCreator('/docs/api/lusid/endpoints/calendars/CreateCalendar', 'a03'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/calendars/DeleteCalendar',
                component: ComponentCreator('/docs/api/lusid/endpoints/calendars/DeleteCalendar', '01c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/calendars/DeleteDateFromCalendar',
                component: ComponentCreator('/docs/api/lusid/endpoints/calendars/DeleteDateFromCalendar', 'fdb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/calendars/DeleteDatesFromCalendar',
                component: ComponentCreator('/docs/api/lusid/endpoints/calendars/DeleteDatesFromCalendar', '27b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/calendars/GenerateSchedule',
                component: ComponentCreator('/docs/api/lusid/endpoints/calendars/GenerateSchedule', 'd38'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/calendars/GetCalendar',
                component: ComponentCreator('/docs/api/lusid/endpoints/calendars/GetCalendar', '223'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/calendars/GetDates',
                component: ComponentCreator('/docs/api/lusid/endpoints/calendars/GetDates', '723'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/calendars/IsBusinessDateTime',
                component: ComponentCreator('/docs/api/lusid/endpoints/calendars/IsBusinessDateTime', '678'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/calendars/ListCalendars',
                component: ComponentCreator('/docs/api/lusid/endpoints/calendars/ListCalendars', 'f74'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/calendars/ListCalendarsInScope',
                component: ComponentCreator('/docs/api/lusid/endpoints/calendars/ListCalendarsInScope', '52b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/calendars/UpdateCalendar',
                component: ComponentCreator('/docs/api/lusid/endpoints/calendars/UpdateCalendar', 'b3d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/CreateChartOfAccounts',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/CreateChartOfAccounts', '8d6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/CreateCleardownModule',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/CreateCleardownModule', '2a5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/CreateGeneralLedgerProfile',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/CreateGeneralLedgerProfile', 'f0d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/CreatePostingModule',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/CreatePostingModule', '630'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/DeleteAccounts',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/DeleteAccounts', 'bde'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/DeleteChartOfAccounts',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/DeleteChartOfAccounts', '61e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/DeleteCleardownModule',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/DeleteCleardownModule', 'c91'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/DeleteGeneralLedgerProfile',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/DeleteGeneralLedgerProfile', '152'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/DeletePostingModule',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/DeletePostingModule', 'dfd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/GetAccount',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/GetAccount', '714'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/GetAccountProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/GetAccountProperties', 'd74'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/GetChartOfAccounts',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/GetChartOfAccounts', '28b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/GetChartOfAccountsProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/GetChartOfAccountsProperties', '8d2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/GetCleardownModule',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/GetCleardownModule', '8d9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/GetGeneralLedgerProfile',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/GetGeneralLedgerProfile', 'ca7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/GetPostingModule',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/GetPostingModule', '66c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/ListAccounts',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/ListAccounts', '46f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/ListChartsOfAccounts',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/ListChartsOfAccounts', 'b38'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/ListCleardownModuleRules',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/ListCleardownModuleRules', '9fd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/ListCleardownModules',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/ListCleardownModules', 'faa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/ListGeneralLedgerProfiles',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/ListGeneralLedgerProfiles', '938'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/ListPostingModuleRules',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/ListPostingModuleRules', '4c8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/ListPostingModules',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/ListPostingModules', '72d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/PatchChartOfAccounts',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/PatchChartOfAccounts', '27d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/PatchCleardownModule',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/PatchCleardownModule', 'cf3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/PatchPostingModule',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/PatchPostingModule', 'a48'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/SetCleardownModuleDetails',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/SetCleardownModuleDetails', '9e4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/SetCleardownModuleRules',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/SetCleardownModuleRules', '535'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/SetGeneralLedgerProfileMappings',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/SetGeneralLedgerProfileMappings', 'b97'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/SetPostingModuleDetails',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/SetPostingModuleDetails', 'cdb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/SetPostingModuleRules',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/SetPostingModuleRules', '806'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/UpsertAccountProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/UpsertAccountProperties', '00d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/UpsertAccounts',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/UpsertAccounts', '8a6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/chart-of-accounts/UpsertChartOfAccountsProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/chart-of-accounts/UpsertChartOfAccountsProperties', '3b5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/complex-market-data/DeleteComplexMarketData',
                component: ComponentCreator('/docs/api/lusid/endpoints/complex-market-data/DeleteComplexMarketData', '07e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/complex-market-data/GetComplexMarketData',
                component: ComponentCreator('/docs/api/lusid/endpoints/complex-market-data/GetComplexMarketData', '130'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/complex-market-data/ListComplexMarketData',
                component: ComponentCreator('/docs/api/lusid/endpoints/complex-market-data/ListComplexMarketData', 'e6b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/complex-market-data/UpsertAppendComplexMarketData',
                component: ComponentCreator('/docs/api/lusid/endpoints/complex-market-data/UpsertAppendComplexMarketData', '45d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/complex-market-data/UpsertComplexMarketData',
                component: ComponentCreator('/docs/api/lusid/endpoints/complex-market-data/UpsertComplexMarketData', '093'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/compliance/CreateComplianceTemplate',
                component: ComponentCreator('/docs/api/lusid/endpoints/compliance/CreateComplianceTemplate', 'cba'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/compliance/DeleteComplianceRule',
                component: ComponentCreator('/docs/api/lusid/endpoints/compliance/DeleteComplianceRule', '075'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/compliance/DeleteComplianceTemplate',
                component: ComponentCreator('/docs/api/lusid/endpoints/compliance/DeleteComplianceTemplate', '2fa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/compliance/GetComplianceRule',
                component: ComponentCreator('/docs/api/lusid/endpoints/compliance/GetComplianceRule', '24f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/compliance/GetComplianceRuleResult',
                component: ComponentCreator('/docs/api/lusid/endpoints/compliance/GetComplianceRuleResult', '57d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/compliance/GetComplianceTemplate',
                component: ComponentCreator('/docs/api/lusid/endpoints/compliance/GetComplianceTemplate', '323'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/compliance/GetDecoratedComplianceRunSummary',
                component: ComponentCreator('/docs/api/lusid/endpoints/compliance/GetDecoratedComplianceRunSummary', 'c55'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/compliance/ListComplianceRules',
                component: ComponentCreator('/docs/api/lusid/endpoints/compliance/ListComplianceRules', '638'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/compliance/ListComplianceRuns',
                component: ComponentCreator('/docs/api/lusid/endpoints/compliance/ListComplianceRuns', '0e8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/compliance/ListComplianceTemplates',
                component: ComponentCreator('/docs/api/lusid/endpoints/compliance/ListComplianceTemplates', '7d9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/compliance/RunCompliance',
                component: ComponentCreator('/docs/api/lusid/endpoints/compliance/RunCompliance', '153'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/compliance/RunCompliancePreview',
                component: ComponentCreator('/docs/api/lusid/endpoints/compliance/RunCompliancePreview', 'd15'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/compliance/UpdateComplianceTemplate',
                component: ComponentCreator('/docs/api/lusid/endpoints/compliance/UpdateComplianceTemplate', 'a5b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/compliance/UpsertComplianceRule',
                component: ComponentCreator('/docs/api/lusid/endpoints/compliance/UpsertComplianceRule', '01e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/compliance/UpsertComplianceRunSummary',
                component: ComponentCreator('/docs/api/lusid/endpoints/compliance/UpsertComplianceRunSummary', 'e04'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/configuration-recipe/DeleteConfigurationRecipe',
                component: ComponentCreator('/docs/api/lusid/endpoints/configuration-recipe/DeleteConfigurationRecipe', '33f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/configuration-recipe/DeleteRecipeComposer',
                component: ComponentCreator('/docs/api/lusid/endpoints/configuration-recipe/DeleteRecipeComposer', '1c3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/configuration-recipe/GetConfigurationRecipe',
                component: ComponentCreator('/docs/api/lusid/endpoints/configuration-recipe/GetConfigurationRecipe', 'b33'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/configuration-recipe/GetDerivedRecipe',
                component: ComponentCreator('/docs/api/lusid/endpoints/configuration-recipe/GetDerivedRecipe', 'b41'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/configuration-recipe/GetRecipeComposer',
                component: ComponentCreator('/docs/api/lusid/endpoints/configuration-recipe/GetRecipeComposer', '72f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/configuration-recipe/GetRecipeComposerResolvedInline',
                component: ComponentCreator('/docs/api/lusid/endpoints/configuration-recipe/GetRecipeComposerResolvedInline', '604'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/configuration-recipe/ListConfigurationRecipes',
                component: ComponentCreator('/docs/api/lusid/endpoints/configuration-recipe/ListConfigurationRecipes', '1d0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/configuration-recipe/ListDerivedRecipes',
                component: ComponentCreator('/docs/api/lusid/endpoints/configuration-recipe/ListDerivedRecipes', 'b49'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/configuration-recipe/ListRecipeComposers',
                component: ComponentCreator('/docs/api/lusid/endpoints/configuration-recipe/ListRecipeComposers', 'd5e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/configuration-recipe/UpsertConfigurationRecipe',
                component: ComponentCreator('/docs/api/lusid/endpoints/configuration-recipe/UpsertConfigurationRecipe', '337'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/configuration-recipe/UpsertRecipeComposer',
                component: ComponentCreator('/docs/api/lusid/endpoints/configuration-recipe/UpsertRecipeComposer', '946'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/conventions/DeleteCdsFlowConventions',
                component: ComponentCreator('/docs/api/lusid/endpoints/conventions/DeleteCdsFlowConventions', 'f08'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/conventions/DeleteFlowConventions',
                component: ComponentCreator('/docs/api/lusid/endpoints/conventions/DeleteFlowConventions', '3ef'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/conventions/DeleteIndexConvention',
                component: ComponentCreator('/docs/api/lusid/endpoints/conventions/DeleteIndexConvention', '854'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/conventions/GetCdsFlowConventions',
                component: ComponentCreator('/docs/api/lusid/endpoints/conventions/GetCdsFlowConventions', '908'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/conventions/GetFlowConventions',
                component: ComponentCreator('/docs/api/lusid/endpoints/conventions/GetFlowConventions', 'b06'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/conventions/GetIndexConvention',
                component: ComponentCreator('/docs/api/lusid/endpoints/conventions/GetIndexConvention', '8bc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/conventions/ListCdsFlowConventions',
                component: ComponentCreator('/docs/api/lusid/endpoints/conventions/ListCdsFlowConventions', 'd02'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/conventions/ListFlowConventions',
                component: ComponentCreator('/docs/api/lusid/endpoints/conventions/ListFlowConventions', '418'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/conventions/ListIndexConvention',
                component: ComponentCreator('/docs/api/lusid/endpoints/conventions/ListIndexConvention', 'fd0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/conventions/UpsertCdsFlowConventions',
                component: ComponentCreator('/docs/api/lusid/endpoints/conventions/UpsertCdsFlowConventions', 'fd5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/conventions/UpsertFlowConventions',
                component: ComponentCreator('/docs/api/lusid/endpoints/conventions/UpsertFlowConventions', '0a3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/conventions/UpsertIndexConvention',
                component: ComponentCreator('/docs/api/lusid/endpoints/conventions/UpsertIndexConvention', '2f5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/corporate-action-sources/BatchUpsertCorporateActions',
                component: ComponentCreator('/docs/api/lusid/endpoints/corporate-action-sources/BatchUpsertCorporateActions', '49b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/corporate-action-sources/CreateCorporateActionSource',
                component: ComponentCreator('/docs/api/lusid/endpoints/corporate-action-sources/CreateCorporateActionSource', 'ff2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/corporate-action-sources/DeleteCorporateActions',
                component: ComponentCreator('/docs/api/lusid/endpoints/corporate-action-sources/DeleteCorporateActions', '4d7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/corporate-action-sources/DeleteCorporateActionSource',
                component: ComponentCreator('/docs/api/lusid/endpoints/corporate-action-sources/DeleteCorporateActionSource', '51b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/corporate-action-sources/DeleteInstrumentEvents',
                component: ComponentCreator('/docs/api/lusid/endpoints/corporate-action-sources/DeleteInstrumentEvents', 'b46'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/corporate-action-sources/GetCorporateActions',
                component: ComponentCreator('/docs/api/lusid/endpoints/corporate-action-sources/GetCorporateActions', 'cb6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/corporate-action-sources/GetInstrumentEvents',
                component: ComponentCreator('/docs/api/lusid/endpoints/corporate-action-sources/GetInstrumentEvents', '7cb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/corporate-action-sources/ListCorporateActionSources',
                component: ComponentCreator('/docs/api/lusid/endpoints/corporate-action-sources/ListCorporateActionSources', '837'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/corporate-action-sources/UpsertInstrumentEvents',
                component: ComponentCreator('/docs/api/lusid/endpoints/corporate-action-sources/UpsertInstrumentEvents', 'f78'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/counterparties/DeleteCounterpartyAgreement',
                component: ComponentCreator('/docs/api/lusid/endpoints/counterparties/DeleteCounterpartyAgreement', 'ad9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/counterparties/DeleteCreditSupportAnnex',
                component: ComponentCreator('/docs/api/lusid/endpoints/counterparties/DeleteCreditSupportAnnex', '5c1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/counterparties/GetCounterpartyAgreement',
                component: ComponentCreator('/docs/api/lusid/endpoints/counterparties/GetCounterpartyAgreement', 'c3d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/counterparties/GetCreditSupportAnnex',
                component: ComponentCreator('/docs/api/lusid/endpoints/counterparties/GetCreditSupportAnnex', '5b3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/counterparties/ListCounterpartyAgreements',
                component: ComponentCreator('/docs/api/lusid/endpoints/counterparties/ListCounterpartyAgreements', 'a47'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/counterparties/ListCreditSupportAnnexes',
                component: ComponentCreator('/docs/api/lusid/endpoints/counterparties/ListCreditSupportAnnexes', '8b3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/counterparties/UpsertCounterpartyAgreement',
                component: ComponentCreator('/docs/api/lusid/endpoints/counterparties/UpsertCounterpartyAgreement', 'ca7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/counterparties/UpsertCreditSupportAnnex',
                component: ComponentCreator('/docs/api/lusid/endpoints/counterparties/UpsertCreditSupportAnnex', '6a2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-data-models/CreateCustomDataModel',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-data-models/CreateCustomDataModel', '23e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-data-models/DeleteCustomDataModel',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-data-models/DeleteCustomDataModel', 'b5a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-data-models/GetCustomDataModel',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-data-models/GetCustomDataModel', '784'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-data-models/ListDataModelHierarchies',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-data-models/ListDataModelHierarchies', 'eb2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-data-models/ListSupportedEntityTypes',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-data-models/ListSupportedEntityTypes', 'e37'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-data-models/UpdateCustomDataModel',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-data-models/UpdateCustomDataModel', 'a94'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-entities/DeleteCustomEntity',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-entities/DeleteCustomEntity', 'f96'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-entities/DeleteCustomEntityAccessMetadata',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-entities/DeleteCustomEntityAccessMetadata', '2b5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-entities/GetAllCustomEntityAccessMetadata',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-entities/GetAllCustomEntityAccessMetadata', '7f0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-entities/GetCustomEntity',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-entities/GetCustomEntity', 'd1b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-entities/GetCustomEntityAccessMetadataByKey',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-entities/GetCustomEntityAccessMetadataByKey', '600'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-entities/GetCustomEntityRelationships',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-entities/GetCustomEntityRelationships', '931'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-entities/ListCustomEntities',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-entities/ListCustomEntities', '5b1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-entities/PatchCustomEntityAccessMetadata',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-entities/PatchCustomEntityAccessMetadata', 'cde'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-entities/UpsertCustomEntities',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-entities/UpsertCustomEntities', '8fd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-entities/UpsertCustomEntity',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-entities/UpsertCustomEntity', 'e44'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-entities/UpsertCustomEntityAccessMetadata',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-entities/UpsertCustomEntityAccessMetadata', '84c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-entity-definitions/CreateCustomEntityDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-entity-definitions/CreateCustomEntityDefinition', 'c1f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-entity-definitions/GetDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-entity-definitions/GetDefinition', '4fa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-entity-definitions/ListCustomEntityDefinitions',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-entity-definitions/ListCustomEntityDefinitions', '328'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-entity-definitions/UpdateCustomEntityDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-entity-definitions/UpdateCustomEntityDefinition', '9f1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-entity-types/CreateCustomEntityType',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-entity-types/CreateCustomEntityType', '9b5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-entity-types/GetCustomEntityType',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-entity-types/GetCustomEntityType', '861'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-entity-types/ListCustomEntityTypes',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-entity-types/ListCustomEntityTypes', '3eb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/custom-entity-types/UpdateCustomEntityType',
                component: ComponentCreator('/docs/api/lusid/endpoints/custom-entity-types/UpdateCustomEntityType', 'cf8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/cut-label-definitions/CreateCutLabelDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/cut-label-definitions/CreateCutLabelDefinition', 'd8a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/cut-label-definitions/DeleteCutLabelDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/cut-label-definitions/DeleteCutLabelDefinition', 'f6f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/cut-label-definitions/GetCutLabelDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/cut-label-definitions/GetCutLabelDefinition', 'd55'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/cut-label-definitions/ListCutLabelDefinitions',
                component: ComponentCreator('/docs/api/lusid/endpoints/cut-label-definitions/ListCutLabelDefinitions', '66e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/cut-label-definitions/UpdateCutLabelDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/cut-label-definitions/UpdateCutLabelDefinition', 'e46'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/data-types/CreateDataType',
                component: ComponentCreator('/docs/api/lusid/endpoints/data-types/CreateDataType', 'be1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/data-types/DeleteDataType',
                component: ComponentCreator('/docs/api/lusid/endpoints/data-types/DeleteDataType', 'a38'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/data-types/GetDataType',
                component: ComponentCreator('/docs/api/lusid/endpoints/data-types/GetDataType', '36a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/data-types/GetUnitsFromDataType',
                component: ComponentCreator('/docs/api/lusid/endpoints/data-types/GetUnitsFromDataType', '502'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/data-types/ListDataTypes',
                component: ComponentCreator('/docs/api/lusid/endpoints/data-types/ListDataTypes', 'e01'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/data-types/ListDataTypeSummaries',
                component: ComponentCreator('/docs/api/lusid/endpoints/data-types/ListDataTypeSummaries', 'b39'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/data-types/UpdateDataType',
                component: ComponentCreator('/docs/api/lusid/endpoints/data-types/UpdateDataType', '33d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/data-types/UpdateReferenceData',
                component: ComponentCreator('/docs/api/lusid/endpoints/data-types/UpdateReferenceData', '8f0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/data-types/UpdateReferenceValues',
                component: ComponentCreator('/docs/api/lusid/endpoints/data-types/UpdateReferenceValues', 'd14'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/derived-transaction-portfolios/CreateDerivedPortfolio',
                component: ComponentCreator('/docs/api/lusid/endpoints/derived-transaction-portfolios/CreateDerivedPortfolio', 'ede'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/derived-transaction-portfolios/DeleteDerivedPortfolioDetails',
                component: ComponentCreator('/docs/api/lusid/endpoints/derived-transaction-portfolios/DeleteDerivedPortfolioDetails', '20a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/entities/GetCustomEntityByEntityUniqueId',
                component: ComponentCreator('/docs/api/lusid/endpoints/entities/GetCustomEntityByEntityUniqueId', 'fdf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/entities/GetDataTypeByEntityUniqueId',
                component: ComponentCreator('/docs/api/lusid/endpoints/entities/GetDataTypeByEntityUniqueId', '123'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/entities/GetEntityHistory',
                component: ComponentCreator('/docs/api/lusid/endpoints/entities/GetEntityHistory', 'cbc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/entities/GetInstrumentByEntityUniqueId',
                component: ComponentCreator('/docs/api/lusid/endpoints/entities/GetInstrumentByEntityUniqueId', '80d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/entities/GetPortfolioByEntityUniqueId',
                component: ComponentCreator('/docs/api/lusid/endpoints/entities/GetPortfolioByEntityUniqueId', '556'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/entities/GetPortfolioChanges',
                component: ComponentCreator('/docs/api/lusid/endpoints/entities/GetPortfolioChanges', '546'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/entities/GetPropertyDefinitionByEntityUniqueId',
                component: ComponentCreator('/docs/api/lusid/endpoints/entities/GetPropertyDefinitionByEntityUniqueId', 'caa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/executions/DeleteExecution',
                component: ComponentCreator('/docs/api/lusid/endpoints/executions/DeleteExecution', '794'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/executions/GetExecution',
                component: ComponentCreator('/docs/api/lusid/endpoints/executions/GetExecution', '75b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/executions/ListExecutions',
                component: ComponentCreator('/docs/api/lusid/endpoints/executions/ListExecutions', 'a74'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/executions/UpsertExecutions',
                component: ComponentCreator('/docs/api/lusid/endpoints/executions/UpsertExecutions', '0ec'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/fee-types/CreateFeeType',
                component: ComponentCreator('/docs/api/lusid/endpoints/fee-types/CreateFeeType', 'dcd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/fee-types/DeleteFeeType',
                component: ComponentCreator('/docs/api/lusid/endpoints/fee-types/DeleteFeeType', '95e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/fee-types/GetFeeTemplateSpecifications',
                component: ComponentCreator('/docs/api/lusid/endpoints/fee-types/GetFeeTemplateSpecifications', '697'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/fee-types/GetFeeType',
                component: ComponentCreator('/docs/api/lusid/endpoints/fee-types/GetFeeType', '318'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/fee-types/ListFeeTypes',
                component: ComponentCreator('/docs/api/lusid/endpoints/fee-types/ListFeeTypes', 'd49'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/fee-types/UpdateFeeType',
                component: ComponentCreator('/docs/api/lusid/endpoints/fee-types/UpdateFeeType', 'c50'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/fund-configuration/CreateFundConfiguration',
                component: ComponentCreator('/docs/api/lusid/endpoints/fund-configuration/CreateFundConfiguration', 'a73'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/fund-configuration/DeleteFundConfiguration',
                component: ComponentCreator('/docs/api/lusid/endpoints/fund-configuration/DeleteFundConfiguration', 'b79'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/fund-configuration/GetFundConfiguration',
                component: ComponentCreator('/docs/api/lusid/endpoints/fund-configuration/GetFundConfiguration', 'fd1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/fund-configuration/ListFundConfigurations',
                component: ComponentCreator('/docs/api/lusid/endpoints/fund-configuration/ListFundConfigurations', '372'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/fund-configuration/PatchFundConfiguration',
                component: ComponentCreator('/docs/api/lusid/endpoints/fund-configuration/PatchFundConfiguration', 'b72'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/fund-configuration/UpsertFundConfigurationProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/fund-configuration/UpsertFundConfigurationProperties', 'bba'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/AcceptEstimateValuationPoint',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/AcceptEstimateValuationPoint', 'c85'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/CreateFee',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/CreateFee', '99d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/CreateFund',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/CreateFund', '8cd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/DeleteFee',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/DeleteFee', '4e3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/DeleteFund',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/DeleteFund', 'ff5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/DeleteValuationPoint',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/DeleteValuationPoint', 'dc7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/FinaliseCandidateValuationPoint',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/FinaliseCandidateValuationPoint', '173'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/GetFee',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/GetFee', 'e95'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/GetFeeProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/GetFeeProperties', '1c0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/GetFund',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/GetFund', '081'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/GetFundProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/GetFundProperties', '7d0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/GetValuationPointData',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/GetValuationPointData', '13c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/GetValuationPointJournalEntryLines',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/GetValuationPointJournalEntryLines', 'f99'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/GetValuationPointPnlSummary',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/GetValuationPointPnlSummary', 'e4f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/GetValuationPointTransactions',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/GetValuationPointTransactions', '567'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/GetValuationPointTrialBalance',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/GetValuationPointTrialBalance', 'a2c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/ListFees',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/ListFees', '9ea'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/ListFunds',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/ListFunds', 'd5b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/ListValuationPointOverview',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/ListValuationPointOverview', 'da9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/PatchFee',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/PatchFee', '1da'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/PatchFund',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/PatchFund', '043'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/SetShareClassInstruments',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/SetShareClassInstruments', 'f9e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/UpsertDiaryEntryTypeValuationPoint',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/UpsertDiaryEntryTypeValuationPoint', '5a2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/UpsertFeeProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/UpsertFeeProperties', '123'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/funds/UpsertFundProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/funds/UpsertFundProperties', '976'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/group-reconciliations/BatchUpdateComparisonResults',
                component: ComponentCreator('/docs/api/lusid/endpoints/group-reconciliations/BatchUpdateComparisonResults', 'afc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/group-reconciliations/CreateComparisonRuleset',
                component: ComponentCreator('/docs/api/lusid/endpoints/group-reconciliations/CreateComparisonRuleset', '051'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/group-reconciliations/CreateGroupReconciliationDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/group-reconciliations/CreateGroupReconciliationDefinition', 'bcc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/group-reconciliations/DeleteComparisonRuleset',
                component: ComponentCreator('/docs/api/lusid/endpoints/group-reconciliations/DeleteComparisonRuleset', '65a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/group-reconciliations/DeleteGroupReconciliationDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/group-reconciliations/DeleteGroupReconciliationDefinition', 'faa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/group-reconciliations/GetComparisonResult',
                component: ComponentCreator('/docs/api/lusid/endpoints/group-reconciliations/GetComparisonResult', '1df'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/group-reconciliations/GetComparisonRuleset',
                component: ComponentCreator('/docs/api/lusid/endpoints/group-reconciliations/GetComparisonRuleset', 'cb7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/group-reconciliations/GetGroupReconciliationDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/group-reconciliations/GetGroupReconciliationDefinition', 'a86'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/group-reconciliations/ListComparisonResults',
                component: ComponentCreator('/docs/api/lusid/endpoints/group-reconciliations/ListComparisonResults', 'ba9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/group-reconciliations/ListComparisonRulesets',
                component: ComponentCreator('/docs/api/lusid/endpoints/group-reconciliations/ListComparisonRulesets', 'b82'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/group-reconciliations/ListGroupReconciliationDefinitions',
                component: ComponentCreator('/docs/api/lusid/endpoints/group-reconciliations/ListGroupReconciliationDefinitions', '87b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/group-reconciliations/RunReconciliation',
                component: ComponentCreator('/docs/api/lusid/endpoints/group-reconciliations/RunReconciliation', 'c29'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/group-reconciliations/UpdateComparisonRuleset',
                component: ComponentCreator('/docs/api/lusid/endpoints/group-reconciliations/UpdateComparisonRuleset', 'd00'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/group-reconciliations/UpdateGroupReconciliationDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/group-reconciliations/UpdateGroupReconciliationDefinition', '420'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/identifier-definitions/CreateIdentifierDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/identifier-definitions/CreateIdentifierDefinition', 'ace'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/identifier-definitions/DeleteIdentifierDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/identifier-definitions/DeleteIdentifierDefinition', '9cc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/identifier-definitions/GetIdentifierDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/identifier-definitions/GetIdentifierDefinition', 'dc7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/identifier-definitions/ListIdentifierDefinitions',
                component: ComponentCreator('/docs/api/lusid/endpoints/identifier-definitions/ListIdentifierDefinitions', '2ba'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/identifier-definitions/UpdateIdentifierDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/identifier-definitions/UpdateIdentifierDefinition', '248'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instrument-event-types/CreateTransactionTemplate',
                component: ComponentCreator('/docs/api/lusid/endpoints/instrument-event-types/CreateTransactionTemplate', '181'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instrument-event-types/DeleteTransactionTemplate',
                component: ComponentCreator('/docs/api/lusid/endpoints/instrument-event-types/DeleteTransactionTemplate', 'ac0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instrument-event-types/GetTransactionTemplate',
                component: ComponentCreator('/docs/api/lusid/endpoints/instrument-event-types/GetTransactionTemplate', 'b12'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instrument-event-types/GetTransactionTemplateSpecification',
                component: ComponentCreator('/docs/api/lusid/endpoints/instrument-event-types/GetTransactionTemplateSpecification', '1c3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instrument-event-types/ListTransactionTemplates',
                component: ComponentCreator('/docs/api/lusid/endpoints/instrument-event-types/ListTransactionTemplates', '528'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instrument-event-types/ListTransactionTemplateSpecifications',
                component: ComponentCreator('/docs/api/lusid/endpoints/instrument-event-types/ListTransactionTemplateSpecifications', 'c8b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instrument-event-types/UpdateTransactionTemplate',
                component: ComponentCreator('/docs/api/lusid/endpoints/instrument-event-types/UpdateTransactionTemplate', '10e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instrument-events/QueryApplicableInstrumentEvents',
                component: ComponentCreator('/docs/api/lusid/endpoints/instrument-events/QueryApplicableInstrumentEvents', '4b0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instrument-events/QueryBucketedCashFlows',
                component: ComponentCreator('/docs/api/lusid/endpoints/instrument-events/QueryBucketedCashFlows', '520'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instrument-events/QueryCashFlows',
                component: ComponentCreator('/docs/api/lusid/endpoints/instrument-events/QueryCashFlows', '626'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instrument-events/QueryInstrumentEvents',
                component: ComponentCreator('/docs/api/lusid/endpoints/instrument-events/QueryInstrumentEvents', 'df4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instrument-events/QueryTradeTickets',
                component: ComponentCreator('/docs/api/lusid/endpoints/instrument-events/QueryTradeTickets', '67b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instruments/BatchUpsertInstrumentProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/instruments/BatchUpsertInstrumentProperties', '127'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instruments/CalculateSettlementDate',
                component: ComponentCreator('/docs/api/lusid/endpoints/instruments/CalculateSettlementDate', 'cc0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instruments/DeleteInstrument',
                component: ComponentCreator('/docs/api/lusid/endpoints/instruments/DeleteInstrument', '838'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instruments/DeleteInstrumentProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/instruments/DeleteInstrumentProperties', '686'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instruments/DeleteInstruments',
                component: ComponentCreator('/docs/api/lusid/endpoints/instruments/DeleteInstruments', 'e98'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instruments/GetAllPossibleFeatures',
                component: ComponentCreator('/docs/api/lusid/endpoints/instruments/GetAllPossibleFeatures', 'c0f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instruments/GetExistingInstrumentCapabilities',
                component: ComponentCreator('/docs/api/lusid/endpoints/instruments/GetExistingInstrumentCapabilities', '66b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instruments/GetExistingInstrumentModels',
                component: ComponentCreator('/docs/api/lusid/endpoints/instruments/GetExistingInstrumentModels', '5f7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instruments/GetInstrument',
                component: ComponentCreator('/docs/api/lusid/endpoints/instruments/GetInstrument', 'c6e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instruments/GetInstrumentIdentifierTypes',
                component: ComponentCreator('/docs/api/lusid/endpoints/instruments/GetInstrumentIdentifierTypes', 'b24'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instruments/GetInstrumentPaymentDiary',
                component: ComponentCreator('/docs/api/lusid/endpoints/instruments/GetInstrumentPaymentDiary', '2ec'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instruments/GetInstrumentProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/instruments/GetInstrumentProperties', '668'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instruments/GetInstrumentPropertyTimeSeries',
                component: ComponentCreator('/docs/api/lusid/endpoints/instruments/GetInstrumentPropertyTimeSeries', '3ad'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instruments/GetInstrumentRelationships',
                component: ComponentCreator('/docs/api/lusid/endpoints/instruments/GetInstrumentRelationships', 'e4f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instruments/GetInstruments',
                component: ComponentCreator('/docs/api/lusid/endpoints/instruments/GetInstruments', '37a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instruments/ListInstrumentProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/instruments/ListInstrumentProperties', 'aa9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instruments/ListInstruments',
                component: ComponentCreator('/docs/api/lusid/endpoints/instruments/ListInstruments', '507'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instruments/QueryInstrumentCapabilities',
                component: ComponentCreator('/docs/api/lusid/endpoints/instruments/QueryInstrumentCapabilities', '471'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instruments/UpdateInstrumentIdentifier',
                component: ComponentCreator('/docs/api/lusid/endpoints/instruments/UpdateInstrumentIdentifier', '69e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instruments/UpsertInstruments',
                component: ComponentCreator('/docs/api/lusid/endpoints/instruments/UpsertInstruments', 'c7a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/instruments/UpsertInstrumentsProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/instruments/UpsertInstrumentsProperties', '6bd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legacy-compliance/DeleteLegacyComplianceRule',
                component: ComponentCreator('/docs/api/lusid/endpoints/legacy-compliance/DeleteLegacyComplianceRule', '61f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legacy-compliance/GetLegacyBreachedOrdersInfo',
                component: ComponentCreator('/docs/api/lusid/endpoints/legacy-compliance/GetLegacyBreachedOrdersInfo', '179'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legacy-compliance/GetLegacyComplianceRule',
                component: ComponentCreator('/docs/api/lusid/endpoints/legacy-compliance/GetLegacyComplianceRule', 'bdc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legacy-compliance/GetLegacyComplianceRunResults',
                component: ComponentCreator('/docs/api/lusid/endpoints/legacy-compliance/GetLegacyComplianceRunResults', '6cc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legacy-compliance/ListLegacyComplianceRules',
                component: ComponentCreator('/docs/api/lusid/endpoints/legacy-compliance/ListLegacyComplianceRules', '3d5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legacy-compliance/ListLegacyComplianceRunInfo',
                component: ComponentCreator('/docs/api/lusid/endpoints/legacy-compliance/ListLegacyComplianceRunInfo', '17f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legacy-compliance/RunLegacyCompliance',
                component: ComponentCreator('/docs/api/lusid/endpoints/legacy-compliance/RunLegacyCompliance', '9bb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legacy-compliance/UpsertLegacyComplianceRules',
                component: ComponentCreator('/docs/api/lusid/endpoints/legacy-compliance/UpsertLegacyComplianceRules', '480'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legal-entities/DeleteLegalEntity',
                component: ComponentCreator('/docs/api/lusid/endpoints/legal-entities/DeleteLegalEntity', '450'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legal-entities/DeleteLegalEntityAccessMetadata',
                component: ComponentCreator('/docs/api/lusid/endpoints/legal-entities/DeleteLegalEntityAccessMetadata', 'd00'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legal-entities/DeleteLegalEntityIdentifiers',
                component: ComponentCreator('/docs/api/lusid/endpoints/legal-entities/DeleteLegalEntityIdentifiers', '3f2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legal-entities/DeleteLegalEntityProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/legal-entities/DeleteLegalEntityProperties', 'bb5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legal-entities/GetAllLegalEntityAccessMetadata',
                component: ComponentCreator('/docs/api/lusid/endpoints/legal-entities/GetAllLegalEntityAccessMetadata', '23b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legal-entities/GetLegalEntity',
                component: ComponentCreator('/docs/api/lusid/endpoints/legal-entities/GetLegalEntity', 'f6f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legal-entities/GetLegalEntityAccessMetadataByKey',
                component: ComponentCreator('/docs/api/lusid/endpoints/legal-entities/GetLegalEntityAccessMetadataByKey', 'a7b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legal-entities/GetLegalEntityPropertyTimeSeries',
                component: ComponentCreator('/docs/api/lusid/endpoints/legal-entities/GetLegalEntityPropertyTimeSeries', '0a4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legal-entities/GetLegalEntityRelations',
                component: ComponentCreator('/docs/api/lusid/endpoints/legal-entities/GetLegalEntityRelations', '91b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legal-entities/GetLegalEntityRelationships',
                component: ComponentCreator('/docs/api/lusid/endpoints/legal-entities/GetLegalEntityRelationships', '295'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legal-entities/ListAllLegalEntities',
                component: ComponentCreator('/docs/api/lusid/endpoints/legal-entities/ListAllLegalEntities', '3cf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legal-entities/ListLegalEntities',
                component: ComponentCreator('/docs/api/lusid/endpoints/legal-entities/ListLegalEntities', '419'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legal-entities/PatchLegalEntityAccessMetadata',
                component: ComponentCreator('/docs/api/lusid/endpoints/legal-entities/PatchLegalEntityAccessMetadata', '020'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legal-entities/SetLegalEntityIdentifiers',
                component: ComponentCreator('/docs/api/lusid/endpoints/legal-entities/SetLegalEntityIdentifiers', '014'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legal-entities/SetLegalEntityProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/legal-entities/SetLegalEntityProperties', 'a2e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legal-entities/UpsertLegalEntities',
                component: ComponentCreator('/docs/api/lusid/endpoints/legal-entities/UpsertLegalEntities', '803'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legal-entities/UpsertLegalEntity',
                component: ComponentCreator('/docs/api/lusid/endpoints/legal-entities/UpsertLegalEntity', '1fb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/legal-entities/UpsertLegalEntityAccessMetadata',
                component: ComponentCreator('/docs/api/lusid/endpoints/legal-entities/UpsertLegalEntityAccessMetadata', '252'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/order-graph/ListOrderGraphBlocks',
                component: ComponentCreator('/docs/api/lusid/endpoints/order-graph/ListOrderGraphBlocks', '78a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/order-graph/ListOrderGraphPlacementChildren',
                component: ComponentCreator('/docs/api/lusid/endpoints/order-graph/ListOrderGraphPlacementChildren', 'f18'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/order-graph/ListOrderGraphPlacements',
                component: ComponentCreator('/docs/api/lusid/endpoints/order-graph/ListOrderGraphPlacements', '791'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/order-instructions/DeleteOrderInstruction',
                component: ComponentCreator('/docs/api/lusid/endpoints/order-instructions/DeleteOrderInstruction', '7c0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/order-instructions/GetOrderInstruction',
                component: ComponentCreator('/docs/api/lusid/endpoints/order-instructions/GetOrderInstruction', '3c6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/order-instructions/ListOrderInstructions',
                component: ComponentCreator('/docs/api/lusid/endpoints/order-instructions/ListOrderInstructions', '175'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/order-instructions/UpsertOrderInstructions',
                component: ComponentCreator('/docs/api/lusid/endpoints/order-instructions/UpsertOrderInstructions', 'ad1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/order-management/BookTransactions',
                component: ComponentCreator('/docs/api/lusid/endpoints/order-management/BookTransactions', '6d9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/order-management/CancelOrders',
                component: ComponentCreator('/docs/api/lusid/endpoints/order-management/CancelOrders', '0a4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/order-management/CancelOrdersAndMoveRemaining',
                component: ComponentCreator('/docs/api/lusid/endpoints/order-management/CancelOrdersAndMoveRemaining', '5d7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/order-management/CancelPlacements',
                component: ComponentCreator('/docs/api/lusid/endpoints/order-management/CancelPlacements', '21c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/order-management/CreateOrders',
                component: ComponentCreator('/docs/api/lusid/endpoints/order-management/CreateOrders', '290'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/order-management/GetOrderHistory',
                component: ComponentCreator('/docs/api/lusid/endpoints/order-management/GetOrderHistory', 'db8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/order-management/MoveOrders',
                component: ComponentCreator('/docs/api/lusid/endpoints/order-management/MoveOrders', '750'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/order-management/PlaceBlocks',
                component: ComponentCreator('/docs/api/lusid/endpoints/order-management/PlaceBlocks', 'e64'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/order-management/RunAllocationService',
                component: ComponentCreator('/docs/api/lusid/endpoints/order-management/RunAllocationService', 'ea2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/order-management/UpdateOrders',
                component: ComponentCreator('/docs/api/lusid/endpoints/order-management/UpdateOrders', '5bd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/order-management/UpdatePlacements',
                component: ComponentCreator('/docs/api/lusid/endpoints/order-management/UpdatePlacements', 'a2c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/orders/DeleteOrder',
                component: ComponentCreator('/docs/api/lusid/endpoints/orders/DeleteOrder', 'e7d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/orders/GetOrder',
                component: ComponentCreator('/docs/api/lusid/endpoints/orders/GetOrder', 'bd5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/orders/ListOrders',
                component: ComponentCreator('/docs/api/lusid/endpoints/orders/ListOrders', 'f2b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/orders/UpsertOrders',
                component: ComponentCreator('/docs/api/lusid/endpoints/orders/UpsertOrders', '3e8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/packages/DeletePackage',
                component: ComponentCreator('/docs/api/lusid/endpoints/packages/DeletePackage', '462'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/packages/GetPackage',
                component: ComponentCreator('/docs/api/lusid/endpoints/packages/GetPackage', '118'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/packages/ListPackages',
                component: ComponentCreator('/docs/api/lusid/endpoints/packages/ListPackages', '9b1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/packages/UpsertPackages',
                component: ComponentCreator('/docs/api/lusid/endpoints/packages/UpsertPackages', '671'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/participations/DeleteParticipation',
                component: ComponentCreator('/docs/api/lusid/endpoints/participations/DeleteParticipation', '0e2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/participations/GetParticipation',
                component: ComponentCreator('/docs/api/lusid/endpoints/participations/GetParticipation', '5a5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/participations/ListParticipations',
                component: ComponentCreator('/docs/api/lusid/endpoints/participations/ListParticipations', '9be'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/participations/UpsertParticipations',
                component: ComponentCreator('/docs/api/lusid/endpoints/participations/UpsertParticipations', 'cb6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/persons/DeletePerson',
                component: ComponentCreator('/docs/api/lusid/endpoints/persons/DeletePerson', 'ac8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/persons/DeletePersonAccessMetadata',
                component: ComponentCreator('/docs/api/lusid/endpoints/persons/DeletePersonAccessMetadata', 'b91'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/persons/DeletePersonIdentifiers',
                component: ComponentCreator('/docs/api/lusid/endpoints/persons/DeletePersonIdentifiers', 'ff9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/persons/DeletePersonProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/persons/DeletePersonProperties', '33d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/persons/GetAllPersonAccessMetadata',
                component: ComponentCreator('/docs/api/lusid/endpoints/persons/GetAllPersonAccessMetadata', '5e7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/persons/GetPerson',
                component: ComponentCreator('/docs/api/lusid/endpoints/persons/GetPerson', 'f2c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/persons/GetPersonAccessMetadataByKey',
                component: ComponentCreator('/docs/api/lusid/endpoints/persons/GetPersonAccessMetadataByKey', 'e74'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/persons/GetPersonPropertyTimeSeries',
                component: ComponentCreator('/docs/api/lusid/endpoints/persons/GetPersonPropertyTimeSeries', '447'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/persons/GetPersonRelations',
                component: ComponentCreator('/docs/api/lusid/endpoints/persons/GetPersonRelations', '0df'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/persons/GetPersonRelationships',
                component: ComponentCreator('/docs/api/lusid/endpoints/persons/GetPersonRelationships', 'bc5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/persons/ListAllPersons',
                component: ComponentCreator('/docs/api/lusid/endpoints/persons/ListAllPersons', '4bd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/persons/ListPersons',
                component: ComponentCreator('/docs/api/lusid/endpoints/persons/ListPersons', '4c1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/persons/PatchPersonAccessMetadata',
                component: ComponentCreator('/docs/api/lusid/endpoints/persons/PatchPersonAccessMetadata', 'f83'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/persons/SetPersonIdentifiers',
                component: ComponentCreator('/docs/api/lusid/endpoints/persons/SetPersonIdentifiers', '236'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/persons/SetPersonProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/persons/SetPersonProperties', 'dce'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/persons/UpsertPerson',
                component: ComponentCreator('/docs/api/lusid/endpoints/persons/UpsertPerson', '53a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/persons/UpsertPersonAccessMetadata',
                component: ComponentCreator('/docs/api/lusid/endpoints/persons/UpsertPersonAccessMetadata', '2ff'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/persons/UpsertPersons',
                component: ComponentCreator('/docs/api/lusid/endpoints/persons/UpsertPersons', '035'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/placements/DeletePlacement',
                component: ComponentCreator('/docs/api/lusid/endpoints/placements/DeletePlacement', 'f2d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/placements/GetPlacement',
                component: ComponentCreator('/docs/api/lusid/endpoints/placements/GetPlacement', '4bc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/placements/ListPlacements',
                component: ComponentCreator('/docs/api/lusid/endpoints/placements/ListPlacements', 'a4d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/placements/UpsertPlacements',
                component: ComponentCreator('/docs/api/lusid/endpoints/placements/UpsertPlacements', '1a7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/AddPortfolioToGroup',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/AddPortfolioToGroup', '48d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/AddSubGroupToGroup',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/AddSubGroupToGroup', 'a52'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/BuildTransactionsForPortfolioGroup',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/BuildTransactionsForPortfolioGroup', 'd1f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/CreatePortfolioGroup',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/CreatePortfolioGroup', 'a10'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/DeleteGroupProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/DeleteGroupProperties', '2bc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/DeleteKeyFromPortfolioGroupAccessMetadata',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/DeleteKeyFromPortfolioGroupAccessMetadata', '865'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/DeletePortfolioFromGroup',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/DeletePortfolioFromGroup', 'c83'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/DeletePortfolioGroup',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/DeletePortfolioGroup', '205'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/DeleteSubGroupFromGroup',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/DeleteSubGroupFromGroup', '323'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/GetA2BDataForPortfolioGroup',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/GetA2BDataForPortfolioGroup', 'f9b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/GetGroupProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/GetGroupProperties', '256'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/GetHoldingsForPortfolioGroup',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/GetHoldingsForPortfolioGroup', '14f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/GetPortfolioGroup',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/GetPortfolioGroup', 'bd8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/GetPortfolioGroupAccessMetadataByKey',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/GetPortfolioGroupAccessMetadataByKey', '572'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/GetPortfolioGroupCommands',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/GetPortfolioGroupCommands', '9bf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/GetPortfolioGroupExpansion',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/GetPortfolioGroupExpansion', '6d1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/GetPortfolioGroupMetadata',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/GetPortfolioGroupMetadata', 'b18'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/GetPortfolioGroupPropertyTimeSeries',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/GetPortfolioGroupPropertyTimeSeries', '459'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/GetPortfolioGroupRelations',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/GetPortfolioGroupRelations', 'a4b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/GetPortfolioGroupRelationships',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/GetPortfolioGroupRelationships', 'e1b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/GetTransactionsForPortfolioGroup',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/GetTransactionsForPortfolioGroup', 'bd3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/ListPortfolioGroups',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/ListPortfolioGroups', 'd75'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/PatchPortfolioGroupAccessMetadata',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/PatchPortfolioGroupAccessMetadata', '1db'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/UpdatePortfolioGroup',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/UpdatePortfolioGroup', '7e0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/UpsertGroupProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/UpsertGroupProperties', '116'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolio-groups/UpsertPortfolioGroupAccessMetadata',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolio-groups/UpsertPortfolioGroupAccessMetadata', '811'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/BatchUpsertPortfolioAccessMetadata',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/BatchUpsertPortfolioAccessMetadata', '4ec'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/DeleteInstrumentEventInstruction',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/DeleteInstrumentEventInstruction', 'bf8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/DeleteKeyFromPortfolioAccessMetadata',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/DeleteKeyFromPortfolioAccessMetadata', '719'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/DeletePortfolio',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/DeletePortfolio', '01a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/DeletePortfolioProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/DeletePortfolioProperties', 'c58'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/DeletePortfolioReturns',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/DeletePortfolioReturns', 'fc7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/GetAggregatedReturnsDispersionMetrics',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/GetAggregatedReturnsDispersionMetrics', '5b0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/GetCompositeBreakdown',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/GetCompositeBreakdown', '625'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/GetInstrumentEventInstruction',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/GetInstrumentEventInstruction', 'fb3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/GetPortfolio',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/GetPortfolio', '18f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/GetPortfolioAggregatedReturns',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/GetPortfolioAggregatedReturns', 'cfb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/GetPortfolioAggregateReturns',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/GetPortfolioAggregateReturns', '2d0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/GetPortfolioCommands',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/GetPortfolioCommands', '32b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/GetPortfolioMetadata',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/GetPortfolioMetadata', 'c3d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/GetPortfolioProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/GetPortfolioProperties', '01d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/GetPortfolioPropertyTimeSeries',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/GetPortfolioPropertyTimeSeries', '05e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/GetPortfolioRelations',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/GetPortfolioRelations', 'aef'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/GetPortfolioRelationships',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/GetPortfolioRelationships', '658'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/GetPortfolioReturns',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/GetPortfolioReturns', 'fc5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/GetPortfoliosAccessMetadataByKey',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/GetPortfoliosAccessMetadataByKey', 'c8c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/ListInstrumentEventInstructions',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/ListInstrumentEventInstructions', '0d1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/ListPortfolioProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/ListPortfolioProperties', 'e82'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/ListPortfolios',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/ListPortfolios', '0f3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/ListPortfoliosForScope',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/ListPortfoliosForScope', 'e93'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/PatchPortfolio',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/PatchPortfolio', 'ba6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/PatchPortfolioAccessMetadata',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/PatchPortfolioAccessMetadata', '3d0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/UpdatePortfolio',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/UpdatePortfolio', 'b70'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/UpsertInstrumentEventInstructions',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/UpsertInstrumentEventInstructions', 'd10'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/UpsertPortfolioAccessMetadata',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/UpsertPortfolioAccessMetadata', '1f2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/UpsertPortfolioProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/UpsertPortfolioProperties', 'cb7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/portfolios/UpsertPortfolioReturns',
                component: ComponentCreator('/docs/api/lusid/endpoints/portfolios/UpsertPortfolioReturns', 'e00'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/property-definitions/CreateDerivedPropertyDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/property-definitions/CreateDerivedPropertyDefinition', '358'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/property-definitions/CreatePropertyDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/property-definitions/CreatePropertyDefinition', '87f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/property-definitions/DeletePropertyDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/property-definitions/DeletePropertyDefinition', '386'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/property-definitions/DeletePropertyDefinitionProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/property-definitions/DeletePropertyDefinitionProperties', '634'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/property-definitions/GetMultiplePropertyDefinitions',
                component: ComponentCreator('/docs/api/lusid/endpoints/property-definitions/GetMultiplePropertyDefinitions', '369'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/property-definitions/GetPropertyDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/property-definitions/GetPropertyDefinition', 'e71'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/property-definitions/GetPropertyDefinitionPropertyTimeSeries',
                component: ComponentCreator('/docs/api/lusid/endpoints/property-definitions/GetPropertyDefinitionPropertyTimeSeries', 'f54'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/property-definitions/ListPropertyDefinitions',
                component: ComponentCreator('/docs/api/lusid/endpoints/property-definitions/ListPropertyDefinitions', '71f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/property-definitions/UpdateDerivedPropertyDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/property-definitions/UpdateDerivedPropertyDefinition', 'd64'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/property-definitions/UpdatePropertyDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/property-definitions/UpdatePropertyDefinition', '1ef'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/property-definitions/UpsertPropertyDefinitionProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/property-definitions/UpsertPropertyDefinitionProperties', 'c22'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/queryable-keys/GetAllQueryableKeys',
                component: ComponentCreator('/docs/api/lusid/endpoints/queryable-keys/GetAllQueryableKeys', 'f00'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/quotes/DeleteQuoteAccessMetadataRule',
                component: ComponentCreator('/docs/api/lusid/endpoints/quotes/DeleteQuoteAccessMetadataRule', 'ab2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/quotes/DeleteQuotes',
                component: ComponentCreator('/docs/api/lusid/endpoints/quotes/DeleteQuotes', '331'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/quotes/GetQuotes',
                component: ComponentCreator('/docs/api/lusid/endpoints/quotes/GetQuotes', 'd32'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/quotes/GetQuotesAccessMetadataRule',
                component: ComponentCreator('/docs/api/lusid/endpoints/quotes/GetQuotesAccessMetadataRule', '9f7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/quotes/ListQuotes',
                component: ComponentCreator('/docs/api/lusid/endpoints/quotes/ListQuotes', '167'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/quotes/ListQuotesAccessMetadataRules',
                component: ComponentCreator('/docs/api/lusid/endpoints/quotes/ListQuotesAccessMetadataRules', '46e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/quotes/ListQuotesForScope',
                component: ComponentCreator('/docs/api/lusid/endpoints/quotes/ListQuotesForScope', '6fa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/quotes/UpsertQuoteAccessMetadataRule',
                component: ComponentCreator('/docs/api/lusid/endpoints/quotes/UpsertQuoteAccessMetadataRule', '747'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/quotes/UpsertQuotes',
                component: ComponentCreator('/docs/api/lusid/endpoints/quotes/UpsertQuotes', '6e2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reconciliations/CreateScheduledReconciliation',
                component: ComponentCreator('/docs/api/lusid/endpoints/reconciliations/CreateScheduledReconciliation', '7a7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reconciliations/DeleteReconciliation',
                component: ComponentCreator('/docs/api/lusid/endpoints/reconciliations/DeleteReconciliation', '533'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reconciliations/DeleteReconciliationMapping',
                component: ComponentCreator('/docs/api/lusid/endpoints/reconciliations/DeleteReconciliationMapping', 'a99'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reconciliations/GetReconciliation',
                component: ComponentCreator('/docs/api/lusid/endpoints/reconciliations/GetReconciliation', '239'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reconciliations/GetReconciliationMapping',
                component: ComponentCreator('/docs/api/lusid/endpoints/reconciliations/GetReconciliationMapping', '5a8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reconciliations/ListReconciliationMappings',
                component: ComponentCreator('/docs/api/lusid/endpoints/reconciliations/ListReconciliationMappings', '6ed'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reconciliations/ListReconciliations',
                component: ComponentCreator('/docs/api/lusid/endpoints/reconciliations/ListReconciliations', '07e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reconciliations/ReconcileGeneric',
                component: ComponentCreator('/docs/api/lusid/endpoints/reconciliations/ReconcileGeneric', 'e58'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reconciliations/ReconcileHoldings',
                component: ComponentCreator('/docs/api/lusid/endpoints/reconciliations/ReconcileHoldings', 'f85'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reconciliations/ReconcileInline',
                component: ComponentCreator('/docs/api/lusid/endpoints/reconciliations/ReconcileInline', '141'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reconciliations/ReconcileTransactions',
                component: ComponentCreator('/docs/api/lusid/endpoints/reconciliations/ReconcileTransactions', 'c2f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reconciliations/ReconcileTransactionsV2',
                component: ComponentCreator('/docs/api/lusid/endpoints/reconciliations/ReconcileTransactionsV2', '7ae'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reconciliations/ReconcileValuation',
                component: ComponentCreator('/docs/api/lusid/endpoints/reconciliations/ReconcileValuation', '090'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reconciliations/UpdateReconciliation',
                component: ComponentCreator('/docs/api/lusid/endpoints/reconciliations/UpdateReconciliation', 'fd2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reconciliations/UpsertReconciliationMapping',
                component: ComponentCreator('/docs/api/lusid/endpoints/reconciliations/UpsertReconciliationMapping', '99f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reference-lists/DeleteReferenceList',
                component: ComponentCreator('/docs/api/lusid/endpoints/reference-lists/DeleteReferenceList', '965'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reference-lists/GetReferenceList',
                component: ComponentCreator('/docs/api/lusid/endpoints/reference-lists/GetReferenceList', 'e6b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reference-lists/ListReferenceLists',
                component: ComponentCreator('/docs/api/lusid/endpoints/reference-lists/ListReferenceLists', 'd19'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reference-lists/UpsertReferenceList',
                component: ComponentCreator('/docs/api/lusid/endpoints/reference-lists/UpsertReferenceList', '2b4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reference-portfolio/CreateReferencePortfolio',
                component: ComponentCreator('/docs/api/lusid/endpoints/reference-portfolio/CreateReferencePortfolio', '813'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reference-portfolio/GetReferencePortfolioConstituents',
                component: ComponentCreator('/docs/api/lusid/endpoints/reference-portfolio/GetReferencePortfolioConstituents', 'b53'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reference-portfolio/ListConstituentsAdjustments',
                component: ComponentCreator('/docs/api/lusid/endpoints/reference-portfolio/ListConstituentsAdjustments', '57e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reference-portfolio/UpsertReferencePortfolioConstituentProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/reference-portfolio/UpsertReferencePortfolioConstituentProperties', 'd67'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/reference-portfolio/UpsertReferencePortfolioConstituents',
                component: ComponentCreator('/docs/api/lusid/endpoints/reference-portfolio/UpsertReferencePortfolioConstituents', '7bf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/relation-definitions/CreateRelationDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/relation-definitions/CreateRelationDefinition', 'e1d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/relation-definitions/DeleteRelationDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/relation-definitions/DeleteRelationDefinition', '3d3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/relation-definitions/GetRelationDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/relation-definitions/GetRelationDefinition', '0ae'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/relations/CreateRelation',
                component: ComponentCreator('/docs/api/lusid/endpoints/relations/CreateRelation', 'a5f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/relations/DeleteRelation',
                component: ComponentCreator('/docs/api/lusid/endpoints/relations/DeleteRelation', 'f47'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/relationship-definitions/CreateRelationshipDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/relationship-definitions/CreateRelationshipDefinition', '8e9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/relationship-definitions/DeleteRelationshipDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/relationship-definitions/DeleteRelationshipDefinition', '504'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/relationship-definitions/GetRelationshipDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/relationship-definitions/GetRelationshipDefinition', 'a8f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/relationship-definitions/ListRelationshipDefinitions',
                component: ComponentCreator('/docs/api/lusid/endpoints/relationship-definitions/ListRelationshipDefinitions', '418'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/relationship-definitions/UpdateRelationshipDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/relationship-definitions/UpdateRelationshipDefinition', '5cc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/relationships/CreateRelationship',
                component: ComponentCreator('/docs/api/lusid/endpoints/relationships/CreateRelationship', '96c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/relationships/DeleteRelationship',
                component: ComponentCreator('/docs/api/lusid/endpoints/relationships/DeleteRelationship', 'fc7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/schemas/GetEntitySchema',
                component: ComponentCreator('/docs/api/lusid/endpoints/schemas/GetEntitySchema', '301'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/schemas/GetPropertySchema',
                component: ComponentCreator('/docs/api/lusid/endpoints/schemas/GetPropertySchema', '8b6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/schemas/GetValueTypes',
                component: ComponentCreator('/docs/api/lusid/endpoints/schemas/GetValueTypes', '77a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/schemas/ListEntities',
                component: ComponentCreator('/docs/api/lusid/endpoints/schemas/ListEntities', '85e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/scopes/ListEntityScopes',
                component: ComponentCreator('/docs/api/lusid/endpoints/scopes/ListEntityScopes', '117'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/scopes/ListScopes',
                component: ComponentCreator('/docs/api/lusid/endpoints/scopes/ListScopes', '8af'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/scripted-translation/GetTranslationDialect',
                component: ComponentCreator('/docs/api/lusid/endpoints/scripted-translation/GetTranslationDialect', 'a69'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/scripted-translation/GetTranslationScript',
                component: ComponentCreator('/docs/api/lusid/endpoints/scripted-translation/GetTranslationScript', '3cb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/scripted-translation/ListDialectIds',
                component: ComponentCreator('/docs/api/lusid/endpoints/scripted-translation/ListDialectIds', 'c41'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/scripted-translation/ListTranslationScriptIds',
                component: ComponentCreator('/docs/api/lusid/endpoints/scripted-translation/ListTranslationScriptIds', '2ea'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/scripted-translation/TranslateEntities',
                component: ComponentCreator('/docs/api/lusid/endpoints/scripted-translation/TranslateEntities', 'aa9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/scripted-translation/TranslateEntitiesInlined',
                component: ComponentCreator('/docs/api/lusid/endpoints/scripted-translation/TranslateEntitiesInlined', '58c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/scripted-translation/UpsertTranslationDialect',
                component: ComponentCreator('/docs/api/lusid/endpoints/scripted-translation/UpsertTranslationDialect', 'd78'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/scripted-translation/UpsertTranslationScript',
                component: ComponentCreator('/docs/api/lusid/endpoints/scripted-translation/UpsertTranslationScript', '80c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/search/InstrumentsSearch',
                component: ComponentCreator('/docs/api/lusid/endpoints/search/InstrumentsSearch', 'ab1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/search/SearchPortfolioGroups',
                component: ComponentCreator('/docs/api/lusid/endpoints/search/SearchPortfolioGroups', 'b96'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/search/SearchPortfolios',
                component: ComponentCreator('/docs/api/lusid/endpoints/search/SearchPortfolios', '2f5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/search/SearchProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/search/SearchProperties', '29c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/sequences/CreateSequence',
                component: ComponentCreator('/docs/api/lusid/endpoints/sequences/CreateSequence', '5f1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/sequences/GetSequence',
                component: ComponentCreator('/docs/api/lusid/endpoints/sequences/GetSequence', '0d6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/sequences/ListSequences',
                component: ComponentCreator('/docs/api/lusid/endpoints/sequences/ListSequences', 'adf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/sequences/Next',
                component: ComponentCreator('/docs/api/lusid/endpoints/sequences/Next', '617'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/simple-position-portfolios/CreateSimplePositionPortfolio',
                component: ComponentCreator('/docs/api/lusid/endpoints/simple-position-portfolios/CreateSimplePositionPortfolio', 'd42'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/staged-modifications/AddDecision',
                component: ComponentCreator('/docs/api/lusid/endpoints/staged-modifications/AddDecision', '020'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/staged-modifications/GetStagedModification',
                component: ComponentCreator('/docs/api/lusid/endpoints/staged-modifications/GetStagedModification', '2c6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/staged-modifications/ListRequestedChanges',
                component: ComponentCreator('/docs/api/lusid/endpoints/staged-modifications/ListRequestedChanges', 'c34'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/staged-modifications/ListStagedModifications',
                component: ComponentCreator('/docs/api/lusid/endpoints/staged-modifications/ListStagedModifications', '93e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/staging-rule-set/CreateStagingRuleSet',
                component: ComponentCreator('/docs/api/lusid/endpoints/staging-rule-set/CreateStagingRuleSet', '2f6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/staging-rule-set/DeleteStagingRuleSet',
                component: ComponentCreator('/docs/api/lusid/endpoints/staging-rule-set/DeleteStagingRuleSet', '1ac'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/staging-rule-set/GetStagingRuleSet',
                component: ComponentCreator('/docs/api/lusid/endpoints/staging-rule-set/GetStagingRuleSet', '859'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/staging-rule-set/ListStagingRuleSets',
                component: ComponentCreator('/docs/api/lusid/endpoints/staging-rule-set/ListStagingRuleSets', '632'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/staging-rule-set/UpdateStagingRuleSet',
                component: ComponentCreator('/docs/api/lusid/endpoints/staging-rule-set/UpdateStagingRuleSet', '8bf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/structured-result-data/CreateDataMap',
                component: ComponentCreator('/docs/api/lusid/endpoints/structured-result-data/CreateDataMap', '4d9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/structured-result-data/DeleteStructuredResultData',
                component: ComponentCreator('/docs/api/lusid/endpoints/structured-result-data/DeleteStructuredResultData', '58c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/structured-result-data/GetAddressKeyDefinitionsForDocument',
                component: ComponentCreator('/docs/api/lusid/endpoints/structured-result-data/GetAddressKeyDefinitionsForDocument', '3d9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/structured-result-data/GetDataMap',
                component: ComponentCreator('/docs/api/lusid/endpoints/structured-result-data/GetDataMap', '419'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/structured-result-data/GetStructuredResultData',
                component: ComponentCreator('/docs/api/lusid/endpoints/structured-result-data/GetStructuredResultData', '2d3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/structured-result-data/GetVirtualDocument',
                component: ComponentCreator('/docs/api/lusid/endpoints/structured-result-data/GetVirtualDocument', '03d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/structured-result-data/GetVirtualDocumentRows',
                component: ComponentCreator('/docs/api/lusid/endpoints/structured-result-data/GetVirtualDocumentRows', 'b91'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/structured-result-data/UpsertResultValue',
                component: ComponentCreator('/docs/api/lusid/endpoints/structured-result-data/UpsertResultValue', '34d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/structured-result-data/UpsertStructuredResultData',
                component: ComponentCreator('/docs/api/lusid/endpoints/structured-result-data/UpsertStructuredResultData', '024'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/system-configuration/CreateConfigurationTransactionType',
                component: ComponentCreator('/docs/api/lusid/endpoints/system-configuration/CreateConfigurationTransactionType', 'dad'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/system-configuration/CreateSideDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/system-configuration/CreateSideDefinition', 'db5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/system-configuration/DeleteTransactionConfigurationSource',
                component: ComponentCreator('/docs/api/lusid/endpoints/system-configuration/DeleteTransactionConfigurationSource', '52b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/system-configuration/GetTransactionConfigurationSource',
                component: ComponentCreator('/docs/api/lusid/endpoints/system-configuration/GetTransactionConfigurationSource', 'b23'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/system-configuration/ListConfigurationTransactionTypes',
                component: ComponentCreator('/docs/api/lusid/endpoints/system-configuration/ListConfigurationTransactionTypes', '7db'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/system-configuration/SetConfigurationTransactionTypes',
                component: ComponentCreator('/docs/api/lusid/endpoints/system-configuration/SetConfigurationTransactionTypes', '784'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/system-configuration/SetTransactionConfigurationSource',
                component: ComponentCreator('/docs/api/lusid/endpoints/system-configuration/SetTransactionConfigurationSource', '8de'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/tax-rule-sets/CreateTaxRuleSet',
                component: ComponentCreator('/docs/api/lusid/endpoints/tax-rule-sets/CreateTaxRuleSet', 'e4a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/tax-rule-sets/DeleteTaxRuleSet',
                component: ComponentCreator('/docs/api/lusid/endpoints/tax-rule-sets/DeleteTaxRuleSet', 'e4b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/tax-rule-sets/GetTaxRuleSet',
                component: ComponentCreator('/docs/api/lusid/endpoints/tax-rule-sets/GetTaxRuleSet', '511'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/tax-rule-sets/ListTaxRuleSets',
                component: ComponentCreator('/docs/api/lusid/endpoints/tax-rule-sets/ListTaxRuleSets', 'eaf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/tax-rule-sets/UpdateTaxRuleSet',
                component: ComponentCreator('/docs/api/lusid/endpoints/tax-rule-sets/UpdateTaxRuleSet', '41c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/timelines/CreateClosedPeriod',
                component: ComponentCreator('/docs/api/lusid/endpoints/timelines/CreateClosedPeriod', '8c6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/timelines/CreateTimeline',
                component: ComponentCreator('/docs/api/lusid/endpoints/timelines/CreateTimeline', '8e3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/timelines/DeleteTimeline',
                component: ComponentCreator('/docs/api/lusid/endpoints/timelines/DeleteTimeline', 'e1a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/timelines/GetClosedPeriod',
                component: ComponentCreator('/docs/api/lusid/endpoints/timelines/GetClosedPeriod', 'd06'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/timelines/GetTimeline',
                component: ComponentCreator('/docs/api/lusid/endpoints/timelines/GetTimeline', '017'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/timelines/ListClosedPeriods',
                component: ComponentCreator('/docs/api/lusid/endpoints/timelines/ListClosedPeriods', '379'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/timelines/ListTimelines',
                component: ComponentCreator('/docs/api/lusid/endpoints/timelines/ListTimelines', 'dd7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/timelines/UpdateTimeline',
                component: ComponentCreator('/docs/api/lusid/endpoints/timelines/UpdateTimeline', '26b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-configuration/DeleteSideDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-configuration/DeleteSideDefinition', '280'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-configuration/DeleteTransactionType',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-configuration/DeleteTransactionType', 'c7a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-configuration/DeleteTransactionTypeSource',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-configuration/DeleteTransactionTypeSource', 'ea2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-configuration/GetSideDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-configuration/GetSideDefinition', '230'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-configuration/GetTransactionType',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-configuration/GetTransactionType', '7a5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-configuration/ListSideDefinitions',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-configuration/ListSideDefinitions', '8fe'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-configuration/ListTransactionTypes',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-configuration/ListTransactionTypes', '6c9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-configuration/SetSideDefinition',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-configuration/SetSideDefinition', '1dc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-configuration/SetSideDefinitions',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-configuration/SetSideDefinitions', 'b9b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-configuration/SetTransactionType',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-configuration/SetTransactionType', '5a2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-configuration/SetTransactionTypeSource',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-configuration/SetTransactionTypeSource', 'de4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-fees/DeleteTransactionFeeRule',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-fees/DeleteTransactionFeeRule', '7cf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-fees/GetApplicableTransactionFees',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-fees/GetApplicableTransactionFees', 'ebf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-fees/GetTransactionFeeRule',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-fees/GetTransactionFeeRule', 'e39'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-fees/ListTransactionFeeRules',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-fees/ListTransactionFeeRules', 'e4f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-fees/UpsertTransactionFeeRules',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-fees/UpsertTransactionFeeRules', '3da'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/AdjustHoldings',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/AdjustHoldings', '41b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/BatchAdjustHoldings',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/BatchAdjustHoldings', '240'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/BatchCreateTradeTickets',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/BatchCreateTradeTickets', '046'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/BatchSetHoldings',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/BatchSetHoldings', '799'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/BatchUpsertTransactions',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/BatchUpsertTransactions', '90e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/BuildTransactions',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/BuildTransactions', '805'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/CancelAdjustHoldings',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/CancelAdjustHoldings', '9b3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/CancelSingleAdjustHolding',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/CancelSingleAdjustHolding', '6d1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/CancelTransactions',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/CancelTransactions', '3e7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/CreatePortfolio',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/CreatePortfolio', '560'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/CreateTradeTicket',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/CreateTradeTicket', '2f7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/DeleteCustodianAccounts',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/DeleteCustodianAccounts', 'd3b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/DeletePropertiesFromTransaction',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/DeletePropertiesFromTransaction', 'c44'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/GetA2BData',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/GetA2BData', 'e5f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/GetA2BMovements',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/GetA2BMovements', 'd03'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/GetBucketedCashFlows',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/GetBucketedCashFlows', '1fd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/GetCustodianAccount',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/GetCustodianAccount', '622'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/GetDetails',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/GetDetails', '07b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/GetHoldingContributors',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/GetHoldingContributors', 'a12'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/GetHoldings',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/GetHoldings', 'ce8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/GetHoldingsAdjustment',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/GetHoldingsAdjustment', '33b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/GetHoldingsWithOrders',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/GetHoldingsWithOrders', 'f2b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/GetMultipleHoldingContributors',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/GetMultipleHoldingContributors', '661'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/GetPortfolioCashFlows',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/GetPortfolioCashFlows', '6f8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/GetPortfolioCashLadder',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/GetPortfolioCashLadder', '52e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/GetPortfolioCashStatement',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/GetPortfolioCashStatement', '10f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/GetTransactionHistory',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/GetTransactionHistory', '1e6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/GetTransactions',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/GetTransactions', 'c02'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/GetUpsertablePortfolioCashFlows',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/GetUpsertablePortfolioCashFlows', 'f2a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/ListCustodianAccounts',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/ListCustodianAccounts', '06c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/ListHoldingsAdjustments',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/ListHoldingsAdjustments', 'f20'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/PatchPortfolioDetails',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/PatchPortfolioDetails', '5ba'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/PreviewTransaction',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/PreviewTransaction', '7a2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/ResolveInstrument',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/ResolveInstrument', 'fe8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/SetHoldings',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/SetHoldings', '823'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/UpsertCustodianAccounts',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/UpsertCustodianAccounts', '278'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/UpsertCustodianAccountsProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/UpsertCustodianAccountsProperties', '2f8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/UpsertPortfolioDetails',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/UpsertPortfolioDetails', '8d0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/UpsertTransactionProperties',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/UpsertTransactionProperties', '014'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/transaction-portfolios/UpsertTransactions',
                component: ComponentCreator('/docs/api/lusid/endpoints/transaction-portfolios/UpsertTransactions', '4f5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/translation/TranslateInstrumentDefinitions',
                component: ComponentCreator('/docs/api/lusid/endpoints/translation/TranslateInstrumentDefinitions', '19c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/translation/TranslateTradeTickets',
                component: ComponentCreator('/docs/api/lusid/endpoints/translation/TranslateTradeTickets', 'b80'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/workspace/CreateItem',
                component: ComponentCreator('/docs/api/lusid/endpoints/workspace/CreateItem', '526'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/workspace/CreateWorkspace',
                component: ComponentCreator('/docs/api/lusid/endpoints/workspace/CreateWorkspace', 'c5b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/workspace/DeleteItem',
                component: ComponentCreator('/docs/api/lusid/endpoints/workspace/DeleteItem', '7c8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/workspace/DeleteWorkspace',
                component: ComponentCreator('/docs/api/lusid/endpoints/workspace/DeleteWorkspace', 'd8d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/workspace/GetItem',
                component: ComponentCreator('/docs/api/lusid/endpoints/workspace/GetItem', '200'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/workspace/GetWorkspace',
                component: ComponentCreator('/docs/api/lusid/endpoints/workspace/GetWorkspace', '316'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/workspace/ListItems',
                component: ComponentCreator('/docs/api/lusid/endpoints/workspace/ListItems', 'c17'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/workspace/ListWorkspaces',
                component: ComponentCreator('/docs/api/lusid/endpoints/workspace/ListWorkspaces', '7af'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/workspace/UpdateItem',
                component: ComponentCreator('/docs/api/lusid/endpoints/workspace/UpdateItem', '459'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/endpoints/workspace/UpdateWorkspace',
                component: ComponentCreator('/docs/api/lusid/endpoints/workspace/UpdateWorkspace', '31f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/error-codes',
                component: ComponentCreator('/docs/api/lusid/error-codes', '084'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/intro',
                component: ComponentCreator('/docs/api/lusid/intro', '7f1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/A2BBreakdown',
                component: ComponentCreator('/docs/api/lusid/schemas/A2BBreakdown', '703'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/A2BCategory',
                component: ComponentCreator('/docs/api/lusid/schemas/A2BCategory', 'bf9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/A2BDataRecord',
                component: ComponentCreator('/docs/api/lusid/schemas/A2BDataRecord', '42f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/A2BMovementRecord',
                component: ComponentCreator('/docs/api/lusid/schemas/A2BMovementRecord', '9a4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Abor',
                component: ComponentCreator('/docs/api/lusid/schemas/Abor', '696'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AborConfiguration',
                component: ComponentCreator('/docs/api/lusid/schemas/AborConfiguration', '37f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AborConfigurationProperties',
                component: ComponentCreator('/docs/api/lusid/schemas/AborConfigurationProperties', '82e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AborConfigurationRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/AborConfigurationRequest', '1d0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AborProperties',
                component: ComponentCreator('/docs/api/lusid/schemas/AborProperties', '5b9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AborRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/AborRequest', '6b5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AcceptEstimateValuationPointResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/AcceptEstimateValuationPointResponse', '357'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AccessControlledAction',
                component: ComponentCreator('/docs/api/lusid/schemas/AccessControlledAction', 'f7c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AccessControlledResource',
                component: ComponentCreator('/docs/api/lusid/schemas/AccessControlledResource', 'a92'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AccessMetadataOperation',
                component: ComponentCreator('/docs/api/lusid/schemas/AccessMetadataOperation', '140'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AccessMetadataValue',
                component: ComponentCreator('/docs/api/lusid/schemas/AccessMetadataValue', 'b98'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Account',
                component: ComponentCreator('/docs/api/lusid/schemas/Account', '261'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AccountedTransaction',
                component: ComponentCreator('/docs/api/lusid/schemas/AccountedTransaction', '565'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AccountProperties',
                component: ComponentCreator('/docs/api/lusid/schemas/AccountProperties', 'e5b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AccountsUpsertResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/AccountsUpsertResponse', 'a35'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AccumulationEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/AccumulationEvent', '10c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ActionId',
                component: ComponentCreator('/docs/api/lusid/schemas/ActionId', '4a8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AddBusinessDaysToDateRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/AddBusinessDaysToDateRequest', 'ed2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AddBusinessDaysToDateResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/AddBusinessDaysToDateResponse', '261'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AdditionalPayment',
                component: ComponentCreator('/docs/api/lusid/schemas/AdditionalPayment', '691'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AddressDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/AddressDefinition', '203'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AddressKeyComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/AddressKeyComplianceParameter', '30e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AddressKeyDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/AddressKeyDefinition', 'c6f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AddressKeyFilter',
                component: ComponentCreator('/docs/api/lusid/schemas/AddressKeyFilter', '3b3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AddressKeyList',
                component: ComponentCreator('/docs/api/lusid/schemas/AddressKeyList', 'ef5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AddressKeyListComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/AddressKeyListComplianceParameter', '21d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AddressKeyOptionDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/AddressKeyOptionDefinition', 'b52'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AdjustGlobalCommitmentEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/AdjustGlobalCommitmentEvent', 'b61'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AdjustHolding',
                component: ComponentCreator('/docs/api/lusid/schemas/AdjustHolding', '247'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AdjustHoldingForDateRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/AdjustHoldingForDateRequest', 'f7e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AdjustHoldingRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/AdjustHoldingRequest', '4bb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AggregatedReturn',
                component: ComponentCreator('/docs/api/lusid/schemas/AggregatedReturn', '8ce'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AggregatedReturnsDispersionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/AggregatedReturnsDispersionRequest', '1de'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AggregatedReturnsRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/AggregatedReturnsRequest', 'd4d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AggregatedReturnsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/AggregatedReturnsResponse', '351'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AggregatedTransactionsRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/AggregatedTransactionsRequest', '48e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AggregateSpec',
                component: ComponentCreator('/docs/api/lusid/schemas/AggregateSpec', 'bf0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AggregationContext',
                component: ComponentCreator('/docs/api/lusid/schemas/AggregationContext', '946'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AggregationMeasureFailureDetail',
                component: ComponentCreator('/docs/api/lusid/schemas/AggregationMeasureFailureDetail', '221'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AggregationOptions',
                component: ComponentCreator('/docs/api/lusid/schemas/AggregationOptions', '272'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AggregationQuery',
                component: ComponentCreator('/docs/api/lusid/schemas/AggregationQuery', '69e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Alias',
                component: ComponentCreator('/docs/api/lusid/schemas/Alias', '702'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Allocation',
                component: ComponentCreator('/docs/api/lusid/schemas/Allocation', 'ec2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AllocationRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/AllocationRequest', 'f8e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AllocationServiceRunResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/AllocationServiceRunResponse', '5f6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AllocationSetRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/AllocationSetRequest', '012'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AmortisationEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/AmortisationEvent', 'c9f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AmortisationRule',
                component: ComponentCreator('/docs/api/lusid/schemas/AmortisationRule', 'ec7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AmortisationRuleSet',
                component: ComponentCreator('/docs/api/lusid/schemas/AmortisationRuleSet', 'fd9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Amount',
                component: ComponentCreator('/docs/api/lusid/schemas/Amount', '61c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AnnulQuotesResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/AnnulQuotesResponse', '977'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AnnulSingleStructuredDataResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/AnnulSingleStructuredDataResponse', '15a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AnnulStructuredDataResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/AnnulStructuredDataResponse', '892'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AppendComplexMarketDataRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/AppendComplexMarketDataRequest', '23e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AppendFxForwardCurveByQuoteReference',
                component: ComponentCreator('/docs/api/lusid/schemas/AppendFxForwardCurveByQuoteReference', 'fb8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AppendFxForwardCurveData',
                component: ComponentCreator('/docs/api/lusid/schemas/AppendFxForwardCurveData', '4e5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AppendFxForwardPipsCurveData',
                component: ComponentCreator('/docs/api/lusid/schemas/AppendFxForwardPipsCurveData', '54c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AppendFxForwardTenorCurveData',
                component: ComponentCreator('/docs/api/lusid/schemas/AppendFxForwardTenorCurveData', '44f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AppendFxForwardTenorPipsCurveData',
                component: ComponentCreator('/docs/api/lusid/schemas/AppendFxForwardTenorPipsCurveData', 'bae'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AppendMarketData',
                component: ComponentCreator('/docs/api/lusid/schemas/AppendMarketData', '035'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ApplicableInstrumentEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/ApplicableInstrumentEvent', '575'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/AssetLeg',
                component: ComponentCreator('/docs/api/lusid/schemas/AssetLeg', '36c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Barrier',
                component: ComponentCreator('/docs/api/lusid/schemas/Barrier', '5be'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Basket',
                component: ComponentCreator('/docs/api/lusid/schemas/Basket', 'e80'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BasketIdentifier',
                component: ComponentCreator('/docs/api/lusid/schemas/BasketIdentifier', 'f68'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BatchAdjustHoldingsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/BatchAdjustHoldingsResponse', '19a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BatchUpdateUserReviewForComparisonResultRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/BatchUpdateUserReviewForComparisonResultRequest', '82e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BatchUpdateUserReviewForComparisonResultResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/BatchUpdateUserReviewForComparisonResultResponse', '233'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BatchUpsertDatesForCalendarResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/BatchUpsertDatesForCalendarResponse', '291'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BatchUpsertInstrumentPropertiesResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/BatchUpsertInstrumentPropertiesResponse', 'f07'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BatchUpsertPortfolioAccessMetadataRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/BatchUpsertPortfolioAccessMetadataRequest', '812'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BatchUpsertPortfolioAccessMetadataResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/BatchUpsertPortfolioAccessMetadataResponse', 'd5c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BatchUpsertPortfolioAccessMetadataResponseItem',
                component: ComponentCreator('/docs/api/lusid/schemas/BatchUpsertPortfolioAccessMetadataResponseItem', 'ece'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BatchUpsertPortfolioTransactionsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/BatchUpsertPortfolioTransactionsResponse', '4d5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BatchUpsertPropertyDefinitionPropertiesResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/BatchUpsertPropertyDefinitionPropertiesResponse', '0fb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BespokeCashFlowsLeg',
                component: ComponentCreator('/docs/api/lusid/schemas/BespokeCashFlowsLeg', 'cc1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Block',
                component: ComponentCreator('/docs/api/lusid/schemas/Block', '50f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BlockAndOrderIdRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/BlockAndOrderIdRequest', 'c57'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BlockAndOrders',
                component: ComponentCreator('/docs/api/lusid/schemas/BlockAndOrders', 'ec7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BlockAndOrdersCreateRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/BlockAndOrdersCreateRequest', '655'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BlockAndOrdersRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/BlockAndOrdersRequest', 'd70'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BlockedOrderRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/BlockedOrderRequest', '96a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BlockRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/BlockRequest', 'a61'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BlockSetRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/BlockSetRequest', 'a28'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Bond',
                component: ComponentCreator('/docs/api/lusid/schemas/Bond', 'e75'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BondConversionEntry',
                component: ComponentCreator('/docs/api/lusid/schemas/BondConversionEntry', '514'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BondConversionSchedule',
                component: ComponentCreator('/docs/api/lusid/schemas/BondConversionSchedule', '463'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BondCouponEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/BondCouponEvent', '56e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BondDefaultEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/BondDefaultEvent', '6ff'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BondPrincipalEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/BondPrincipalEvent', '6ce'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BonusIssueEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/BonusIssueEvent', '9e1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BookTransactionsRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/BookTransactionsRequest', '61c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BookTransactionsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/BookTransactionsResponse', '911'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BoolComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/BoolComplianceParameter', 'a91'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BoolListComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/BoolListComplianceParameter', '2c5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BranchStep',
                component: ComponentCreator('/docs/api/lusid/schemas/BranchStep', '74b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BranchStepRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/BranchStepRequest', '879'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BreakCodeSource',
                component: ComponentCreator('/docs/api/lusid/schemas/BreakCodeSource', '5d1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BucketedCashFlowRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/BucketedCashFlowRequest', '4de'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BucketedCashFlowResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/BucketedCashFlowResponse', 'e32'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/BucketingSchedule',
                component: ComponentCreator('/docs/api/lusid/schemas/BucketingSchedule', '075'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CalculationInfo',
                component: ComponentCreator('/docs/api/lusid/schemas/CalculationInfo', '772'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Calendar',
                component: ComponentCreator('/docs/api/lusid/schemas/Calendar', 'c96'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CalendarDate',
                component: ComponentCreator('/docs/api/lusid/schemas/CalendarDate', 'd9a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CalendarDependency',
                component: ComponentCreator('/docs/api/lusid/schemas/CalendarDependency', '2d7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CallOnIntermediateSecuritiesEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/CallOnIntermediateSecuritiesEvent', '60e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CancelledOrderResult',
                component: ComponentCreator('/docs/api/lusid/schemas/CancelledOrderResult', 'b2c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CancelledPlacementResult',
                component: ComponentCreator('/docs/api/lusid/schemas/CancelledPlacementResult', '6df'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CancelOrderAndMoveRemainingResult',
                component: ComponentCreator('/docs/api/lusid/schemas/CancelOrderAndMoveRemainingResult', '8ee'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CancelOrdersAndMoveRemainingRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CancelOrdersAndMoveRemainingRequest', 'e7a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CancelOrdersAndMoveRemainingResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/CancelOrdersAndMoveRemainingResponse', 'd6a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CancelOrdersResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/CancelOrdersResponse', 'e90'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CancelPlacementsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/CancelPlacementsResponse', 'feb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CancelSingleHoldingAdjustmentRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CancelSingleHoldingAdjustmentRequest', '8f9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CapFloor',
                component: ComponentCreator('/docs/api/lusid/schemas/CapFloor', '2ce'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CapitalDistributionEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/CapitalDistributionEvent', '5eb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Cash',
                component: ComponentCreator('/docs/api/lusid/schemas/Cash', 'c72'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CashAndSecurityOfferElection',
                component: ComponentCreator('/docs/api/lusid/schemas/CashAndSecurityOfferElection', 'c42'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CashDependency',
                component: ComponentCreator('/docs/api/lusid/schemas/CashDependency', '353'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CashDividendEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/CashDividendEvent', '0e4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CashElection',
                component: ComponentCreator('/docs/api/lusid/schemas/CashElection', '879'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CashFlowEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/CashFlowEvent', 'b82'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CashFlowLineage',
                component: ComponentCreator('/docs/api/lusid/schemas/CashFlowLineage', 'fd3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CashFlowValue',
                component: ComponentCreator('/docs/api/lusid/schemas/CashFlowValue', '7ab'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CashFlowValueSet',
                component: ComponentCreator('/docs/api/lusid/schemas/CashFlowValueSet', '7cc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CashLadderRecord',
                component: ComponentCreator('/docs/api/lusid/schemas/CashLadderRecord', 'fa0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CashOfferElection',
                component: ComponentCreator('/docs/api/lusid/schemas/CashOfferElection', '371'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CashPerpetual',
                component: ComponentCreator('/docs/api/lusid/schemas/CashPerpetual', 'b7b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CashSettled',
                component: ComponentCreator('/docs/api/lusid/schemas/CashSettled', 'fac'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CdsCreditEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/CdsCreditEvent', '6eb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CdsFlowConventions',
                component: ComponentCreator('/docs/api/lusid/schemas/CdsFlowConventions', '3f4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CdsIndex',
                component: ComponentCreator('/docs/api/lusid/schemas/CdsIndex', 'de6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CdsProtectionDetailSpecification',
                component: ComponentCreator('/docs/api/lusid/schemas/CdsProtectionDetailSpecification', '60f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CdxCreditEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/CdxCreditEvent', '771'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Change',
                component: ComponentCreator('/docs/api/lusid/schemas/Change', 'f17'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ChangeHistory',
                component: ComponentCreator('/docs/api/lusid/schemas/ChangeHistory', '131'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ChangeInterval',
                component: ComponentCreator('/docs/api/lusid/schemas/ChangeInterval', '507'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ChangeIntervalWithOrderManagementDetail',
                component: ComponentCreator('/docs/api/lusid/schemas/ChangeIntervalWithOrderManagementDetail', 'b9e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ChangeItem',
                component: ComponentCreator('/docs/api/lusid/schemas/ChangeItem', '805'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ChartOfAccounts',
                component: ComponentCreator('/docs/api/lusid/schemas/ChartOfAccounts', '822'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ChartOfAccountsProperties',
                component: ComponentCreator('/docs/api/lusid/schemas/ChartOfAccountsProperties', 'd60'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ChartOfAccountsRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/ChartOfAccountsRequest', '4a4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CheckStep',
                component: ComponentCreator('/docs/api/lusid/schemas/CheckStep', 'a47'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CheckStepRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CheckStepRequest', 'db7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CleardownModuleDetails',
                component: ComponentCreator('/docs/api/lusid/schemas/CleardownModuleDetails', 'c44'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CleardownModuleRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CleardownModuleRequest', '330'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CleardownModuleResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/CleardownModuleResponse', '3b3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CleardownModuleRule',
                component: ComponentCreator('/docs/api/lusid/schemas/CleardownModuleRule', 'be3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CleardownModuleRulesUpdatedResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/CleardownModuleRulesUpdatedResponse', '445'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Client',
                component: ComponentCreator('/docs/api/lusid/schemas/Client', 'f0f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ClosedPeriod',
                component: ComponentCreator('/docs/api/lusid/schemas/ClosedPeriod', '6ef'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CloseEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/CloseEvent', 'a47'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ClosePeriodDiaryEntryRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/ClosePeriodDiaryEntryRequest', 'ddd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComparisonAttributeValuePair',
                component: ComponentCreator('/docs/api/lusid/schemas/ComparisonAttributeValuePair', 'ec4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CompletePortfolio',
                component: ComponentCreator('/docs/api/lusid/schemas/CompletePortfolio', '22f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CompleteRelation',
                component: ComponentCreator('/docs/api/lusid/schemas/CompleteRelation', '2de'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CompleteRelationship',
                component: ComponentCreator('/docs/api/lusid/schemas/CompleteRelationship', '9f9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplexBond',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplexBond', 'cec'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplexMarketData',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplexMarketData', '984'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplexMarketDataId',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplexMarketDataId', '984'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceBreachedOrderInfo',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceBreachedOrderInfo', 'a97'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceParameter', 'bfe'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceRule',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceRule', '9b0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceRuleBreakdown',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceRuleBreakdown', 'b92'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceRuleBreakdownRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceRuleBreakdownRequest', 'b0b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceRuleResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceRuleResponse', '693'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceRuleResult',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceRuleResult', '4c7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceRuleResultDetail',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceRuleResultDetail', 'e89'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceRuleResultPortfolioDetail',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceRuleResultPortfolioDetail', '996'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceRuleResultV2',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceRuleResultV2', 'b32'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceRuleTemplate',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceRuleTemplate', '560'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceRuleUpsertRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceRuleUpsertRequest', '210'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceRuleUpsertResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceRuleUpsertResponse', 'cfd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceRunConfiguration',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceRunConfiguration', '39e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceRunInfo',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceRunInfo', 'bd5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceRunInfoV2',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceRunInfoV2', 'd21'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceStep',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceStep', '00c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceStepRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceStepRequest', '441'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceSummaryRuleResult',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceSummaryRuleResult', '71e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceSummaryRuleResultRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceSummaryRuleResultRequest', 'ae2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceTemplate',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceTemplate', 'b8f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceTemplateParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceTemplateParameter', 'a86'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceTemplateVariation',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceTemplateVariation', 'ca7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceTemplateVariationDto',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceTemplateVariationDto', '742'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComplianceTemplateVariationRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/ComplianceTemplateVariationRequest', 'c51'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComponentFilter',
                component: ComponentCreator('/docs/api/lusid/schemas/ComponentFilter', '253'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ComponentTransaction',
                component: ComponentCreator('/docs/api/lusid/schemas/ComponentTransaction', '11a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CompositeBreakdown',
                component: ComponentCreator('/docs/api/lusid/schemas/CompositeBreakdown', 'c50'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CompositeBreakdownRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CompositeBreakdownRequest', '0d6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CompositeBreakdownResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/CompositeBreakdownResponse', '98e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CompositeDispersion',
                component: ComponentCreator('/docs/api/lusid/schemas/CompositeDispersion', '2a3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CompositeDispersionResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/CompositeDispersionResponse', '720'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Compounding',
                component: ComponentCreator('/docs/api/lusid/schemas/Compounding', '355'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ConfigurationRecipe',
                component: ComponentCreator('/docs/api/lusid/schemas/ConfigurationRecipe', 'f09'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ConstantVolatilitySurface',
                component: ComponentCreator('/docs/api/lusid/schemas/ConstantVolatilitySurface', '282'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ConstituentsAdjustmentHeader',
                component: ComponentCreator('/docs/api/lusid/schemas/ConstituentsAdjustmentHeader', '1dc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ContractDetails',
                component: ComponentCreator('/docs/api/lusid/schemas/ContractDetails', '355'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ContractForDifference',
                component: ComponentCreator('/docs/api/lusid/schemas/ContractForDifference', '363'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ContractInitialisationEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/ContractInitialisationEvent', 'dba'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ContributionToNonPassingRuleDetail',
                component: ComponentCreator('/docs/api/lusid/schemas/ContributionToNonPassingRuleDetail', 'eed'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CorporateAction',
                component: ComponentCreator('/docs/api/lusid/schemas/CorporateAction', '243'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CorporateActionSource',
                component: ComponentCreator('/docs/api/lusid/schemas/CorporateActionSource', '5f3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CorporateActionTransition',
                component: ComponentCreator('/docs/api/lusid/schemas/CorporateActionTransition', 'edc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CorporateActionTransitionComponent',
                component: ComponentCreator('/docs/api/lusid/schemas/CorporateActionTransitionComponent', 'cfb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CorporateActionTransitionComponentRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CorporateActionTransitionComponentRequest', 'b0e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CorporateActionTransitionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CorporateActionTransitionRequest', '113'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CounterpartyAgreement',
                component: ComponentCreator('/docs/api/lusid/schemas/CounterpartyAgreement', '201'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CounterpartyRiskInformation',
                component: ComponentCreator('/docs/api/lusid/schemas/CounterpartyRiskInformation', 'fef'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CounterpartySignatory',
                component: ComponentCreator('/docs/api/lusid/schemas/CounterpartySignatory', '941'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateAddressKeyDefinitionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateAddressKeyDefinitionRequest', '62e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateAmortisationRuleSetRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateAmortisationRuleSetRequest', '2fc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateCalendarRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateCalendarRequest', '30f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateClosedPeriodRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateClosedPeriodRequest', 'ee2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateComplianceTemplateRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateComplianceTemplateRequest', '0eb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateCorporateActionSourceRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateCorporateActionSourceRequest', '551'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateCustomDataModelRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateCustomDataModelRequest', '8ef'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateCustomEntityTypeRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateCustomEntityTypeRequest', '6b2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateCutLabelDefinitionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateCutLabelDefinitionRequest', 'bd0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateDataMapRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateDataMapRequest', 'c5d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateDataTypeRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateDataTypeRequest', 'e3c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateDateRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateDateRequest', 'b93'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateDerivedPropertyDefinitionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateDerivedPropertyDefinitionRequest', '2ab'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateDerivedTransactionPortfolioRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateDerivedTransactionPortfolioRequest', '669'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateGroupReconciliationComparisonRulesetRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateGroupReconciliationComparisonRulesetRequest', 'baf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateGroupReconciliationDefinitionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateGroupReconciliationDefinitionRequest', '065'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateIdentifierDefinitionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateIdentifierDefinitionRequest', '85c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreatePortfolioDetails',
                component: ComponentCreator('/docs/api/lusid/schemas/CreatePortfolioDetails', 'e47'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreatePortfolioGroupRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreatePortfolioGroupRequest', '6a7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreatePropertyDefinitionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreatePropertyDefinitionRequest', '2ad'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateRecipeRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateRecipeRequest', '1fb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateReconciliationRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateReconciliationRequest', '5a4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateReferencePortfolioRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateReferencePortfolioRequest', 'f9f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateRelationDefinitionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateRelationDefinitionRequest', 'a76'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateRelationRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateRelationRequest', '267'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateRelationshipDefinitionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateRelationshipDefinitionRequest', '295'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateRelationshipRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateRelationshipRequest', '299'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateSequenceRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateSequenceRequest', 'd12'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateSimplePositionPortfolioRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateSimplePositionPortfolioRequest', 'b39'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateStagingRuleSetRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateStagingRuleSetRequest', '379'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateTaxRuleSetRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateTaxRuleSetRequest', '28b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateTimelineRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateTimelineRequest', '941'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateTradeTicketsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateTradeTicketsResponse', 'dc9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateTransactionPortfolioRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateTransactionPortfolioRequest', 'aa2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreateUnitDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/CreateUnitDefinition', 'd0d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreditDefaultSwap',
                component: ComponentCreator('/docs/api/lusid/schemas/CreditDefaultSwap', 'eb4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreditPremiumCashFlowEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/CreditPremiumCashFlowEvent', '341'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreditRating',
                component: ComponentCreator('/docs/api/lusid/schemas/CreditRating', 'f02'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreditSpreadCurveData',
                component: ComponentCreator('/docs/api/lusid/schemas/CreditSpreadCurveData', '6d6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CreditSupportAnnex',
                component: ComponentCreator('/docs/api/lusid/schemas/CreditSupportAnnex', '5bd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CurrencyAndAmount',
                component: ComponentCreator('/docs/api/lusid/schemas/CurrencyAndAmount', '734'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CurveOptions',
                component: ComponentCreator('/docs/api/lusid/schemas/CurveOptions', '481'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CustodianAccount',
                component: ComponentCreator('/docs/api/lusid/schemas/CustodianAccount', '6b5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CustodianAccountProperties',
                component: ComponentCreator('/docs/api/lusid/schemas/CustodianAccountProperties', '451'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CustodianAccountRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CustodianAccountRequest', 'b8a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CustodianAccountsUpsertResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/CustodianAccountsUpsertResponse', 'e24'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CustomDataModel',
                component: ComponentCreator('/docs/api/lusid/schemas/CustomDataModel', 'b34'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CustomDataModelCriteria',
                component: ComponentCreator('/docs/api/lusid/schemas/CustomDataModelCriteria', 'b66'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CustomDataModelIdentifierTypeSpecification',
                component: ComponentCreator('/docs/api/lusid/schemas/CustomDataModelIdentifierTypeSpecification', 'bed'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CustomDataModelIdentifierTypeSpecificationWithDisplayName',
                component: ComponentCreator('/docs/api/lusid/schemas/CustomDataModelIdentifierTypeSpecificationWithDisplayName', 'a44'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CustomDataModelPropertySpecification',
                component: ComponentCreator('/docs/api/lusid/schemas/CustomDataModelPropertySpecification', '17f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CustomDataModelPropertySpecificationWithDisplayName',
                component: ComponentCreator('/docs/api/lusid/schemas/CustomDataModelPropertySpecificationWithDisplayName', '410'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CustomEntityDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/CustomEntityDefinition', 'f79'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CustomEntityDefinitionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CustomEntityDefinitionRequest', '549'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CustomEntityEntity',
                component: ComponentCreator('/docs/api/lusid/schemas/CustomEntityEntity', '803'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CustomEntityField',
                component: ComponentCreator('/docs/api/lusid/schemas/CustomEntityField', '289'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CustomEntityFieldDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/CustomEntityFieldDefinition', '95e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CustomEntityId',
                component: ComponentCreator('/docs/api/lusid/schemas/CustomEntityId', '59c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CustomEntityRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/CustomEntityRequest', '136'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CustomEntityResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/CustomEntityResponse', '67e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CustomEntityType',
                component: ComponentCreator('/docs/api/lusid/schemas/CustomEntityType', '6ce'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CutLabelDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/CutLabelDefinition', 'a7e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/CutLocalTime',
                component: ComponentCreator('/docs/api/lusid/schemas/CutLocalTime', 'b16'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DataDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/DataDefinition', 'dbc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DataMapKey',
                component: ComponentCreator('/docs/api/lusid/schemas/DataMapKey', 'b22'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DataMapping',
                component: ComponentCreator('/docs/api/lusid/schemas/DataMapping', 'e14'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DataModelMembership',
                component: ComponentCreator('/docs/api/lusid/schemas/DataModelMembership', '3ff'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DataModelSummary',
                component: ComponentCreator('/docs/api/lusid/schemas/DataModelSummary', 'a7a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DataScope',
                component: ComponentCreator('/docs/api/lusid/schemas/DataScope', 'c07'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DataType',
                component: ComponentCreator('/docs/api/lusid/schemas/DataType', '61d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DataTypeEntity',
                component: ComponentCreator('/docs/api/lusid/schemas/DataTypeEntity', '8a3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DataTypeSummary',
                component: ComponentCreator('/docs/api/lusid/schemas/DataTypeSummary', 'a5c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DateAttributes',
                component: ComponentCreator('/docs/api/lusid/schemas/DateAttributes', '0a1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DateOrDiaryEntry',
                component: ComponentCreator('/docs/api/lusid/schemas/DateOrDiaryEntry', '147'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DateRange',
                component: ComponentCreator('/docs/api/lusid/schemas/DateRange', 'be9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DateTimeComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/DateTimeComplianceParameter', '08c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DateTimeListComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/DateTimeListComplianceParameter', 'aa4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DayMonth',
                component: ComponentCreator('/docs/api/lusid/schemas/DayMonth', '76e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DayOfWeek',
                component: ComponentCreator('/docs/api/lusid/schemas/DayOfWeek', '603'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DecimalComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/DecimalComplianceParameter', 'a74'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DecimalList',
                component: ComponentCreator('/docs/api/lusid/schemas/DecimalList', '6fb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DecimalListComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/DecimalListComplianceParameter', '4f4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DecoratedComplianceRunSummary',
                component: ComponentCreator('/docs/api/lusid/schemas/DecoratedComplianceRunSummary', '3a4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DeleteAccountsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/DeleteAccountsResponse', '58c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DeleteCustodianAccountsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/DeleteCustodianAccountsResponse', 'd49'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DeletedEntityResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/DeletedEntityResponse', '9bb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DeleteInstrumentPropertiesResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/DeleteInstrumentPropertiesResponse', 'f12'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DeleteInstrumentResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/DeleteInstrumentResponse', '72f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DeleteInstrumentsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/DeleteInstrumentsResponse', '1a3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DeleteRelationRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/DeleteRelationRequest', 'c76'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DeleteRelationshipRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/DeleteRelationshipRequest', '0e4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DependencySourceFilter',
                component: ComponentCreator('/docs/api/lusid/schemas/DependencySourceFilter', 'b26'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DepositCloseEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/DepositCloseEvent', 'beb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DepositInterestPaymentEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/DepositInterestPaymentEvent', '56a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DescribedAddressKey',
                component: ComponentCreator('/docs/api/lusid/schemas/DescribedAddressKey', '01f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Dialect',
                component: ComponentCreator('/docs/api/lusid/schemas/Dialect', 'aa1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DialectId',
                component: ComponentCreator('/docs/api/lusid/schemas/DialectId', '24e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DialectSchema',
                component: ComponentCreator('/docs/api/lusid/schemas/DialectSchema', '756'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DiaryEntry',
                component: ComponentCreator('/docs/api/lusid/schemas/DiaryEntry', '103'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DiaryEntryRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/DiaryEntryRequest', 'd44'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DiscountFactorCurveData',
                component: ComponentCreator('/docs/api/lusid/schemas/DiscountFactorCurveData', 'c87'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DiscountingDependency',
                component: ComponentCreator('/docs/api/lusid/schemas/DiscountingDependency', '553'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DividendOptionEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/DividendOptionEvent', '015'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DividendReinvestmentEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/DividendReinvestmentEvent', 'b5a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/DrawdownEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/DrawdownEvent', '87a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/EarlyRedemptionElection',
                component: ComponentCreator('/docs/api/lusid/schemas/EarlyRedemptionElection', '6fb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/EarlyRedemptionEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/EarlyRedemptionEvent', '96a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/EconomicDependency',
                component: ComponentCreator('/docs/api/lusid/schemas/EconomicDependency', '011'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/EconomicDependencyWithComplexMarketData',
                component: ComponentCreator('/docs/api/lusid/schemas/EconomicDependencyWithComplexMarketData', 'ffd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/EconomicDependencyWithQuote',
                component: ComponentCreator('/docs/api/lusid/schemas/EconomicDependencyWithQuote', '9f8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/EffectiveRange',
                component: ComponentCreator('/docs/api/lusid/schemas/EffectiveRange', '4a3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ElectionSpecification',
                component: ComponentCreator('/docs/api/lusid/schemas/ElectionSpecification', '811'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/EligibilityCalculation',
                component: ComponentCreator('/docs/api/lusid/schemas/EligibilityCalculation', '174'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/EmptyModelOptions',
                component: ComponentCreator('/docs/api/lusid/schemas/EmptyModelOptions', 'aad'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/EntityIdentifier',
                component: ComponentCreator('/docs/api/lusid/schemas/EntityIdentifier', '496'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Equity',
                component: ComponentCreator('/docs/api/lusid/schemas/Equity', '352'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/EquityCurveByPricesData',
                component: ComponentCreator('/docs/api/lusid/schemas/EquityCurveByPricesData', 'e5f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/EquityCurveDependency',
                component: ComponentCreator('/docs/api/lusid/schemas/EquityCurveDependency', 'bed'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/EquityModelOptions',
                component: ComponentCreator('/docs/api/lusid/schemas/EquityModelOptions', 'd56'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/EquityOption',
                component: ComponentCreator('/docs/api/lusid/schemas/EquityOption', 'a48'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/EquitySwap',
                component: ComponentCreator('/docs/api/lusid/schemas/EquitySwap', '721'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/EquityVolDependency',
                component: ComponentCreator('/docs/api/lusid/schemas/EquityVolDependency', 'dda'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/EquityVolSurfaceData',
                component: ComponentCreator('/docs/api/lusid/schemas/EquityVolSurfaceData', 'e8a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ErrorDetail',
                component: ComponentCreator('/docs/api/lusid/schemas/ErrorDetail', '6e8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/EventDateRange',
                component: ComponentCreator('/docs/api/lusid/schemas/EventDateRange', 'bc0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ExchangeTradedOption',
                component: ComponentCreator('/docs/api/lusid/schemas/ExchangeTradedOption', 'd90'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ExchangeTradedOptionContractDetails',
                component: ComponentCreator('/docs/api/lusid/schemas/ExchangeTradedOptionContractDetails', '998'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ExDividendConfiguration',
                component: ComponentCreator('/docs/api/lusid/schemas/ExDividendConfiguration', '4b2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Execution',
                component: ComponentCreator('/docs/api/lusid/schemas/Execution', '250'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ExecutionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/ExecutionRequest', 'ad9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ExecutionSetRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/ExecutionSetRequest', '154'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Exercise',
                component: ComponentCreator('/docs/api/lusid/schemas/Exercise', '7bf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ExerciseEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/ExerciseEvent', '09e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ExoticInstrument',
                component: ComponentCreator('/docs/api/lusid/schemas/ExoticInstrument', 'a86'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ExpandedGroup',
                component: ComponentCreator('/docs/api/lusid/schemas/ExpandedGroup', '783'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ExpiryEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/ExpiryEvent', 'ced'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ExternalFeeComponentFilter',
                component: ComponentCreator('/docs/api/lusid/schemas/ExternalFeeComponentFilter', 'c0b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Fee',
                component: ComponentCreator('/docs/api/lusid/schemas/Fee', '432'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FeeAccrual',
                component: ComponentCreator('/docs/api/lusid/schemas/FeeAccrual', 'd6d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FeeProperties',
                component: ComponentCreator('/docs/api/lusid/schemas/FeeProperties', '2d4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FeeRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/FeeRequest', 'c84'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FeeRule',
                component: ComponentCreator('/docs/api/lusid/schemas/FeeRule', '495'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FeeRuleUpsertRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/FeeRuleUpsertRequest', 'f78'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FeeRuleUpsertResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/FeeRuleUpsertResponse', '5e9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FeeTransactionTemplateSpecification',
                component: ComponentCreator('/docs/api/lusid/schemas/FeeTransactionTemplateSpecification', 'b26'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FeeType',
                component: ComponentCreator('/docs/api/lusid/schemas/FeeType', 'b32'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FeeTypeRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/FeeTypeRequest', '8a6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FieldDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/FieldDefinition', 'b8e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FieldSchema',
                component: ComponentCreator('/docs/api/lusid/schemas/FieldSchema', 'ce8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FieldValue',
                component: ComponentCreator('/docs/api/lusid/schemas/FieldValue', '52a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FileResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/FileResponse', 'c7a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FilterPredicateComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/FilterPredicateComplianceParameter', '960'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FilterStep',
                component: ComponentCreator('/docs/api/lusid/schemas/FilterStep', 'da5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FilterStepRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/FilterStepRequest', '0e9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FixedLeg',
                component: ComponentCreator('/docs/api/lusid/schemas/FixedLeg', '68f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FixedSchedule',
                component: ComponentCreator('/docs/api/lusid/schemas/FixedSchedule', '466'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FlexibleDeposit',
                component: ComponentCreator('/docs/api/lusid/schemas/FlexibleDeposit', '799'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FlexibleLoan',
                component: ComponentCreator('/docs/api/lusid/schemas/FlexibleLoan', '608'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FloatingLeg',
                component: ComponentCreator('/docs/api/lusid/schemas/FloatingLeg', 'a90'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FloatSchedule',
                component: ComponentCreator('/docs/api/lusid/schemas/FloatSchedule', '6e2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FlowConventionName',
                component: ComponentCreator('/docs/api/lusid/schemas/FlowConventionName', '2e2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FlowConventions',
                component: ComponentCreator('/docs/api/lusid/schemas/FlowConventions', '830'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ForwardRateAgreement',
                component: ComponentCreator('/docs/api/lusid/schemas/ForwardRateAgreement', '8da'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FromRecipe',
                component: ComponentCreator('/docs/api/lusid/schemas/FromRecipe', 'dda'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Fund',
                component: ComponentCreator('/docs/api/lusid/schemas/Fund', '76e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FundAmount',
                component: ComponentCreator('/docs/api/lusid/schemas/FundAmount', '68b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FundConfiguration',
                component: ComponentCreator('/docs/api/lusid/schemas/FundConfiguration', 'fac'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FundConfigurationProperties',
                component: ComponentCreator('/docs/api/lusid/schemas/FundConfigurationProperties', 'dca'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FundConfigurationRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/FundConfigurationRequest', 'bf9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FundDetails',
                component: ComponentCreator('/docs/api/lusid/schemas/FundDetails', 'b3d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FundIdList',
                component: ComponentCreator('/docs/api/lusid/schemas/FundIdList', 'b17'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FundingLeg',
                component: ComponentCreator('/docs/api/lusid/schemas/FundingLeg', '5e8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FundingLegModelOptions',
                component: ComponentCreator('/docs/api/lusid/schemas/FundingLegModelOptions', 'a3f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FundJournalEntryLine',
                component: ComponentCreator('/docs/api/lusid/schemas/FundJournalEntryLine', 'ec9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FundPnlBreakdown',
                component: ComponentCreator('/docs/api/lusid/schemas/FundPnlBreakdown', 'd69'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FundPreviousNAV',
                component: ComponentCreator('/docs/api/lusid/schemas/FundPreviousNAV', '0d1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FundProperties',
                component: ComponentCreator('/docs/api/lusid/schemas/FundProperties', '8b9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FundRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/FundRequest', '242'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FundShareClass',
                component: ComponentCreator('/docs/api/lusid/schemas/FundShareClass', 'c8a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FundValuationPointData',
                component: ComponentCreator('/docs/api/lusid/schemas/FundValuationPointData', 'e7c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Future',
                component: ComponentCreator('/docs/api/lusid/schemas/Future', 'ebf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FutureExpiryEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/FutureExpiryEvent', '838'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FutureMarkToMarketEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/FutureMarkToMarketEvent', '4bc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FuturesContractDetails',
                component: ComponentCreator('/docs/api/lusid/schemas/FuturesContractDetails', '863'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FxConventions',
                component: ComponentCreator('/docs/api/lusid/schemas/FxConventions', 'c2e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FxDependency',
                component: ComponentCreator('/docs/api/lusid/schemas/FxDependency', '63c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FxForward',
                component: ComponentCreator('/docs/api/lusid/schemas/FxForward', '6d4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FxForwardCurveByQuoteReference',
                component: ComponentCreator('/docs/api/lusid/schemas/FxForwardCurveByQuoteReference', 'caa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FxForwardCurveData',
                component: ComponentCreator('/docs/api/lusid/schemas/FxForwardCurveData', '60a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FxForwardModelOptions',
                component: ComponentCreator('/docs/api/lusid/schemas/FxForwardModelOptions', '238'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FxForwardPipsCurveData',
                component: ComponentCreator('/docs/api/lusid/schemas/FxForwardPipsCurveData', '62b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FxForwardsDependency',
                component: ComponentCreator('/docs/api/lusid/schemas/FxForwardsDependency', '52a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FxForwardSettlementEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/FxForwardSettlementEvent', 'd8a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FxForwardTenorCurveData',
                component: ComponentCreator('/docs/api/lusid/schemas/FxForwardTenorCurveData', '448'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FxForwardTenorPipsCurveData',
                component: ComponentCreator('/docs/api/lusid/schemas/FxForwardTenorPipsCurveData', '02a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FxLinkedNotionalSchedule',
                component: ComponentCreator('/docs/api/lusid/schemas/FxLinkedNotionalSchedule', '30e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FxOption',
                component: ComponentCreator('/docs/api/lusid/schemas/FxOption', '967'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FxRateSchedule',
                component: ComponentCreator('/docs/api/lusid/schemas/FxRateSchedule', 'f32'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FxSwap',
                component: ComponentCreator('/docs/api/lusid/schemas/FxSwap', '80d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FxTenorConvention',
                component: ComponentCreator('/docs/api/lusid/schemas/FxTenorConvention', '207'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FxVolDependency',
                component: ComponentCreator('/docs/api/lusid/schemas/FxVolDependency', '5ec'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/FxVolSurfaceData',
                component: ComponentCreator('/docs/api/lusid/schemas/FxVolSurfaceData', '701'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GeneralLedgerProfileMapping',
                component: ComponentCreator('/docs/api/lusid/schemas/GeneralLedgerProfileMapping', '946'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GeneralLedgerProfileRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/GeneralLedgerProfileRequest', '152'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GeneralLedgerProfileResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/GeneralLedgerProfileResponse', '1a5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GeneratedEventDiagnostics',
                component: ComponentCreator('/docs/api/lusid/schemas/GeneratedEventDiagnostics', '00d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GetCdsFlowConventionsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/GetCdsFlowConventionsResponse', '591'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GetComplexMarketDataResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/GetComplexMarketDataResponse', 'a96'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GetCounterpartyAgreementResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/GetCounterpartyAgreementResponse', 'bb6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GetCreditSupportAnnexResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/GetCreditSupportAnnexResponse', '7b6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GetDataMapResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/GetDataMapResponse', 'f61'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GetFlowConventionsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/GetFlowConventionsResponse', '047'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GetIndexConventionResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/GetIndexConventionResponse', 'f71'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GetInstrumentsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/GetInstrumentsResponse', '928'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GetQuotesResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/GetQuotesResponse', '5b6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GetRecipeComposerResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/GetRecipeComposerResponse', '5d8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GetRecipeResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/GetRecipeResponse', '502'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GetReferencePortfolioConstituentsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/GetReferencePortfolioConstituentsResponse', '55f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GetStructuredResultDataResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/GetStructuredResultDataResponse', '104'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GetVirtualDocumentResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/GetVirtualDocumentResponse', 'c44'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupBySelectorComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupBySelectorComplianceParameter', 'e41'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupByStep',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupByStep', 'cf0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupByStepRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupByStepRequest', 'd7f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupCalculationComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupCalculationComplianceParameter', '8b3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupedResultOfAddressKey',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupedResultOfAddressKey', '38c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupFilterPredicateComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupFilterPredicateComplianceParameter', '012'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupFilterStep',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupFilterStep', '098'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupFilterStepRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupFilterStepRequest', '5fe'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupOfMarketDataKeyRules',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupOfMarketDataKeyRules', 'fa4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationAggregateAttributeRule',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationAggregateAttributeRule', 'c18'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationAggregateAttributeValues',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationAggregateAttributeValues', '94a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationAggregateComparisonRuleOperand',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationAggregateComparisonRuleOperand', 'aff'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationComparisonResult',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationComparisonResult', '968'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationComparisonRuleset',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationComparisonRuleset', 'a8f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationComparisonRuleStringValueMap',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationComparisonRuleStringValueMap', '16d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationComparisonRuleTolerance',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationComparisonRuleTolerance', '5d6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationCoreAttributeRule',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationCoreAttributeRule', '7a6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationCoreAttributeValues',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationCoreAttributeValues', '1f5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationCoreComparisonRuleOperand',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationCoreComparisonRuleOperand', '025'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationDatePair',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationDatePair', '274'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationDates',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationDates', '7d4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationDefinition', '02e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationDefinitionComparisonRulesetIds',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationDefinitionComparisonRulesetIds', '18a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationDefinitionCurrencies',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationDefinitionCurrencies', '530'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationDefinitionPortfolioEntityIds',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationDefinitionPortfolioEntityIds', '205'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationDefinitionRecipeIds',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationDefinitionRecipeIds', 'b4a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationInstanceId',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationInstanceId', '0c3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationResultStatuses',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationResultStatuses', '0f6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationResultTypes',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationResultTypes', '336'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationReviewStatuses',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationReviewStatuses', '859'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationRunDetails',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationRunDetails', 'df3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationRunRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationRunRequest', '404'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationRunResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationRunResponse', '005'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationSummary',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationSummary', '7b7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationUserReview',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationUserReview', 'e4c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationUserReviewAdd',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationUserReviewAdd', 'de2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationUserReviewBreakCode',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationUserReviewBreakCode', '32f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationUserReviewComment',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationUserReviewComment', '5cc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationUserReviewMatchKey',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationUserReviewMatchKey', '6f7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/GroupReconciliationUserReviewRemove',
                component: ComponentCreator('/docs/api/lusid/schemas/GroupReconciliationUserReviewRemove', '2b1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/HoldingAdjustment',
                component: ComponentCreator('/docs/api/lusid/schemas/HoldingAdjustment', 'b6b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/HoldingAdjustmentWithDate',
                component: ComponentCreator('/docs/api/lusid/schemas/HoldingAdjustmentWithDate', '945'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/HoldingContext',
                component: ComponentCreator('/docs/api/lusid/schemas/HoldingContext', '140'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/HoldingContributor',
                component: ComponentCreator('/docs/api/lusid/schemas/HoldingContributor', '843'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/HoldingIdsRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/HoldingIdsRequest', '3e8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/HoldingPricingInfo',
                component: ComponentCreator('/docs/api/lusid/schemas/HoldingPricingInfo', '434'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/HoldingsAdjustment',
                component: ComponentCreator('/docs/api/lusid/schemas/HoldingsAdjustment', '6ad'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/HoldingsAdjustmentHeader',
                component: ComponentCreator('/docs/api/lusid/schemas/HoldingsAdjustmentHeader', 'e56'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/IdentifierDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/IdentifierDefinition', 'b29'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/IdentifierPartSchema',
                component: ComponentCreator('/docs/api/lusid/schemas/IdentifierPartSchema', '498'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/IdSelectorDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/IdSelectorDefinition', '485'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/IndexConvention',
                component: ComponentCreator('/docs/api/lusid/schemas/IndexConvention', '59b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/IndexModelOptions',
                component: ComponentCreator('/docs/api/lusid/schemas/IndexModelOptions', 'b7e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/IndexProjectionDependency',
                component: ComponentCreator('/docs/api/lusid/schemas/IndexProjectionDependency', 'f65'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/IndustryClassifier',
                component: ComponentCreator('/docs/api/lusid/schemas/IndustryClassifier', 'e34'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InflationFixingDependency',
                component: ComponentCreator('/docs/api/lusid/schemas/InflationFixingDependency', '85c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InflationIndexConventions',
                component: ComponentCreator('/docs/api/lusid/schemas/InflationIndexConventions', 'f48'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InflationLeg',
                component: ComponentCreator('/docs/api/lusid/schemas/InflationLeg', '23a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InflationLinkedBond',
                component: ComponentCreator('/docs/api/lusid/schemas/InflationLinkedBond', '4e0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InflationSwap',
                component: ComponentCreator('/docs/api/lusid/schemas/InflationSwap', '218'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InformationalErrorEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/InformationalErrorEvent', 'a9a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InformationalEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/InformationalEvent', '309'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InlineValuationRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/InlineValuationRequest', '1cb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InlineValuationsReconciliationRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/InlineValuationsReconciliationRequest', '1c2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InputTransition',
                component: ComponentCreator('/docs/api/lusid/schemas/InputTransition', 'ff1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Instrument',
                component: ComponentCreator('/docs/api/lusid/schemas/Instrument', '9a3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentCapabilities',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentCapabilities', 'ac1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentCashFlow',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentCashFlow', '737'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentDefinition', 'ed1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentDefinitionFormat',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentDefinitionFormat', 'ea7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentEntity',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentEntity', 'e87'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentEvent', 'f83'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentEventConfiguration',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentEventConfiguration', '555'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentEventHolder',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentEventHolder', '25d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentEventInstruction',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentEventInstruction', 'da4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentEventInstructionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentEventInstructionRequest', 'c33'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentEventInstructionsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentEventInstructionsResponse', 'ebe'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentIdTypeDescriptor',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentIdTypeDescriptor', '441'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentIdValue',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentIdValue', 'c7a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentLeg',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentLeg', '141'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentList',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentList', '404'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentListComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentListComplianceParameter', '238'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentMatch',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentMatch', '812'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentModels',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentModels', '7c6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentPaymentDiary',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentPaymentDiary', 'db2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentPaymentDiaryLeg',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentPaymentDiaryLeg', '415'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentPaymentDiaryRow',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentPaymentDiaryRow', '109'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentProperties',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentProperties', '93d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentResolutionDetail',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentResolutionDetail', '9e9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InstrumentSearchProperty',
                component: ComponentCreator('/docs/api/lusid/schemas/InstrumentSearchProperty', '6dc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InterestRateSwap',
                component: ComponentCreator('/docs/api/lusid/schemas/InterestRateSwap', '2d1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/InterestRateSwaption',
                component: ComponentCreator('/docs/api/lusid/schemas/InterestRateSwaption', '293'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/IntermediateSecuritiesDistributionEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/IntermediateSecuritiesDistributionEvent', '8e1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Invalid',
                component: ComponentCreator('/docs/api/lusid/schemas/Invalid', '42f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/IrVolCubeData',
                component: ComponentCreator('/docs/api/lusid/schemas/IrVolCubeData', '4e4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/IrVolDependency',
                component: ComponentCreator('/docs/api/lusid/schemas/IrVolDependency', '263'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/IsBusinessDayResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/IsBusinessDayResponse', '062'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/IUnitDefinitionDto',
                component: ComponentCreator('/docs/api/lusid/schemas/IUnitDefinitionDto', '66f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/JournalEntryLine',
                component: ComponentCreator('/docs/api/lusid/schemas/JournalEntryLine', '284'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/JournalEntryLineShareClassBreakdown',
                component: ComponentCreator('/docs/api/lusid/schemas/JournalEntryLineShareClassBreakdown', 'c1d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/JournalEntryLinesQueryParameters',
                component: ComponentCreator('/docs/api/lusid/schemas/JournalEntryLinesQueryParameters', '2bb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/LabelValueSet',
                component: ComponentCreator('/docs/api/lusid/schemas/LabelValueSet', 'fc6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/LapseElection',
                component: ComponentCreator('/docs/api/lusid/schemas/LapseElection', '0b5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/LegalEntity',
                component: ComponentCreator('/docs/api/lusid/schemas/LegalEntity', '49d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/LegDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/LegDefinition', '4c3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/LevelStep',
                component: ComponentCreator('/docs/api/lusid/schemas/LevelStep', '812'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/LifeCycleEventLineage',
                component: ComponentCreator('/docs/api/lusid/schemas/LifeCycleEventLineage', 'ced'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/LifeCycleEventValue',
                component: ComponentCreator('/docs/api/lusid/schemas/LifeCycleEventValue', 'f21'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/LineageMember',
                component: ComponentCreator('/docs/api/lusid/schemas/LineageMember', '0f0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Link',
                component: ComponentCreator('/docs/api/lusid/schemas/Link', '5f0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ListAggregationReconciliation',
                component: ComponentCreator('/docs/api/lusid/schemas/ListAggregationReconciliation', '200'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ListAggregationResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/ListAggregationResponse', '508'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ListComplexMarketDataWithMetaDataResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/ListComplexMarketDataWithMetaDataResponse', '522'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/LoanFacility',
                component: ComponentCreator('/docs/api/lusid/schemas/LoanFacility', '647'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/LoanFacilityContractRolloverEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/LoanFacilityContractRolloverEvent', '85f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/LoanInterestRepaymentEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/LoanInterestRepaymentEvent', 'c92'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/LoanPeriod',
                component: ComponentCreator('/docs/api/lusid/schemas/LoanPeriod', 'b30'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/LoanPrincipalRepaymentEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/LoanPrincipalRepaymentEvent', '1a9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/LockPeriodDiaryEntryRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/LockPeriodDiaryEntryRequest', '142'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/LusidInstrument',
                component: ComponentCreator('/docs/api/lusid/schemas/LusidInstrument', '96f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/LusidProblemDetails',
                component: ComponentCreator('/docs/api/lusid/schemas/LusidProblemDetails', '5cd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/LusidTradeTicket',
                component: ComponentCreator('/docs/api/lusid/schemas/LusidTradeTicket', '2a8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/LusidUniqueId',
                component: ComponentCreator('/docs/api/lusid/schemas/LusidUniqueId', 'a30'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/LusidValidationProblemDetails',
                component: ComponentCreator('/docs/api/lusid/schemas/LusidValidationProblemDetails', '1db'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MappedString',
                component: ComponentCreator('/docs/api/lusid/schemas/MappedString', '765'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Mapping',
                component: ComponentCreator('/docs/api/lusid/schemas/Mapping', '34c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MappingRule',
                component: ComponentCreator('/docs/api/lusid/schemas/MappingRule', '8ce'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MarketContext',
                component: ComponentCreator('/docs/api/lusid/schemas/MarketContext', 'bd8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MarketDataKeyRule',
                component: ComponentCreator('/docs/api/lusid/schemas/MarketDataKeyRule', 'a3e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MarketDataOptions',
                component: ComponentCreator('/docs/api/lusid/schemas/MarketDataOptions', '69b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MarketDataOverrides',
                component: ComponentCreator('/docs/api/lusid/schemas/MarketDataOverrides', '109'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MarketDataSpecificRule',
                component: ComponentCreator('/docs/api/lusid/schemas/MarketDataSpecificRule', '4b9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MarketOptions',
                component: ComponentCreator('/docs/api/lusid/schemas/MarketOptions', '1ac'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MarketQuote',
                component: ComponentCreator('/docs/api/lusid/schemas/MarketQuote', '57b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MarkToMarketConventions',
                component: ComponentCreator('/docs/api/lusid/schemas/MarkToMarketConventions', '421'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MasteredInstrument',
                component: ComponentCreator('/docs/api/lusid/schemas/MasteredInstrument', '678'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MatchCriterion',
                component: ComponentCreator('/docs/api/lusid/schemas/MatchCriterion', '6e9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MaturityEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/MaturityEvent', 'a64'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MbsCouponEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/MbsCouponEvent', '3e9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MbsInterestDeferralEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/MbsInterestDeferralEvent', '119'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MbsInterestShortfallEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/MbsInterestShortfallEvent', '130'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MbsPrincipalEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/MbsPrincipalEvent', '031'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MbsPrincipalWriteOffEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/MbsPrincipalWriteOffEvent', '837'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Membership',
                component: ComponentCreator('/docs/api/lusid/schemas/Membership', 'f49'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MembershipAndStatus',
                component: ComponentCreator('/docs/api/lusid/schemas/MembershipAndStatus', '9a3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MergerEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/MergerEvent', 'f18'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MetricValue',
                component: ComponentCreator('/docs/api/lusid/schemas/MetricValue', 'ff8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ModelOptions',
                component: ComponentCreator('/docs/api/lusid/schemas/ModelOptions', '9e3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ModelSelection',
                component: ComponentCreator('/docs/api/lusid/schemas/ModelSelection', '328'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MovedOrderToDifferentBlockResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/MovedOrderToDifferentBlockResponse', '5a0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MoveOrdersToDifferentBlocksRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/MoveOrdersToDifferentBlocksRequest', '14b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/MultiCurrencyAmounts',
                component: ComponentCreator('/docs/api/lusid/schemas/MultiCurrencyAmounts', 'b59'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/NewInstrument',
                component: ComponentCreator('/docs/api/lusid/schemas/NewInstrument', 'e83'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/NextValueInSequenceResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/NextValueInSequenceResponse', 'f6c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OpaqueDependency',
                component: ComponentCreator('/docs/api/lusid/schemas/OpaqueDependency', '943'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OpaqueMarketData',
                component: ComponentCreator('/docs/api/lusid/schemas/OpaqueMarketData', '043'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OpaqueModelOptions',
                component: ComponentCreator('/docs/api/lusid/schemas/OpaqueModelOptions', 'eca'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OpenEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/OpenEvent', '8a0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Operation',
                component: ComponentCreator('/docs/api/lusid/schemas/Operation', '53c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OptionalitySchedule',
                component: ComponentCreator('/docs/api/lusid/schemas/OptionalitySchedule', '183'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OptionEntry',
                component: ComponentCreator('/docs/api/lusid/schemas/OptionEntry', '377'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OptionExerciseCashEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/OptionExerciseCashEvent', 'a33'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OptionExerciseElection',
                component: ComponentCreator('/docs/api/lusid/schemas/OptionExerciseElection', '712'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OptionExercisePhysicalEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/OptionExercisePhysicalEvent', '7bc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Order',
                component: ComponentCreator('/docs/api/lusid/schemas/Order', 'd01'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderBySpec',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderBySpec', '081'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderFlowConfiguration',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderFlowConfiguration', '65f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderGraphBlock',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderGraphBlock', '8a0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderGraphBlockAllocationDetail',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderGraphBlockAllocationDetail', '5ec'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderGraphBlockAllocationSynopsis',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderGraphBlockAllocationSynopsis', 'e6d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderGraphBlockExecutionDetail',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderGraphBlockExecutionDetail', 'cad'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderGraphBlockExecutionSynopsis',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderGraphBlockExecutionSynopsis', 'dae'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderGraphBlockOrderDetail',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderGraphBlockOrderDetail', '342'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderGraphBlockOrderSynopsis',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderGraphBlockOrderSynopsis', 'eb1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderGraphBlockPlacementDetail',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderGraphBlockPlacementDetail', '1fd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderGraphBlockPlacementSynopsis',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderGraphBlockPlacementSynopsis', 'bc3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderGraphBlockTransactionDetail',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderGraphBlockTransactionDetail', '971'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderGraphBlockTransactionSynopsis',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderGraphBlockTransactionSynopsis', '692'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderGraphPlacement',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderGraphPlacement', 'c20'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderGraphPlacementAllocationDetail',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderGraphPlacementAllocationDetail', '8bf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderGraphPlacementAllocationSynopsis',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderGraphPlacementAllocationSynopsis', 'c0d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderGraphPlacementChildPlacementDetail',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderGraphPlacementChildPlacementDetail', 'ca0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderGraphPlacementExecutionDetail',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderGraphPlacementExecutionDetail', '2fe'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderGraphPlacementExecutionSynopsis',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderGraphPlacementExecutionSynopsis', 'f29'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderGraphPlacementOrderDetail',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderGraphPlacementOrderDetail', '518'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderGraphPlacementOrderSynopsis',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderGraphPlacementOrderSynopsis', '47f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderGraphPlacementPlacementSynopsis',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderGraphPlacementPlacementSynopsis', '935'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderInstruction',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderInstruction', '6c3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderInstructionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderInstructionRequest', 'abd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderInstructionSetRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderInstructionSetRequest', 'ad2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderRequest', 'cf7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderSetRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderSetRequest', 'ac2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OrderUpdateRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/OrderUpdateRequest', 'c7f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OtcConfirmation',
                component: ComponentCreator('/docs/api/lusid/schemas/OtcConfirmation', 'e5a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OutputTransaction',
                component: ComponentCreator('/docs/api/lusid/schemas/OutputTransaction', '3d2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/OutputTransition',
                component: ComponentCreator('/docs/api/lusid/schemas/OutputTransition', 'd51'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Package',
                component: ComponentCreator('/docs/api/lusid/schemas/Package', 'dd1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PackageRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/PackageRequest', 'd69'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PackageSetRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/PackageSetRequest', '85f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfAbor',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfAbor', '888'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfAborConfiguration',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfAborConfiguration', 'e1b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfAccount',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfAccount', '098'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfAddressKeyDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfAddressKeyDefinition', 'cd6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfAllocation',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfAllocation', 'a73'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfAmortisationRuleSet',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfAmortisationRuleSet', 'b98'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfBlock',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfBlock', 'd49'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfCalendar',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfCalendar', '6d0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfChartOfAccounts',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfChartOfAccounts', '7bf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfCleardownModuleResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfCleardownModuleResponse', 'e42'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfCleardownModuleRule',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfCleardownModuleRule', 'bd9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfClosedPeriod',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfClosedPeriod', '364'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfComplianceRuleResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfComplianceRuleResponse', '1c8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfComplianceRunInfoV2',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfComplianceRunInfoV2', 'a55'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfComplianceTemplate',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfComplianceTemplate', '8ec'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfCorporateActionSource',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfCorporateActionSource', 'edd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfCustodianAccount',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfCustodianAccount', 'a52'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfCustomEntityDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfCustomEntityDefinition', 'b9e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfCustomEntityResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfCustomEntityResponse', 'd3c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfCustomEntityType',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfCustomEntityType', '857'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfCutLabelDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfCutLabelDefinition', 'ac4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfDataTypeSummary',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfDataTypeSummary', 'cb3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfDialectId',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfDialectId', 'd12'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfDiaryEntry',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfDiaryEntry', '6b1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfExecution',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfExecution', '63f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfFee',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfFee', 'b4f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfFeeType',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfFeeType', 'd34'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfFund',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfFund', 'f51'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfFundConfiguration',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfFundConfiguration', 'c62'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfGeneralLedgerProfileResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfGeneralLedgerProfileResponse', '3a3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfGroupReconciliationComparisonResult',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfGroupReconciliationComparisonResult', 'ed5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfGroupReconciliationComparisonRuleset',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfGroupReconciliationComparisonRuleset', '77e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfGroupReconciliationDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfGroupReconciliationDefinition', 'a92'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfIdentifierDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfIdentifierDefinition', 'dc2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfInstrument',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfInstrument', 'eea'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfInstrumentEventHolder',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfInstrumentEventHolder', '6e9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfInstrumentEventInstruction',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfInstrumentEventInstruction', 'cb2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfLegalEntity',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfLegalEntity', 'd02'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfOrder',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfOrder', '3fa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfOrderGraphBlock',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfOrderGraphBlock', 'e4c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfOrderGraphPlacement',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfOrderGraphPlacement', '7a6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfOrderInstruction',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfOrderInstruction', '8c9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfPackage',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfPackage', '860'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfParticipation',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfParticipation', '0b9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfPerson',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfPerson', 'd4a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfPlacement',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfPlacement', '811'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfPortfolioGroup',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfPortfolioGroup', '8f1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfPortfolioGroupSearchResult',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfPortfolioGroupSearchResult', '969'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfPortfolioSearchResult',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfPortfolioSearchResult', 'd90'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfPostingModuleResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfPostingModuleResponse', 'e1e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfPostingModuleRule',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfPostingModuleRule', 'b1f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfPropertyDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfPropertyDefinition', 'b30'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfPropertyDefinitionSearchResult',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfPropertyDefinitionSearchResult', 'f20'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfReconciliation',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfReconciliation', 'd08'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfReferenceListResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfReferenceListResponse', '857'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfRelationshipDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfRelationshipDefinition', '984'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfSequenceDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfSequenceDefinition', '9bd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfStagedModification',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfStagedModification', '96a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfStagedModificationsRequestedChangeInterval',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfStagedModificationsRequestedChangeInterval', '6a9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfStagingRuleSet',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfStagingRuleSet', 'a43'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfTimeline',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfTimeline', '508'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfTransactionTemplate',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfTransactionTemplate', '046'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfTransactionTemplateSpecification',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfTransactionTemplateSpecification', '91f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfTranslationScriptId',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfTranslationScriptId', '2c7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfValuationPointOverview',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfValuationPointOverview', '41f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfVirtualRow',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfVirtualRow', 'b99'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfWorkspace',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfWorkspace', '87e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PagedResourceListOfWorkspaceItem',
                component: ComponentCreator('/docs/api/lusid/schemas/PagedResourceListOfWorkspaceItem', 'a8e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Participation',
                component: ComponentCreator('/docs/api/lusid/schemas/Participation', '120'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ParticipationRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/ParticipationRequest', '6f9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ParticipationSetRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/ParticipationSetRequest', '7f5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PercentCheckStep',
                component: ComponentCreator('/docs/api/lusid/schemas/PercentCheckStep', '8b2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PercentCheckStepRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/PercentCheckStepRequest', '804'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PerformanceReturn',
                component: ComponentCreator('/docs/api/lusid/schemas/PerformanceReturn', 'f0d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PerformanceReturnsMetric',
                component: ComponentCreator('/docs/api/lusid/schemas/PerformanceReturnsMetric', '48d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PeriodDiaryEntriesReopenedResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/PeriodDiaryEntriesReopenedResponse', '4d3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PerpetualProperty',
                component: ComponentCreator('/docs/api/lusid/schemas/PerpetualProperty', 'f1b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Person',
                component: ComponentCreator('/docs/api/lusid/schemas/Person', '3dd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PlaceBlocksRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/PlaceBlocksRequest', '0f2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Placement',
                component: ComponentCreator('/docs/api/lusid/schemas/Placement', 'e6b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PlacementRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/PlacementRequest', '32c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PlacementSetRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/PlacementSetRequest', 'e4e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PlacementUpdateRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/PlacementUpdateRequest', '1dd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PnlJournalEntryLine',
                component: ComponentCreator('/docs/api/lusid/schemas/PnlJournalEntryLine', '121'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Portfolio',
                component: ComponentCreator('/docs/api/lusid/schemas/Portfolio', 'f18'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioCashFlow',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioCashFlow', '672'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioCashLadder',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioCashLadder', 'd82'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioDetails',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioDetails', '625'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioEntity',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioEntity', 'ca6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioEntityId',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioEntityId', '3d8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioGroup',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioGroup', 'cb9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioGroupIdComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioGroupIdComplianceParameter', '119'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioGroupIdList',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioGroupIdList', 'f28'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioGroupIdListComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioGroupIdListComplianceParameter', '1c1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioGroupProperties',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioGroupProperties', 'a62'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioGroupSearchResult',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioGroupSearchResult', 'fc8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioHolding',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioHolding', '489'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioId',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioId', '2ec'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioIdComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioIdComplianceParameter', '3a9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioIdList',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioIdList', 'c27'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioIdListComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioIdListComplianceParameter', 'fa1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioProperties',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioProperties', '86e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioReconciliationRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioReconciliationRequest', 'dc5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioResultDataKeyRule',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioResultDataKeyRule', '730'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioReturnBreakdown',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioReturnBreakdown', '69f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioSearchResult',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioSearchResult', '027'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfoliosReconciliationRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfoliosReconciliationRequest', '15e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioTradeTicket',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioTradeTicket', '0a2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PortfolioWithoutHref',
                component: ComponentCreator('/docs/api/lusid/schemas/PortfolioWithoutHref', '66a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PostingModuleDetails',
                component: ComponentCreator('/docs/api/lusid/schemas/PostingModuleDetails', 'f3a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PostingModuleRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/PostingModuleRequest', 'ba1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PostingModuleResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/PostingModuleResponse', 'aca'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PostingModuleRule',
                component: ComponentCreator('/docs/api/lusid/schemas/PostingModuleRule', 'be8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PostingModuleRulesUpdatedResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/PostingModuleRulesUpdatedResponse', 'c88'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Premium',
                component: ComponentCreator('/docs/api/lusid/schemas/Premium', 'be2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PreTradeConfiguration',
                component: ComponentCreator('/docs/api/lusid/schemas/PreTradeConfiguration', '916'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PreviousFundValuationPointData',
                component: ComponentCreator('/docs/api/lusid/schemas/PreviousFundValuationPointData', '457'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PreviousNAV',
                component: ComponentCreator('/docs/api/lusid/schemas/PreviousNAV', '4c4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PreviousShareClassBreakdown',
                component: ComponentCreator('/docs/api/lusid/schemas/PreviousShareClassBreakdown', 'd2c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PricingContext',
                component: ComponentCreator('/docs/api/lusid/schemas/PricingContext', '08e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PricingOptions',
                component: ComponentCreator('/docs/api/lusid/schemas/PricingOptions', 'b5d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ProcessedCommand',
                component: ComponentCreator('/docs/api/lusid/schemas/ProcessedCommand', '923'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Property',
                component: ComponentCreator('/docs/api/lusid/schemas/Property', '3ee'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PropertyDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/PropertyDefinition', 'e75'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PropertyDefinitionEntity',
                component: ComponentCreator('/docs/api/lusid/schemas/PropertyDefinitionEntity', '497'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PropertyDefinitionSearchResult',
                component: ComponentCreator('/docs/api/lusid/schemas/PropertyDefinitionSearchResult', '486'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PropertyFilter',
                component: ComponentCreator('/docs/api/lusid/schemas/PropertyFilter', '26b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PropertyInterval',
                component: ComponentCreator('/docs/api/lusid/schemas/PropertyInterval', '382'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PropertyKeyComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/PropertyKeyComplianceParameter', '5f6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PropertyKeyListComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/PropertyKeyListComplianceParameter', 'cff'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PropertyList',
                component: ComponentCreator('/docs/api/lusid/schemas/PropertyList', '626'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PropertyListComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/PropertyListComplianceParameter', '6f2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PropertyReferenceDataValue',
                component: ComponentCreator('/docs/api/lusid/schemas/PropertyReferenceDataValue', '5d2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PropertySchema',
                component: ComponentCreator('/docs/api/lusid/schemas/PropertySchema', '39c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PropertyValue',
                component: ComponentCreator('/docs/api/lusid/schemas/PropertyValue', '130'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PropertyValueEquals',
                component: ComponentCreator('/docs/api/lusid/schemas/PropertyValueEquals', '12d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/PropertyValueIn',
                component: ComponentCreator('/docs/api/lusid/schemas/PropertyValueIn', '912'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ProtectionPayoutCashFlowEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/ProtectionPayoutCashFlowEvent', '492'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/QuantityInstructed',
                component: ComponentCreator('/docs/api/lusid/schemas/QuantityInstructed', '2a1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/QueryableKey',
                component: ComponentCreator('/docs/api/lusid/schemas/QueryableKey', '2e7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/QueryApplicableInstrumentEventsRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/QueryApplicableInstrumentEventsRequest', '84d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/QueryBucketedCashFlowsRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/QueryBucketedCashFlowsRequest', 'b17'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/QueryCashFlowsRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/QueryCashFlowsRequest', 'c75'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/QueryInstrumentEventsRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/QueryInstrumentEventsRequest', '8fe'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/QueryTradeTicketsRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/QueryTradeTicketsRequest', '983'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Quote',
                component: ComponentCreator('/docs/api/lusid/schemas/Quote', '9c5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/QuoteAccessMetadataRule',
                component: ComponentCreator('/docs/api/lusid/schemas/QuoteAccessMetadataRule', '1cf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/QuoteAccessMetadataRuleId',
                component: ComponentCreator('/docs/api/lusid/schemas/QuoteAccessMetadataRuleId', '068'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/QuoteDependency',
                component: ComponentCreator('/docs/api/lusid/schemas/QuoteDependency', '02a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/QuotedSecurity',
                component: ComponentCreator('/docs/api/lusid/schemas/QuotedSecurity', '2e0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/QuoteId',
                component: ComponentCreator('/docs/api/lusid/schemas/QuoteId', '0db'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/QuoteSeriesId',
                component: ComponentCreator('/docs/api/lusid/schemas/QuoteSeriesId', '28f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/RawVendorEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/RawVendorEvent', '9a7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/RealisedGainLoss',
                component: ComponentCreator('/docs/api/lusid/schemas/RealisedGainLoss', 'b63'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/RecipeBlock',
                component: ComponentCreator('/docs/api/lusid/schemas/RecipeBlock', '853'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/RecipeComposer',
                component: ComponentCreator('/docs/api/lusid/schemas/RecipeComposer', 'a25'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/RecipeValue',
                component: ComponentCreator('/docs/api/lusid/schemas/RecipeValue', '5ed'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/RecombineStep',
                component: ComponentCreator('/docs/api/lusid/schemas/RecombineStep', 'c25'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/RecommendedSortBy',
                component: ComponentCreator('/docs/api/lusid/schemas/RecommendedSortBy', '2fe'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReconcileDateTimeRule',
                component: ComponentCreator('/docs/api/lusid/schemas/ReconcileDateTimeRule', '797'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReconciledTransaction',
                component: ComponentCreator('/docs/api/lusid/schemas/ReconciledTransaction', '121'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReconcileExact',
                component: ComponentCreator('/docs/api/lusid/schemas/ReconcileExact', 'b15'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReconcileNumericRule',
                component: ComponentCreator('/docs/api/lusid/schemas/ReconcileNumericRule', 'fcc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReconcileStringRule',
                component: ComponentCreator('/docs/api/lusid/schemas/ReconcileStringRule', '59c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Reconciliation',
                component: ComponentCreator('/docs/api/lusid/schemas/Reconciliation', 'dd5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReconciliationBreak',
                component: ComponentCreator('/docs/api/lusid/schemas/ReconciliationBreak', '4d3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReconciliationConfiguration',
                component: ComponentCreator('/docs/api/lusid/schemas/ReconciliationConfiguration', '86f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReconciliationId',
                component: ComponentCreator('/docs/api/lusid/schemas/ReconciliationId', '047'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReconciliationLeftRightAddressKeyPair',
                component: ComponentCreator('/docs/api/lusid/schemas/ReconciliationLeftRightAddressKeyPair', 'c1d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReconciliationLine',
                component: ComponentCreator('/docs/api/lusid/schemas/ReconciliationLine', '6a0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReconciliationRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/ReconciliationRequest', 'dec'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReconciliationResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/ReconciliationResponse', '2b1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReconciliationRule',
                component: ComponentCreator('/docs/api/lusid/schemas/ReconciliationRule', '8b3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReconciliationSideConfiguration',
                component: ComponentCreator('/docs/api/lusid/schemas/ReconciliationSideConfiguration', 'dbb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReconciliationTransactions',
                component: ComponentCreator('/docs/api/lusid/schemas/ReconciliationTransactions', 'bd7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReferenceData',
                component: ComponentCreator('/docs/api/lusid/schemas/ReferenceData', 'c2b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReferenceInstrument',
                component: ComponentCreator('/docs/api/lusid/schemas/ReferenceInstrument', '807'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReferenceList',
                component: ComponentCreator('/docs/api/lusid/schemas/ReferenceList', 'fa9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReferenceListRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/ReferenceListRequest', '0c9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReferenceListResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/ReferenceListResponse', '5fd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReferencePortfolioConstituent',
                component: ComponentCreator('/docs/api/lusid/schemas/ReferencePortfolioConstituent', '582'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReferencePortfolioConstituentRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/ReferencePortfolioConstituentRequest', 'aa2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/RelatedEntity',
                component: ComponentCreator('/docs/api/lusid/schemas/RelatedEntity', '0d3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Relation',
                component: ComponentCreator('/docs/api/lusid/schemas/Relation', '5a9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/RelationDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/RelationDefinition', '231'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Relationship',
                component: ComponentCreator('/docs/api/lusid/schemas/Relationship', '89c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/RelationshipDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/RelationshipDefinition', '541'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/RelativeDateOffset',
                component: ComponentCreator('/docs/api/lusid/schemas/RelativeDateOffset', 'd9d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReOpenPeriodDiaryEntryRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/ReOpenPeriodDiaryEntryRequest', '1d9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Repo',
                component: ComponentCreator('/docs/api/lusid/schemas/Repo', 'b57'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/RequestedChanges',
                component: ComponentCreator('/docs/api/lusid/schemas/RequestedChanges', 'e62'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResetEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/ResetEvent', 'fd9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceId',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceId', '2ce'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfAccessControlledResource',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfAccessControlledResource', '76e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfAccessMetadataValueOf',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfAccessMetadataValueOf', '5c6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfAddressKeyDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfAddressKeyDefinition', '87d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfAggregatedReturn',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfAggregatedReturn', 'b04'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfAggregationQuery',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfAggregationQuery', '364'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfAllocation',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfAllocation', '30c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfApplicableInstrumentEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfApplicableInstrumentEvent', 'c59'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfBlock',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfBlock', '884'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfBlockAndOrders',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfBlockAndOrders', '884'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfCalendarDate',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfCalendarDate', 'ad0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfChange',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfChange', '77c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfChangeHistory',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfChangeHistory', 'b94'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfChangeInterval',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfChangeInterval', '215'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfChangeIntervalWithOrderManagementDetail',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfChangeIntervalWithOrderManagementDetail', '0b3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfComplianceBreachedOrderInfo',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfComplianceBreachedOrderInfo', '6b0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfComplianceRule',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfComplianceRule', 'db1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfComplianceRuleResult',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfComplianceRuleResult', 'f56'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfComplianceRunInfo',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfComplianceRunInfo', 'ffc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfConstituentsAdjustmentHeader',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfConstituentsAdjustmentHeader', '291'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfCorporateAction',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfCorporateAction', 'be3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfDataModelSummary',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfDataModelSummary', '818'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfDataType',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfDataType', '337'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfExecution',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfExecution', '6f6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfFeeRule',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfFeeRule', '11b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfGetCdsFlowConventionsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfGetCdsFlowConventionsResponse', '859'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfGetCounterpartyAgreementResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfGetCounterpartyAgreementResponse', '438'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfGetCreditSupportAnnexResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfGetCreditSupportAnnexResponse', '85f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfGetFlowConventionsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfGetFlowConventionsResponse', '54b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfGetIndexConventionResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfGetIndexConventionResponse', '7f6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfGetRecipeComposerResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfGetRecipeComposerResponse', 'cb7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfGetRecipeResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfGetRecipeResponse', '6d2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfHoldingsAdjustmentHeader',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfHoldingsAdjustmentHeader', '868'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfInstrumentCashFlow',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfInstrumentCashFlow', '5c1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfInstrumentEventHolder',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfInstrumentEventHolder', '42c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfInstrumentIdTypeDescriptor',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfInstrumentIdTypeDescriptor', '1e8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfIUnitDefinitionDto',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfIUnitDefinitionDto', '24f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfLegalEntity',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfLegalEntity', 'd3c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfListComplexMarketDataWithMetaDataResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfListComplexMarketDataWithMetaDataResponse', '3d6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfMapping',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfMapping', '63d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfMovedOrderToDifferentBlockResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfMovedOrderToDifferentBlockResponse', '73f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfOrder',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfOrder', '4ba'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfOrderInstruction',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfOrderInstruction', 'fcb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfOutputTransaction',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfOutputTransaction', '5f2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfPackage',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfPackage', '8b5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfParticipation',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfParticipation', '04e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfPerformanceReturn',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfPerformanceReturn', 'd11'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfPerson',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfPerson', 'dd3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfPlacement',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfPlacement', 'd96'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfPortfolio',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfPortfolio', 'e32'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfPortfolioCashFlow',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfPortfolioCashFlow', '36c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfPortfolioCashLadder',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfPortfolioCashLadder', '3ef'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfPortfolioTradeTicket',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfPortfolioTradeTicket', '0f1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfProcessedCommand',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfProcessedCommand', '1ea'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfProperty',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfProperty', '419'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfPropertyDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfPropertyDefinition', '635'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfPropertyInterval',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfPropertyInterval', 'd25'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfQueryableKey',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfQueryableKey', '104'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfQuote',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfQuote', '26b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfQuoteAccessMetadataRule',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfQuoteAccessMetadataRule', '786'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfReconciliationBreak',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfReconciliationBreak', 'b9d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfRelation',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfRelation', '0d1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfRelationship',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfRelationship', '9c0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfScopeDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfScopeDefinition', '81f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfSideDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfSideDefinition', '868'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfString',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfString', '6f5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfTaxRuleSet',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfTaxRuleSet', 'ddb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfTransaction',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfTransaction', '428'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfTransactionType',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfTransactionType', '341'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResourceListOfValueType',
                component: ComponentCreator('/docs/api/lusid/schemas/ResourceListOfValueType', '99d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResponseMetaData',
                component: ComponentCreator('/docs/api/lusid/schemas/ResponseMetaData', '4fb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResultDataKeyRule',
                component: ComponentCreator('/docs/api/lusid/schemas/ResultDataKeyRule', '664'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResultDataSchema',
                component: ComponentCreator('/docs/api/lusid/schemas/ResultDataSchema', 'c7f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResultKeyRule',
                component: ComponentCreator('/docs/api/lusid/schemas/ResultKeyRule', 'd3c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResultValue',
                component: ComponentCreator('/docs/api/lusid/schemas/ResultValue', '483'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResultValue0D',
                component: ComponentCreator('/docs/api/lusid/schemas/ResultValue0D', 'c51'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResultValueBool',
                component: ComponentCreator('/docs/api/lusid/schemas/ResultValueBool', 'bb9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResultValueCurrency',
                component: ComponentCreator('/docs/api/lusid/schemas/ResultValueCurrency', '616'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResultValueDateTimeOffset',
                component: ComponentCreator('/docs/api/lusid/schemas/ResultValueDateTimeOffset', '57f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResultValueDecimal',
                component: ComponentCreator('/docs/api/lusid/schemas/ResultValueDecimal', '2e1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResultValueDictionary',
                component: ComponentCreator('/docs/api/lusid/schemas/ResultValueDictionary', '116'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResultValueInt',
                component: ComponentCreator('/docs/api/lusid/schemas/ResultValueInt', '122'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResultValueLifeCycleEventValue',
                component: ComponentCreator('/docs/api/lusid/schemas/ResultValueLifeCycleEventValue', '8db'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ResultValueString',
                component: ComponentCreator('/docs/api/lusid/schemas/ResultValueString', '908'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReturnZeroPvOptions',
                component: ComponentCreator('/docs/api/lusid/schemas/ReturnZeroPvOptions', '79f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ReverseStockSplitEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/ReverseStockSplitEvent', '33a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/RolloverConstituent',
                component: ComponentCreator('/docs/api/lusid/schemas/RolloverConstituent', '11c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/RoundingConfiguration',
                component: ComponentCreator('/docs/api/lusid/schemas/RoundingConfiguration', '89b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/RoundingConfigurationComponent',
                component: ComponentCreator('/docs/api/lusid/schemas/RoundingConfigurationComponent', 'd51'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/RoundingConvention',
                component: ComponentCreator('/docs/api/lusid/schemas/RoundingConvention', 'c62'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/RulesInterval',
                component: ComponentCreator('/docs/api/lusid/schemas/RulesInterval', 'd2e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Schedule',
                component: ComponentCreator('/docs/api/lusid/schemas/Schedule', 'a5f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Schema',
                component: ComponentCreator('/docs/api/lusid/schemas/Schema', '9f3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ScopeDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/ScopeDefinition', 'cca'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ScripDividendEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/ScripDividendEvent', '5b9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ScriptMapReference',
                component: ComponentCreator('/docs/api/lusid/schemas/ScriptMapReference', '565'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SecurityElection',
                component: ComponentCreator('/docs/api/lusid/schemas/SecurityElection', 'b7d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SecurityOfferElection',
                component: ComponentCreator('/docs/api/lusid/schemas/SecurityOfferElection', '1d1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SequenceDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/SequenceDefinition', 'a7c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SetAmortisationRulesRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/SetAmortisationRulesRequest', 'afa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SetLegalEntityIdentifiersRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/SetLegalEntityIdentifiersRequest', '0dd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SetLegalEntityPropertiesRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/SetLegalEntityPropertiesRequest', 'ada'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SetPersonIdentifiersRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/SetPersonIdentifiersRequest', '19a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SetPersonPropertiesRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/SetPersonPropertiesRequest', '957'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SetShareClassInstrumentsRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/SetShareClassInstrumentsRequest', 'a2d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SettlementCycle',
                component: ComponentCreator('/docs/api/lusid/schemas/SettlementCycle', '662'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SettlementSchedule',
                component: ComponentCreator('/docs/api/lusid/schemas/SettlementSchedule', '929'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SetTransactionConfigurationAlias',
                component: ComponentCreator('/docs/api/lusid/schemas/SetTransactionConfigurationAlias', 'da3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SetTransactionConfigurationSourceRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/SetTransactionConfigurationSourceRequest', '4fa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ShareClassAmount',
                component: ComponentCreator('/docs/api/lusid/schemas/ShareClassAmount', '288'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ShareClassBreakdown',
                component: ComponentCreator('/docs/api/lusid/schemas/ShareClassBreakdown', '2d0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ShareClassData',
                component: ComponentCreator('/docs/api/lusid/schemas/ShareClassData', 'bf2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ShareClassDealingBreakdown',
                component: ComponentCreator('/docs/api/lusid/schemas/ShareClassDealingBreakdown', '226'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ShareClassDetails',
                component: ComponentCreator('/docs/api/lusid/schemas/ShareClassDetails', '48b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ShareClassPnlBreakdown',
                component: ComponentCreator('/docs/api/lusid/schemas/ShareClassPnlBreakdown', 'd23'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SideConfigurationData',
                component: ComponentCreator('/docs/api/lusid/schemas/SideConfigurationData', 'aba'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SideConfigurationDataRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/SideConfigurationDataRequest', '61c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SideDefinition',
                component: ComponentCreator('/docs/api/lusid/schemas/SideDefinition', '309'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SideDefinitionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/SideDefinitionRequest', '141'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SidesDefinitionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/SidesDefinitionRequest', 'b40'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SimpleCashFlowLoan',
                component: ComponentCreator('/docs/api/lusid/schemas/SimpleCashFlowLoan', '6cc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SimpleInstrument',
                component: ComponentCreator('/docs/api/lusid/schemas/SimpleInstrument', '2d7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SimpleRoundingConvention',
                component: ComponentCreator('/docs/api/lusid/schemas/SimpleRoundingConvention', 'b64'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SpecificHoldingPricingInfo',
                component: ComponentCreator('/docs/api/lusid/schemas/SpecificHoldingPricingInfo', '491'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SpinOffEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/SpinOffEvent', '0d8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/StagedModification',
                component: ComponentCreator('/docs/api/lusid/schemas/StagedModification', '753'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/StagedModificationDecision',
                component: ComponentCreator('/docs/api/lusid/schemas/StagedModificationDecision', 'ea3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/StagedModificationDecisionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/StagedModificationDecisionRequest', 'f2e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/StagedModificationEffectiveRange',
                component: ComponentCreator('/docs/api/lusid/schemas/StagedModificationEffectiveRange', '4a5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/StagedModificationsEntityHrefs',
                component: ComponentCreator('/docs/api/lusid/schemas/StagedModificationsEntityHrefs', '9be'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/StagedModificationsInfo',
                component: ComponentCreator('/docs/api/lusid/schemas/StagedModificationsInfo', 'd42'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/StagedModificationsRequestedChangeInterval',
                component: ComponentCreator('/docs/api/lusid/schemas/StagedModificationsRequestedChangeInterval', '4e1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/StagedModificationStagingRule',
                component: ComponentCreator('/docs/api/lusid/schemas/StagedModificationStagingRule', '194'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/StagingRule',
                component: ComponentCreator('/docs/api/lusid/schemas/StagingRule', '879'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/StagingRuleApprovalCriteria',
                component: ComponentCreator('/docs/api/lusid/schemas/StagingRuleApprovalCriteria', '5e8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/StagingRuleMatchCriteria',
                component: ComponentCreator('/docs/api/lusid/schemas/StagingRuleMatchCriteria', 'e4f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/StagingRuleSet',
                component: ComponentCreator('/docs/api/lusid/schemas/StagingRuleSet', '46b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/StepSchedule',
                component: ComponentCreator('/docs/api/lusid/schemas/StepSchedule', '215'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/StockDividendEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/StockDividendEvent', '577'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/StockSplitEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/StockSplitEvent', '821'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Strategy',
                component: ComponentCreator('/docs/api/lusid/schemas/Strategy', '9d8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/StringComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/StringComplianceParameter', '0be'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/StringList',
                component: ComponentCreator('/docs/api/lusid/schemas/StringList', '66a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/StringListComplianceParameter',
                component: ComponentCreator('/docs/api/lusid/schemas/StringListComplianceParameter', 'a36'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/StructuredResultData',
                component: ComponentCreator('/docs/api/lusid/schemas/StructuredResultData', '69f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/StructuredResultDataId',
                component: ComponentCreator('/docs/api/lusid/schemas/StructuredResultDataId', '752'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SubHoldingKeyValueEquals',
                component: ComponentCreator('/docs/api/lusid/schemas/SubHoldingKeyValueEquals', 'b14'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SwapCashFlowEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/SwapCashFlowEvent', '3a0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/SwapPrincipalEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/SwapPrincipalEvent', '220'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TargetTaxLot',
                component: ComponentCreator('/docs/api/lusid/schemas/TargetTaxLot', 'f70'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TargetTaxLotRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/TargetTaxLotRequest', '7bf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TaxRule',
                component: ComponentCreator('/docs/api/lusid/schemas/TaxRule', '28e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TaxRuleSet',
                component: ComponentCreator('/docs/api/lusid/schemas/TaxRuleSet', 'e13'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TemplateField',
                component: ComponentCreator('/docs/api/lusid/schemas/TemplateField', 'd16'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TenderEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/TenderEvent', 'cc8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TermDeposit',
                component: ComponentCreator('/docs/api/lusid/schemas/TermDeposit', '065'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TermDepositInterestEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/TermDepositInterestEvent', '6fd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TermDepositPrincipalEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/TermDepositPrincipalEvent', 'b30'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Timeline',
                component: ComponentCreator('/docs/api/lusid/schemas/Timeline', 'ff7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TimeZoneConventions',
                component: ComponentCreator('/docs/api/lusid/schemas/TimeZoneConventions', '2e1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TotalReturnSwap',
                component: ComponentCreator('/docs/api/lusid/schemas/TotalReturnSwap', 'd36'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Touch',
                component: ComponentCreator('/docs/api/lusid/schemas/Touch', 'b21'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TradeTicket',
                component: ComponentCreator('/docs/api/lusid/schemas/TradeTicket', '320'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TradingConventions',
                component: ComponentCreator('/docs/api/lusid/schemas/TradingConventions', '3a2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Transaction',
                component: ComponentCreator('/docs/api/lusid/schemas/Transaction', '29c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionConfigurationData',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionConfigurationData', '7ca'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionConfigurationDataRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionConfigurationDataRequest', 'f7f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionConfigurationMovementData',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionConfigurationMovementData', 'c53'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionConfigurationMovementDataRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionConfigurationMovementDataRequest', 'edb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionConfigurationTypeAlias',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionConfigurationTypeAlias', 'd0d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionCurrencyAndAmount',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionCurrencyAndAmount', '662'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionDateWindows',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionDateWindows', 'c5b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionDiagnostics',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionDiagnostics', 'ff1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionFieldMap',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionFieldMap', '71a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionPrice',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionPrice', '855'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionPriceAndType',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionPriceAndType', '4b0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionPropertyMap',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionPropertyMap', 'f00'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionPropertyMapping',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionPropertyMapping', 'f41'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionPropertyMappingRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionPropertyMappingRequest', 'a34'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionQueryParameters',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionQueryParameters', '254'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionReconciliationRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionReconciliationRequest', '8bd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionReconciliationRequestV2',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionReconciliationRequestV2', '6d1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionRequest', '611'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionSetConfigurationData',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionSetConfigurationData', '761'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionSetConfigurationDataRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionSetConfigurationDataRequest', '91c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionsReconciliationsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionsReconciliationsResponse', '110'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionTemplate',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionTemplate', '677'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionTemplateRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionTemplateRequest', '9c5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionTemplateSpecification',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionTemplateSpecification', '1e6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionType',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionType', '032'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionTypeAlias',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionTypeAlias', '9aa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionTypeCalculation',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionTypeCalculation', '433'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionTypeDetails',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionTypeDetails', 'ebd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionTypeMovement',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionTypeMovement', '5a3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionTypePropertyMapping',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionTypePropertyMapping', '3d9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransactionTypeRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/TransactionTypeRequest', 'd4b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TransitionEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/TransitionEvent', 'a33'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TranslateEntitiesInlinedRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/TranslateEntitiesInlinedRequest', 'f44'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TranslateEntitiesRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/TranslateEntitiesRequest', '569'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TranslateEntitiesResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/TranslateEntitiesResponse', '3af'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TranslateInstrumentDefinitionsRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/TranslateInstrumentDefinitionsRequest', 'ea3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TranslateInstrumentDefinitionsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/TranslateInstrumentDefinitionsResponse', '6fc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TranslateTradeTicketRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/TranslateTradeTicketRequest', '4f4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TranslateTradeTicketsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/TranslateTradeTicketsResponse', 'ed9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TranslationContext',
                component: ComponentCreator('/docs/api/lusid/schemas/TranslationContext', 'bf2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TranslationInput',
                component: ComponentCreator('/docs/api/lusid/schemas/TranslationInput', '902'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TranslationResult',
                component: ComponentCreator('/docs/api/lusid/schemas/TranslationResult', 'f2c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TranslationScript',
                component: ComponentCreator('/docs/api/lusid/schemas/TranslationScript', '81e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TranslationScriptId',
                component: ComponentCreator('/docs/api/lusid/schemas/TranslationScriptId', 'e5e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TrialBalance',
                component: ComponentCreator('/docs/api/lusid/schemas/TrialBalance', 'a5e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TrialBalanceQueryParameters',
                component: ComponentCreator('/docs/api/lusid/schemas/TrialBalanceQueryParameters', '54e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TriggerEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/TriggerEvent', '443'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/TypedResourceId',
                component: ComponentCreator('/docs/api/lusid/schemas/TypedResourceId', '6a2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UnitisationData',
                component: ComponentCreator('/docs/api/lusid/schemas/UnitisationData', 'e58'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UnitsRatio',
                component: ComponentCreator('/docs/api/lusid/schemas/UnitsRatio', '447'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Unknown',
                component: ComponentCreator('/docs/api/lusid/schemas/Unknown', 'b44'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UnsettledCash',
                component: ComponentCreator('/docs/api/lusid/schemas/UnsettledCash', '515'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateAmortisationRuleSetDetailsRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateAmortisationRuleSetDetailsRequest', '346'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateCalendarRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateCalendarRequest', '439'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateComplianceTemplateRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateComplianceTemplateRequest', '077'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateCustomDataModelRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateCustomDataModelRequest', 'ce7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateCustomEntityDefinitionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateCustomEntityDefinitionRequest', 'd2f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateCustomEntityTypeRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateCustomEntityTypeRequest', 'b98'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateCutLabelDefinitionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateCutLabelDefinitionRequest', 'a83'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateDataTypeRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateDataTypeRequest', '02c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateDepositAmountEvent',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateDepositAmountEvent', '6b0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateDerivedPropertyDefinitionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateDerivedPropertyDefinitionRequest', '68d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateFeeTypeRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateFeeTypeRequest', 'bfd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateGroupReconciliationComparisonRulesetRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateGroupReconciliationComparisonRulesetRequest', '97d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateGroupReconciliationDefinitionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateGroupReconciliationDefinitionRequest', '418'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateIdentifierDefinitionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateIdentifierDefinitionRequest', '68b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateInstrumentIdentifierRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateInstrumentIdentifierRequest', '113'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateOrdersResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateOrdersResponse', '7d8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdatePlacementsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdatePlacementsResponse', '949'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdatePortfolioGroupRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdatePortfolioGroupRequest', '539'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdatePortfolioRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdatePortfolioRequest', '9a5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdatePropertyDefinitionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdatePropertyDefinitionRequest', 'b64'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateReconciliationRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateReconciliationRequest', '833'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateReferenceDataRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateReferenceDataRequest', '3fc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateRelationshipDefinitionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateRelationshipDefinitionRequest', 'f61'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateStagingRuleSetRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateStagingRuleSetRequest', '51d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateTaxRuleSetRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateTaxRuleSetRequest', '29a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateTimelineRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateTimelineRequest', '60a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpdateUnitRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpdateUnitRequest', '428'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertCdsFlowConventionsRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertCdsFlowConventionsRequest', '2cf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertComplexMarketDataRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertComplexMarketDataRequest', '749'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertComplianceRuleRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertComplianceRuleRequest', '130'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertComplianceRunSummaryRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertComplianceRunSummaryRequest', '4cf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertComplianceRunSummaryResult',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertComplianceRunSummaryResult', '27f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertCorporateActionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertCorporateActionRequest', '354'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertCorporateActionsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertCorporateActionsResponse', '232'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertCounterpartyAgreementRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertCounterpartyAgreementRequest', 'd14'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertCreditSupportAnnexRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertCreditSupportAnnexRequest', '56d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertCustomEntitiesResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertCustomEntitiesResponse', '88c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertCustomEntityAccessMetadataRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertCustomEntityAccessMetadataRequest', '040'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertDialectRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertDialectRequest', '0e6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertFlowConventionsRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertFlowConventionsRequest', '1b7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertIndexConventionRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertIndexConventionRequest', '536'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertInstrumentEventRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertInstrumentEventRequest', '4ea'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertInstrumentEventsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertInstrumentEventsResponse', '606'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertInstrumentPropertiesResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertInstrumentPropertiesResponse', 'a14'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertInstrumentPropertyRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertInstrumentPropertyRequest', '8bf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertInstrumentsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertInstrumentsResponse', '410'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertLegalEntitiesResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertLegalEntitiesResponse', '1a3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertLegalEntityAccessMetadataRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertLegalEntityAccessMetadataRequest', '63d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertLegalEntityRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertLegalEntityRequest', 'c95'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertPersonAccessMetadataRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertPersonAccessMetadataRequest', 'c66'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertPersonRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertPersonRequest', '9f8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertPersonsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertPersonsResponse', '6e5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertPortfolioAccessMetadataRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertPortfolioAccessMetadataRequest', '187'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertPortfolioGroupAccessMetadataRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertPortfolioGroupAccessMetadataRequest', '403'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertPortfolioTransactionsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertPortfolioTransactionsResponse', '41f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertQuoteAccessMetadataRuleRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertQuoteAccessMetadataRuleRequest', '165'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertQuoteRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertQuoteRequest', '89d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertQuotesResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertQuotesResponse', 'b23'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertRecipeComposerRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertRecipeComposerRequest', '20c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertRecipeRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertRecipeRequest', '083'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertReferencePortfolioConstituentPropertiesRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertReferencePortfolioConstituentPropertiesRequest', '9d9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertReferencePortfolioConstituentPropertiesResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertReferencePortfolioConstituentPropertiesResponse', 'd3f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertReferencePortfolioConstituentsRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertReferencePortfolioConstituentsRequest', 'a94'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertReferencePortfolioConstituentsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertReferencePortfolioConstituentsResponse', 'a3e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertResultValuesDataRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertResultValuesDataRequest', '9e3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertReturnsResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertReturnsResponse', '78b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertSingleStructuredDataResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertSingleStructuredDataResponse', '1d9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertStructuredDataResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertStructuredDataResponse', '6c1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertStructuredResultDataRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertStructuredResultDataRequest', 'eb7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertTransactionPropertiesResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertTransactionPropertiesResponse', '945'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertTranslationScriptRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertTranslationScriptRequest', 'e45'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/UpsertValuationPointRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/UpsertValuationPointRequest', 'b2b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/User',
                component: ComponentCreator('/docs/api/lusid/schemas/User', '4f4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ValuationPointDataQueryParameters',
                component: ComponentCreator('/docs/api/lusid/schemas/ValuationPointDataQueryParameters', '20c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ValuationPointDataRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/ValuationPointDataRequest', '75f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ValuationPointDataResponse',
                component: ComponentCreator('/docs/api/lusid/schemas/ValuationPointDataResponse', '1f7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ValuationPointOverview',
                component: ComponentCreator('/docs/api/lusid/schemas/ValuationPointOverview', '29b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ValuationPointResourceListOfAccountedTransaction',
                component: ComponentCreator('/docs/api/lusid/schemas/ValuationPointResourceListOfAccountedTransaction', '455'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ValuationPointResourceListOfFundJournalEntryLine',
                component: ComponentCreator('/docs/api/lusid/schemas/ValuationPointResourceListOfFundJournalEntryLine', '0d6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ValuationPointResourceListOfPnlJournalEntryLine',
                component: ComponentCreator('/docs/api/lusid/schemas/ValuationPointResourceListOfPnlJournalEntryLine', 'c93'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ValuationPointResourceListOfTrialBalance',
                component: ComponentCreator('/docs/api/lusid/schemas/ValuationPointResourceListOfTrialBalance', 'd4a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ValuationRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/ValuationRequest', 'e55'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ValuationSchedule',
                component: ComponentCreator('/docs/api/lusid/schemas/ValuationSchedule', '77d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ValuationsReconciliationRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/ValuationsReconciliationRequest', '067'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/ValueType',
                component: ComponentCreator('/docs/api/lusid/schemas/ValueType', '57b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Vendor',
                component: ComponentCreator('/docs/api/lusid/schemas/Vendor', '527'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/VendorModelRule',
                component: ComponentCreator('/docs/api/lusid/schemas/VendorModelRule', '688'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Version',
                component: ComponentCreator('/docs/api/lusid/schemas/Version', 'ed2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/VersionedResourceListOfA2BDataRecord',
                component: ComponentCreator('/docs/api/lusid/schemas/VersionedResourceListOfA2BDataRecord', '3d9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/VersionedResourceListOfA2BMovementRecord',
                component: ComponentCreator('/docs/api/lusid/schemas/VersionedResourceListOfA2BMovementRecord', '371'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/VersionedResourceListOfHoldingContributor',
                component: ComponentCreator('/docs/api/lusid/schemas/VersionedResourceListOfHoldingContributor', 'da8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/VersionedResourceListOfJournalEntryLine',
                component: ComponentCreator('/docs/api/lusid/schemas/VersionedResourceListOfJournalEntryLine', '384'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/VersionedResourceListOfOutputTransaction',
                component: ComponentCreator('/docs/api/lusid/schemas/VersionedResourceListOfOutputTransaction', '7aa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/VersionedResourceListOfPortfolioHolding',
                component: ComponentCreator('/docs/api/lusid/schemas/VersionedResourceListOfPortfolioHolding', '188'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/VersionedResourceListOfTransaction',
                component: ComponentCreator('/docs/api/lusid/schemas/VersionedResourceListOfTransaction', '618'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/VersionedResourceListOfTrialBalance',
                component: ComponentCreator('/docs/api/lusid/schemas/VersionedResourceListOfTrialBalance', 'e00'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/VersionedResourceListWithWarningsOfPortfolioHolding',
                component: ComponentCreator('/docs/api/lusid/schemas/VersionedResourceListWithWarningsOfPortfolioHolding', 'e8a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/VersionSummaryDto',
                component: ComponentCreator('/docs/api/lusid/schemas/VersionSummaryDto', 'ff0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/VirtualDocument',
                component: ComponentCreator('/docs/api/lusid/schemas/VirtualDocument', '819'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/VirtualDocumentRow',
                component: ComponentCreator('/docs/api/lusid/schemas/VirtualDocumentRow', '7cf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/VirtualRow',
                component: ComponentCreator('/docs/api/lusid/schemas/VirtualRow', 'fff'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Warning',
                component: ComponentCreator('/docs/api/lusid/schemas/Warning', '6eb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/WeekendMask',
                component: ComponentCreator('/docs/api/lusid/schemas/WeekendMask', '770'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/WeightedInstrument',
                component: ComponentCreator('/docs/api/lusid/schemas/WeightedInstrument', 'd1c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/WeightedInstruments',
                component: ComponentCreator('/docs/api/lusid/schemas/WeightedInstruments', '01b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/Workspace',
                component: ComponentCreator('/docs/api/lusid/schemas/Workspace', 'c10'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/WorkspaceCreationRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/WorkspaceCreationRequest', '408'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/WorkspaceItem',
                component: ComponentCreator('/docs/api/lusid/schemas/WorkspaceItem', 'c4d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/WorkspaceItemCreationRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/WorkspaceItemCreationRequest', '407'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/WorkspaceItemUpdateRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/WorkspaceItemUpdateRequest', 'e25'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/WorkspaceUpdateRequest',
                component: ComponentCreator('/docs/api/lusid/schemas/WorkspaceUpdateRequest', '4d0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/lusid/schemas/YieldCurveData',
                component: ComponentCreator('/docs/api/lusid/schemas/YieldCurveData', 'b30'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/endpoints/application-metadata/ListAccessControlledResources',
                component: ComponentCreator('/docs/api/notification/endpoints/application-metadata/ListAccessControlledResources', '23c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/endpoints/deliveries/ListDeliveries',
                component: ComponentCreator('/docs/api/notification/endpoints/deliveries/ListDeliveries', '14d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/endpoints/event-types/GetEventType',
                component: ComponentCreator('/docs/api/notification/endpoints/event-types/GetEventType', '348'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/endpoints/event-types/ListEventTypes',
                component: ComponentCreator('/docs/api/notification/endpoints/event-types/ListEventTypes', 'c2a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/endpoints/manual-event/TriggerManualEvent',
                component: ComponentCreator('/docs/api/notification/endpoints/manual-event/TriggerManualEvent', '8e9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/endpoints/notifications/CreateNotification',
                component: ComponentCreator('/docs/api/notification/endpoints/notifications/CreateNotification', '136'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/endpoints/notifications/DeleteNotification',
                component: ComponentCreator('/docs/api/notification/endpoints/notifications/DeleteNotification', 'c91'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/endpoints/notifications/GetNotification',
                component: ComponentCreator('/docs/api/notification/endpoints/notifications/GetNotification', 'e56'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/endpoints/notifications/ListNotifications',
                component: ComponentCreator('/docs/api/notification/endpoints/notifications/ListNotifications', '6d9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/endpoints/notifications/UpdateNotification',
                component: ComponentCreator('/docs/api/notification/endpoints/notifications/UpdateNotification', '69b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/endpoints/subscriptions/CreateSubscription',
                component: ComponentCreator('/docs/api/notification/endpoints/subscriptions/CreateSubscription', 'a2a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/endpoints/subscriptions/DeleteSubscription',
                component: ComponentCreator('/docs/api/notification/endpoints/subscriptions/DeleteSubscription', 'caa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/endpoints/subscriptions/GetSubscription',
                component: ComponentCreator('/docs/api/notification/endpoints/subscriptions/GetSubscription', 'c0a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/endpoints/subscriptions/ListSubscriptions',
                component: ComponentCreator('/docs/api/notification/endpoints/subscriptions/ListSubscriptions', 'fa1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/endpoints/subscriptions/UpdateSubscription',
                component: ComponentCreator('/docs/api/notification/endpoints/subscriptions/UpdateSubscription', 'c00'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/error-codes',
                component: ComponentCreator('/docs/api/notification/error-codes', 'c11'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/intro',
                component: ComponentCreator('/docs/api/notification/intro', 'd64'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/AccessControlledAction',
                component: ComponentCreator('/docs/api/notification/schemas/AccessControlledAction', '4ef'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/AccessControlledResource',
                component: ComponentCreator('/docs/api/notification/schemas/AccessControlledResource', '642'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/ActionId',
                component: ComponentCreator('/docs/api/notification/schemas/ActionId', 'a77'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/AmazonSqsNotificationType',
                component: ComponentCreator('/docs/api/notification/schemas/AmazonSqsNotificationType', 'e38'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/AmazonSqsNotificationTypeResponse',
                component: ComponentCreator('/docs/api/notification/schemas/AmazonSqsNotificationTypeResponse', '8b9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/AmazonSqsPrincipalAuthNotificationType',
                component: ComponentCreator('/docs/api/notification/schemas/AmazonSqsPrincipalAuthNotificationType', 'a61'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/AmazonSqsPrincipalAuthNotificationTypeResponse',
                component: ComponentCreator('/docs/api/notification/schemas/AmazonSqsPrincipalAuthNotificationTypeResponse', '982'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/Attempt',
                component: ComponentCreator('/docs/api/notification/schemas/Attempt', '5bc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/AttemptStatus',
                component: ComponentCreator('/docs/api/notification/schemas/AttemptStatus', 'b9a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/AzureServiceBusNotificationType',
                component: ComponentCreator('/docs/api/notification/schemas/AzureServiceBusNotificationType', 'efa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/AzureServiceBusTypeResponse',
                component: ComponentCreator('/docs/api/notification/schemas/AzureServiceBusTypeResponse', '0b7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/CreateNotificationRequest',
                component: ComponentCreator('/docs/api/notification/schemas/CreateNotificationRequest', '293'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/CreateSubscription',
                component: ComponentCreator('/docs/api/notification/schemas/CreateSubscription', '861'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/Delivery',
                component: ComponentCreator('/docs/api/notification/schemas/Delivery', '05f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/EmailNotificationType',
                component: ComponentCreator('/docs/api/notification/schemas/EmailNotificationType', '388'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/EmailNotificationTypeResponse',
                component: ComponentCreator('/docs/api/notification/schemas/EmailNotificationTypeResponse', 'f1f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/EventFieldDefinition',
                component: ComponentCreator('/docs/api/notification/schemas/EventFieldDefinition', 'abd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/EventTypeSchema',
                component: ComponentCreator('/docs/api/notification/schemas/EventTypeSchema', '59e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/IdentifierPartSchema',
                component: ComponentCreator('/docs/api/notification/schemas/IdentifierPartSchema', 'dee'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/IdSelectorDefinition',
                component: ComponentCreator('/docs/api/notification/schemas/IdSelectorDefinition', 'a6f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/Link',
                component: ComponentCreator('/docs/api/notification/schemas/Link', '3a8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/LusidProblemDetails',
                component: ComponentCreator('/docs/api/notification/schemas/LusidProblemDetails', '986'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/LusidValidationProblemDetails',
                component: ComponentCreator('/docs/api/notification/schemas/LusidValidationProblemDetails', 'af3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/ManualEvent',
                component: ComponentCreator('/docs/api/notification/schemas/ManualEvent', 'b85'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/ManualEventBody',
                component: ComponentCreator('/docs/api/notification/schemas/ManualEventBody', '886'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/ManualEventHeader',
                component: ComponentCreator('/docs/api/notification/schemas/ManualEventHeader', 'd3d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/ManualEventRequest',
                component: ComponentCreator('/docs/api/notification/schemas/ManualEventRequest', '16a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/MatchingPattern',
                component: ComponentCreator('/docs/api/notification/schemas/MatchingPattern', '3ab'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/Notification',
                component: ComponentCreator('/docs/api/notification/schemas/Notification', '5a6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/NotificationType',
                component: ComponentCreator('/docs/api/notification/schemas/NotificationType', '713'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/NotificationTypeResponse',
                component: ComponentCreator('/docs/api/notification/schemas/NotificationTypeResponse', '64b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/ResourceId',
                component: ComponentCreator('/docs/api/notification/schemas/ResourceId', '267'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/ResourceListOfAccessControlledResource',
                component: ComponentCreator('/docs/api/notification/schemas/ResourceListOfAccessControlledResource', '7cd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/ResourceListOfDelivery',
                component: ComponentCreator('/docs/api/notification/schemas/ResourceListOfDelivery', 'b53'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/ResourceListOfEventTypeSchema',
                component: ComponentCreator('/docs/api/notification/schemas/ResourceListOfEventTypeSchema', '546'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/ResourceListOfNotification',
                component: ComponentCreator('/docs/api/notification/schemas/ResourceListOfNotification', '06a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/ResourceListOfSubscription',
                component: ComponentCreator('/docs/api/notification/schemas/ResourceListOfSubscription', '038'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/SmsNotificationType',
                component: ComponentCreator('/docs/api/notification/schemas/SmsNotificationType', '50f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/SmsNotificationTypeResponse',
                component: ComponentCreator('/docs/api/notification/schemas/SmsNotificationTypeResponse', '5f0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/Subscription',
                component: ComponentCreator('/docs/api/notification/schemas/Subscription', 'a1d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/UpdateNotificationRequest',
                component: ComponentCreator('/docs/api/notification/schemas/UpdateNotificationRequest', '634'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/UpdateSubscription',
                component: ComponentCreator('/docs/api/notification/schemas/UpdateSubscription', 'cef'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/WebhookNotificationType',
                component: ComponentCreator('/docs/api/notification/schemas/WebhookNotificationType', '965'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/notification/schemas/WebhookNotificationTypeResponse',
                component: ComponentCreator('/docs/api/notification/schemas/WebhookNotificationTypeResponse', 'ef9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/application-metadata/ListAccessControlledResources',
                component: ComponentCreator('/docs/api/scheduler/endpoints/application-metadata/ListAccessControlledResources', '1b0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/images/DeleteImage',
                component: ComponentCreator('/docs/api/scheduler/endpoints/images/DeleteImage', 'e85'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/images/DownloadImage',
                component: ComponentCreator('/docs/api/scheduler/endpoints/images/DownloadImage', 'b54'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/images/GetImage',
                component: ComponentCreator('/docs/api/scheduler/endpoints/images/GetImage', '92d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/images/ListImages',
                component: ComponentCreator('/docs/api/scheduler/endpoints/images/ListImages', '1ed'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/images/ListRepositories',
                component: ComponentCreator('/docs/api/scheduler/endpoints/images/ListRepositories', '5aa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/images/UploadImage',
                component: ComponentCreator('/docs/api/scheduler/endpoints/images/UploadImage', '6b4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/jobs/CreateJob',
                component: ComponentCreator('/docs/api/scheduler/endpoints/jobs/CreateJob', '74b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/jobs/DeleteJob',
                component: ComponentCreator('/docs/api/scheduler/endpoints/jobs/DeleteJob', '3f5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/jobs/GetHistory',
                component: ComponentCreator('/docs/api/scheduler/endpoints/jobs/GetHistory', 'f20'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/jobs/GetJobConsoleOutput',
                component: ComponentCreator('/docs/api/scheduler/endpoints/jobs/GetJobConsoleOutput', '523'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/jobs/GetRunHistory',
                component: ComponentCreator('/docs/api/scheduler/endpoints/jobs/GetRunHistory', '614'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/jobs/GetSchedulesForAJob',
                component: ComponentCreator('/docs/api/scheduler/endpoints/jobs/GetSchedulesForAJob', '62a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/jobs/ListJobs',
                component: ComponentCreator('/docs/api/scheduler/endpoints/jobs/ListJobs', 'ccf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/jobs/RunJob',
                component: ComponentCreator('/docs/api/scheduler/endpoints/jobs/RunJob', 'ada'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/jobs/UpdateJob',
                component: ComponentCreator('/docs/api/scheduler/endpoints/jobs/UpdateJob', 'dc4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/schedules/CreateSchedule',
                component: ComponentCreator('/docs/api/scheduler/endpoints/schedules/CreateSchedule', '8f5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/schedules/DeleteSchedule',
                component: ComponentCreator('/docs/api/scheduler/endpoints/schedules/DeleteSchedule', '682'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/schedules/EnabledSchedule',
                component: ComponentCreator('/docs/api/scheduler/endpoints/schedules/EnabledSchedule', '73c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/schedules/GetSchedule',
                component: ComponentCreator('/docs/api/scheduler/endpoints/schedules/GetSchedule', '0d7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/schedules/GetValidTimezones',
                component: ComponentCreator('/docs/api/scheduler/endpoints/schedules/GetValidTimezones', '5ce'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/schedules/ListSchedules',
                component: ComponentCreator('/docs/api/scheduler/endpoints/schedules/ListSchedules', 'b99'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/schedules/RunSchedule',
                component: ComponentCreator('/docs/api/scheduler/endpoints/schedules/RunSchedule', 'd63'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/endpoints/schedules/UpdateSchedule',
                component: ComponentCreator('/docs/api/scheduler/endpoints/schedules/UpdateSchedule', 'bdd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/error-codes',
                component: ComponentCreator('/docs/api/scheduler/error-codes', 'fc6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/intro',
                component: ComponentCreator('/docs/api/scheduler/intro', 'ad6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/AccessControlledAction',
                component: ComponentCreator('/docs/api/scheduler/schemas/AccessControlledAction', '24f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/AccessControlledResource',
                component: ComponentCreator('/docs/api/scheduler/schemas/AccessControlledResource', '0d1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/ActionId',
                component: ComponentCreator('/docs/api/scheduler/schemas/ActionId', '258'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/ArgumentDefinition',
                component: ComponentCreator('/docs/api/scheduler/schemas/ArgumentDefinition', '6d6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/CreateJobRequest',
                component: ComponentCreator('/docs/api/scheduler/schemas/CreateJobRequest', 'd53'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/CreateScheduleRequest',
                component: ComponentCreator('/docs/api/scheduler/schemas/CreateScheduleRequest', '6cb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/IdentifierPartSchema',
                component: ComponentCreator('/docs/api/scheduler/schemas/IdentifierPartSchema', '99e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/IdSelectorDefinition',
                component: ComponentCreator('/docs/api/scheduler/schemas/IdSelectorDefinition', 'cb7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/Image',
                component: ComponentCreator('/docs/api/scheduler/schemas/Image', '81d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/ImageSummary',
                component: ComponentCreator('/docs/api/scheduler/schemas/ImageSummary', '580'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/JobDefinition',
                component: ComponentCreator('/docs/api/scheduler/schemas/JobDefinition', '3ec'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/JobHistory',
                component: ComponentCreator('/docs/api/scheduler/schemas/JobHistory', 'b22'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/JobRunResult',
                component: ComponentCreator('/docs/api/scheduler/schemas/JobRunResult', '83a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/Link',
                component: ComponentCreator('/docs/api/scheduler/schemas/Link', 'e81'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/LusidProblemDetails',
                component: ComponentCreator('/docs/api/scheduler/schemas/LusidProblemDetails', '0af'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/LusidValidationProblemDetails',
                component: ComponentCreator('/docs/api/scheduler/schemas/LusidValidationProblemDetails', '940'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/Notification',
                component: ComponentCreator('/docs/api/scheduler/schemas/Notification', '145'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/Repository',
                component: ComponentCreator('/docs/api/scheduler/schemas/Repository', '2b5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/RequiredResources',
                component: ComponentCreator('/docs/api/scheduler/schemas/RequiredResources', '807'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/ResourceId',
                component: ComponentCreator('/docs/api/scheduler/schemas/ResourceId', 'c2f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/ResourceListOfAccessControlledResource',
                component: ComponentCreator('/docs/api/scheduler/schemas/ResourceListOfAccessControlledResource', 'eb1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/ResourceListOfImageSummary',
                component: ComponentCreator('/docs/api/scheduler/schemas/ResourceListOfImageSummary', 'ae3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/ResourceListOfJobDefinition',
                component: ComponentCreator('/docs/api/scheduler/schemas/ResourceListOfJobDefinition', '99f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/ResourceListOfJobHistory',
                component: ComponentCreator('/docs/api/scheduler/schemas/ResourceListOfJobHistory', '8c4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/ResourceListOfRepository',
                component: ComponentCreator('/docs/api/scheduler/schemas/ResourceListOfRepository', '63e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/ResourceListOfScheduleDefinition',
                component: ComponentCreator('/docs/api/scheduler/schemas/ResourceListOfScheduleDefinition', '1e2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/ResourceListOfString',
                component: ComponentCreator('/docs/api/scheduler/schemas/ResourceListOfString', '03e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/ScanReport',
                component: ComponentCreator('/docs/api/scheduler/schemas/ScanReport', '120'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/ScanSummary',
                component: ComponentCreator('/docs/api/scheduler/schemas/ScanSummary', 'abd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/ScheduleDefinition',
                component: ComponentCreator('/docs/api/scheduler/schemas/ScheduleDefinition', '0df'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/StartJobRequest',
                component: ComponentCreator('/docs/api/scheduler/schemas/StartJobRequest', '78a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/StartJobResponse',
                component: ComponentCreator('/docs/api/scheduler/schemas/StartJobResponse', '5bf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/StartScheduleResponse',
                component: ComponentCreator('/docs/api/scheduler/schemas/StartScheduleResponse', 'bcb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/Tag',
                component: ComponentCreator('/docs/api/scheduler/schemas/Tag', '631'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/TimeTrigger',
                component: ComponentCreator('/docs/api/scheduler/schemas/TimeTrigger', 'a3b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/Trigger',
                component: ComponentCreator('/docs/api/scheduler/schemas/Trigger', '2e6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/UpdateJobRequest',
                component: ComponentCreator('/docs/api/scheduler/schemas/UpdateJobRequest', 'd08'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/UpdateScheduleRequest',
                component: ComponentCreator('/docs/api/scheduler/schemas/UpdateScheduleRequest', '5c6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/UploadImageInstructions',
                component: ComponentCreator('/docs/api/scheduler/schemas/UploadImageInstructions', 'aef'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/UploadImageRequest',
                component: ComponentCreator('/docs/api/scheduler/schemas/UploadImageRequest', 'c8d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/scheduler/schemas/Vulnerability',
                component: ComponentCreator('/docs/api/scheduler/schemas/Vulnerability', '5bf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/config/GetAppConfig',
                component: ComponentCreator('/docs/api/web-app/endpoints/config/GetAppConfig', '61a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/config/GetVersion',
                component: ComponentCreator('/docs/api/web-app/endpoints/config/GetVersion', '233'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/global-search/GlobalSearch.ListGlobalSearchInstruments',
                component: ComponentCreator('/docs/api/web-app/endpoints/global-search/GlobalSearch.ListGlobalSearchInstruments', '86d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/global-search/GlobalSearch.ListGlobalSearchTransactions',
                component: ComponentCreator('/docs/api/web-app/endpoints/global-search/GlobalSearch.ListGlobalSearchTransactions', '1e0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/logging/Logging.CreateLogMessage',
                component: ComponentCreator('/docs/api/web-app/endpoints/logging/Logging.CreateLogMessage', '1be'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/order-blotter/OrderBlotter.ListBlockJobs',
                component: ComponentCreator('/docs/api/web-app/endpoints/order-blotter/OrderBlotter.ListBlockJobs', '6e6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/order-blotter/OrderBlotter.RunBlockJob',
                component: ComponentCreator('/docs/api/web-app/endpoints/order-blotter/OrderBlotter.RunBlockJob', '7a4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/personalisations/Personalisations.DeletePersonalisation',
                component: ComponentCreator('/docs/api/web-app/endpoints/personalisations/Personalisations.DeletePersonalisation', 'da2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/personalisations/Personalisations.GetPersonalisations',
                component: ComponentCreator('/docs/api/web-app/endpoints/personalisations/Personalisations.GetPersonalisations', '9f0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/personalisations/Personalisations.GetPersonalisationsKeys',
                component: ComponentCreator('/docs/api/web-app/endpoints/personalisations/Personalisations.GetPersonalisationsKeys', '975'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/personalisations/Personalisations.GetPersonalisationsSharedKeys',
                component: ComponentCreator('/docs/api/web-app/endpoints/personalisations/Personalisations.GetPersonalisationsSharedKeys', '51c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/personalisations/Personalisations.SharePersonalisations',
                component: ComponentCreator('/docs/api/web-app/endpoints/personalisations/Personalisations.SharePersonalisations', '8b3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/personalisations/Personalisations.UpsertPersonalisations',
                component: ComponentCreator('/docs/api/web-app/endpoints/personalisations/Personalisations.UpsertPersonalisations', 'fd3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/rebalance/Rebalance.CreateRebalanceConfiguration',
                component: ComponentCreator('/docs/api/web-app/endpoints/rebalance/Rebalance.CreateRebalanceConfiguration', '60e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/rebalance/Rebalance.DeleteRebalanceConfiguration',
                component: ComponentCreator('/docs/api/web-app/endpoints/rebalance/Rebalance.DeleteRebalanceConfiguration', 'b1f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/rebalance/Rebalance.DeleteRebalancePortfolioMapping',
                component: ComponentCreator('/docs/api/web-app/endpoints/rebalance/Rebalance.DeleteRebalancePortfolioMapping', '10f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/rebalance/Rebalance.GetOrderInstructionsById',
                component: ComponentCreator('/docs/api/web-app/endpoints/rebalance/Rebalance.GetOrderInstructionsById', '71e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/rebalance/Rebalance.GetRebalancePortfolioMapping',
                component: ComponentCreator('/docs/api/web-app/endpoints/rebalance/Rebalance.GetRebalancePortfolioMapping', '343'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/rebalance/Rebalance.ListRebalanceAlgorithms',
                component: ComponentCreator('/docs/api/web-app/endpoints/rebalance/Rebalance.ListRebalanceAlgorithms', '2ab'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/rebalance/Rebalance.ListRebalanceConfigurations',
                component: ComponentCreator('/docs/api/web-app/endpoints/rebalance/Rebalance.ListRebalanceConfigurations', 'a98'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/rebalance/Rebalance.ListRebalancePortfolioMappings',
                component: ComponentCreator('/docs/api/web-app/endpoints/rebalance/Rebalance.ListRebalancePortfolioMappings', 'f68'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/rebalance/Rebalance.RunRebalanceAlgorithm',
                component: ComponentCreator('/docs/api/web-app/endpoints/rebalance/Rebalance.RunRebalanceAlgorithm', '59e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/rebalance/Rebalance.ShareRebalanceConfiguration',
                component: ComponentCreator('/docs/api/web-app/endpoints/rebalance/Rebalance.ShareRebalanceConfiguration', '47a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/rebalance/Rebalance.UnshareRebalanceConfiguration',
                component: ComponentCreator('/docs/api/web-app/endpoints/rebalance/Rebalance.UnshareRebalanceConfiguration', '789'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/rebalance/Rebalance.UpdateRebalanceConfiguration',
                component: ComponentCreator('/docs/api/web-app/endpoints/rebalance/Rebalance.UpdateRebalanceConfiguration', '5f8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/rebalance/Rebalance.UpsertRebalancePortfolioMapping',
                component: ComponentCreator('/docs/api/web-app/endpoints/rebalance/Rebalance.UpsertRebalancePortfolioMapping', 'b1b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/reports/GetDashboards',
                component: ComponentCreator('/docs/api/web-app/endpoints/reports/GetDashboards', '903'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/reports/GetSecurityToken',
                component: ComponentCreator('/docs/api/web-app/endpoints/reports/GetSecurityToken', '317'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/reports/ShareDashboard',
                component: ComponentCreator('/docs/api/web-app/endpoints/reports/ShareDashboard', '89a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/reports/UnshareDashboard',
                component: ComponentCreator('/docs/api/web-app/endpoints/reports/UnshareDashboard', '711'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/roadmap/Roadmap.GetRoadmap',
                component: ComponentCreator('/docs/api/web-app/endpoints/roadmap/Roadmap.GetRoadmap', 'dd9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/roadmap/Roadmap.GetRoadmapHistory',
                component: ComponentCreator('/docs/api/web-app/endpoints/roadmap/Roadmap.GetRoadmapHistory', 'e80'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/system-defaults/SystemDefaults.ListSystemDefaultsConfigItems',
                component: ComponentCreator('/docs/api/web-app/endpoints/system-defaults/SystemDefaults.ListSystemDefaultsConfigItems', '8c9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/tickets/Tickets.CloseTicket',
                component: ComponentCreator('/docs/api/web-app/endpoints/tickets/Tickets.CloseTicket', 'c57'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/tickets/Tickets.CreateTicket',
                component: ComponentCreator('/docs/api/web-app/endpoints/tickets/Tickets.CreateTicket', '22e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/tickets/Tickets.GetPriorityCodes',
                component: ComponentCreator('/docs/api/web-app/endpoints/tickets/Tickets.GetPriorityCodes', 'd90'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/tickets/Tickets.GetStatuses',
                component: ComponentCreator('/docs/api/web-app/endpoints/tickets/Tickets.GetStatuses', '604'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/tickets/Tickets.GetTickets',
                component: ComponentCreator('/docs/api/web-app/endpoints/tickets/Tickets.GetTickets', '0ac'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/tickets/Tickets.UpdateTicket',
                component: ComponentCreator('/docs/api/web-app/endpoints/tickets/Tickets.UpdateTicket', 'b16'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/valuations/Valuations.ListValuationManifest',
                component: ComponentCreator('/docs/api/web-app/endpoints/valuations/Valuations.ListValuationManifest', '826'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/endpoints/views/Views.DeleteSharedView',
                component: ComponentCreator('/docs/api/web-app/endpoints/views/Views.DeleteSharedView', '604'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/intro',
                component: ComponentCreator('/docs/api/web-app/intro', '38c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/AbsoluteRule',
                component: ComponentCreator('/docs/api/web-app/schemas/AbsoluteRule', '2e8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/AbsoluteRuleInstrument',
                component: ComponentCreator('/docs/api/web-app/schemas/AbsoluteRuleInstrument', '88b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/AbsoluteRules',
                component: ComponentCreator('/docs/api/web-app/schemas/AbsoluteRules', '0b8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/AggregateSpec',
                component: ComponentCreator('/docs/api/web-app/schemas/AggregateSpec', '076'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/AggregationOp',
                component: ComponentCreator('/docs/api/web-app/schemas/AggregationOp', 'd83'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/AlgorithmMetadata',
                component: ComponentCreator('/docs/api/web-app/schemas/AlgorithmMetadata', 'f62'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/CashEquitisation',
                component: ComponentCreator('/docs/api/web-app/schemas/CashEquitisation', '835'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/CashEquitisationDetails',
                component: ComponentCreator('/docs/api/web-app/schemas/CashEquitisationDetails', '387'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/CashflowsItems',
                component: ComponentCreator('/docs/api/web-app/schemas/CashflowsItems', '6a6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/CashRule',
                component: ComponentCreator('/docs/api/web-app/schemas/CashRule', '792'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/CashRules',
                component: ComponentCreator('/docs/api/web-app/schemas/CashRules', 'c57'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/CashRuleType',
                component: ComponentCreator('/docs/api/web-app/schemas/CashRuleType', 'be6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/ClientConfig',
                component: ComponentCreator('/docs/api/web-app/schemas/ClientConfig', '006'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/ColumnFormatDateTime',
                component: ComponentCreator('/docs/api/web-app/schemas/ColumnFormatDateTime', 'fce'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/ColumnFormatFractionDigits',
                component: ComponentCreator('/docs/api/web-app/schemas/ColumnFormatFractionDigits', 'ef9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/ColumnFormatNegativeNumber',
                component: ComponentCreator('/docs/api/web-app/schemas/ColumnFormatNegativeNumber', '4fe'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/Contact',
                component: ComponentCreator('/docs/api/web-app/schemas/Contact', '633'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/ContactPropertiesRequest',
                component: ComponentCreator('/docs/api/web-app/schemas/ContactPropertiesRequest', '45c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/CorporateActionsItems',
                component: ComponentCreator('/docs/api/web-app/schemas/CorporateActionsItems', 'e60'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/CreateDomainRequest',
                component: ComponentCreator('/docs/api/web-app/schemas/CreateDomainRequest', '78d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/CustomValue',
                component: ComponentCreator('/docs/api/web-app/schemas/CustomValue', '4bb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/CustomValueProperty',
                component: ComponentCreator('/docs/api/web-app/schemas/CustomValueProperty', 'ef5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/CustomValues',
                component: ComponentCreator('/docs/api/web-app/schemas/CustomValues', '500'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/Dashboard',
                component: ComponentCreator('/docs/api/web-app/schemas/Dashboard', 'd9f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/DataVirtualisationItems',
                component: ComponentCreator('/docs/api/web-app/schemas/DataVirtualisationItems', '7ed'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/DeleteSharedViewRequest',
                component: ComponentCreator('/docs/api/web-app/schemas/DeleteSharedViewRequest', '51e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/EntityId',
                component: ComponentCreator('/docs/api/web-app/schemas/EntityId', '17e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/FractionalRule',
                component: ComponentCreator('/docs/api/web-app/schemas/FractionalRule', '928'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/FractionalRuleValue',
                component: ComponentCreator('/docs/api/web-app/schemas/FractionalRuleValue', '4ff'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/FxMap',
                component: ComponentCreator('/docs/api/web-app/schemas/FxMap', '002'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/FxMapDetails',
                component: ComponentCreator('/docs/api/web-app/schemas/FxMapDetails', 'd14'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/General',
                component: ComponentCreator('/docs/api/web-app/schemas/General', '360'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/GetDashboardsResponse',
                component: ComponentCreator('/docs/api/web-app/schemas/GetDashboardsResponse', '0ba'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/GetOrderInstructionsByIdRequest',
                component: ComponentCreator('/docs/api/web-app/schemas/GetOrderInstructionsByIdRequest', 'e44'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/GetSecurityTokenResponse',
                component: ComponentCreator('/docs/api/web-app/schemas/GetSecurityTokenResponse', '5c5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/GlobalSearchInstrumentParameters',
                component: ComponentCreator('/docs/api/web-app/schemas/GlobalSearchInstrumentParameters', '17a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/GlobalSearchInstrumentResponse',
                component: ComponentCreator('/docs/api/web-app/schemas/GlobalSearchInstrumentResponse', '9a6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/GlobalSearchTransactionParameters',
                component: ComponentCreator('/docs/api/web-app/schemas/GlobalSearchTransactionParameters', 'a3b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/GlobalSearchTransactionResponse',
                component: ComponentCreator('/docs/api/web-app/schemas/GlobalSearchTransactionResponse', '580'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/GroupingLevel',
                component: ComponentCreator('/docs/api/web-app/schemas/GroupingLevel', '13f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/HedgingPolicyType',
                component: ComponentCreator('/docs/api/web-app/schemas/HedgingPolicyType', 'f0b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/HubspotContact',
                component: ComponentCreator('/docs/api/web-app/schemas/HubspotContact', 'c72'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/HubspotIdentity',
                component: ComponentCreator('/docs/api/web-app/schemas/HubspotIdentity', '99b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/HubspotIdentityProfile',
                component: ComponentCreator('/docs/api/web-app/schemas/HubspotIdentityProfile', 'f07'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/HubspotResponseProperty',
                component: ComponentCreator('/docs/api/web-app/schemas/HubspotResponseProperty', '45c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/HubspotResponsePropertyVersion',
                component: ComponentCreator('/docs/api/web-app/schemas/HubspotResponsePropertyVersion', 'd5f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/HubspotSupportArticle',
                component: ComponentCreator('/docs/api/web-app/schemas/HubspotSupportArticle', '1dc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/HubspotSupportArticleList',
                component: ComponentCreator('/docs/api/web-app/schemas/HubspotSupportArticleList', '72b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/InstrumentIdType',
                component: ComponentCreator('/docs/api/web-app/schemas/InstrumentIdType', '767'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/InstrumentsItems',
                component: ComponentCreator('/docs/api/web-app/schemas/InstrumentsItems', '764'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/LabelValueSet',
                component: ComponentCreator('/docs/api/web-app/schemas/LabelValueSet', 'ff8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/Link',
                component: ComponentCreator('/docs/api/web-app/schemas/Link', 'aa9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/LinkType',
                component: ComponentCreator('/docs/api/web-app/schemas/LinkType', '5a9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/LogLevel',
                component: ComponentCreator('/docs/api/web-app/schemas/LogLevel', 'f0d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/LuminesceQueryData',
                component: ComponentCreator('/docs/api/web-app/schemas/LuminesceQueryData', '481'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/LusidProblemDetails',
                component: ComponentCreator('/docs/api/web-app/schemas/LusidProblemDetails', '15d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/LusidValidationProblemDetails',
                component: ComponentCreator('/docs/api/web-app/schemas/LusidValidationProblemDetails', '45f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/MetricValue',
                component: ComponentCreator('/docs/api/web-app/schemas/MetricValue', '267'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/Mixpanel',
                component: ComponentCreator('/docs/api/web-app/schemas/Mixpanel', '3c0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/OktaConfig',
                component: ComponentCreator('/docs/api/web-app/schemas/OktaConfig', 'd32'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/OptionSetItem',
                component: ComponentCreator('/docs/api/web-app/schemas/OptionSetItem', 'a08'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/OrderInstructionSummary',
                component: ComponentCreator('/docs/api/web-app/schemas/OrderInstructionSummary', 'bf0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/OrderManagementItems',
                component: ComponentCreator('/docs/api/web-app/schemas/OrderManagementItems', 'a73'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/OrderTimeInForce',
                component: ComponentCreator('/docs/api/web-app/schemas/OrderTimeInForce', 'd18'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/OrderTransactionTypeConfigurationItems',
                component: ComponentCreator('/docs/api/web-app/schemas/OrderTransactionTypeConfigurationItems', 'aa6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/OrderType',
                component: ComponentCreator('/docs/api/web-app/schemas/OrderType', '15d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/Personalisation',
                component: ComponentCreator('/docs/api/web-app/schemas/Personalisation', 'e0f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/PersonalisationScope',
                component: ComponentCreator('/docs/api/web-app/schemas/PersonalisationScope', '1e6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/PortfolioType',
                component: ComponentCreator('/docs/api/web-app/schemas/PortfolioType', '83f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/PropertySpec',
                component: ComponentCreator('/docs/api/web-app/schemas/PropertySpec', 'dee'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/PropertyValue',
                component: ComponentCreator('/docs/api/web-app/schemas/PropertyValue', '07f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/QuotesItems',
                component: ComponentCreator('/docs/api/web-app/schemas/QuotesItems', '70d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/RebalanceConfiguration',
                component: ComponentCreator('/docs/api/web-app/schemas/RebalanceConfiguration', 'c92'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/RebalanceConfigurationDetails',
                component: ComponentCreator('/docs/api/web-app/schemas/RebalanceConfigurationDetails', '521'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/RebalanceConfigurationItems',
                component: ComponentCreator('/docs/api/web-app/schemas/RebalanceConfigurationItems', 'a5a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/RebalanceFilter',
                component: ComponentCreator('/docs/api/web-app/schemas/RebalanceFilter', '226'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/RebalanceFilterItems',
                component: ComponentCreator('/docs/api/web-app/schemas/RebalanceFilterItems', 'c7f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/RebalancePortfolioMapping',
                component: ComponentCreator('/docs/api/web-app/schemas/RebalancePortfolioMapping', '779'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/RebalancePortfolioMappingDetails',
                component: ComponentCreator('/docs/api/web-app/schemas/RebalancePortfolioMappingDetails', '54b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/RebalanceRounding',
                component: ComponentCreator('/docs/api/web-app/schemas/RebalanceRounding', 'eb5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/ReCaptcha',
                component: ComponentCreator('/docs/api/web-app/schemas/ReCaptcha', 'baf'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/RecipesItems',
                component: ComponentCreator('/docs/api/web-app/schemas/RecipesItems', '7cd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/ResourceId',
                component: ComponentCreator('/docs/api/web-app/schemas/ResourceId', '814'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/ResourceList[RoadmapItem]',
                component: ComponentCreator('/docs/api/web-app/schemas/ResourceList[RoadmapItem]', '1ea'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/ResourceList[SharedPersonalisation]',
                component: ComponentCreator('/docs/api/web-app/schemas/ResourceList[SharedPersonalisation]', '9b1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/RoadmapItem',
                component: ComponentCreator('/docs/api/web-app/schemas/RoadmapItem', 'fd2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/RoadmapItemCategory',
                component: ComponentCreator('/docs/api/web-app/schemas/RoadmapItemCategory', '6ff'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/RoundingMethod',
                component: ComponentCreator('/docs/api/web-app/schemas/RoundingMethod', '4d4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/RoundingRules',
                component: ComponentCreator('/docs/api/web-app/schemas/RoundingRules', 'ae6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/RunBlockJobRequest',
                component: ComponentCreator('/docs/api/web-app/schemas/RunBlockJobRequest', '9d8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/RunBlockJobResponse',
                component: ComponentCreator('/docs/api/web-app/schemas/RunBlockJobResponse', 'f0d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/RunRebalanceAlgorithmRequest',
                component: ComponentCreator('/docs/api/web-app/schemas/RunRebalanceAlgorithmRequest', '7ca'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/RunRebalanceAlgorithmResponse',
                component: ComponentCreator('/docs/api/web-app/schemas/RunRebalanceAlgorithmResponse', 'bfb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/SdkOperatorEnum',
                component: ComponentCreator('/docs/api/web-app/schemas/SdkOperatorEnum', '0af'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/SdkPropertyFilter',
                component: ComponentCreator('/docs/api/web-app/schemas/SdkPropertyFilter', 'cab'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/SdkQuoteType',
                component: ComponentCreator('/docs/api/web-app/schemas/SdkQuoteType', '08e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/SdkResourceId',
                component: ComponentCreator('/docs/api/web-app/schemas/SdkResourceId', '68a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/SdkRightOperandTypeEnum',
                component: ComponentCreator('/docs/api/web-app/schemas/SdkRightOperandTypeEnum', 'f65'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/SharedPersonalisation',
                component: ComponentCreator('/docs/api/web-app/schemas/SharedPersonalisation', '04a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/SharePersonalisationsRequest',
                component: ComponentCreator('/docs/api/web-app/schemas/SharePersonalisationsRequest', '1ac'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/SystemDefaultsConfigItems',
                component: ComponentCreator('/docs/api/web-app/schemas/SystemDefaultsConfigItems', '7be'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/TableSettingsItems',
                component: ComponentCreator('/docs/api/web-app/schemas/TableSettingsItems', '8e9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/TargetWeight',
                component: ComponentCreator('/docs/api/web-app/schemas/TargetWeight', '5ee'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/TargetWeightDetails',
                component: ComponentCreator('/docs/api/web-app/schemas/TargetWeightDetails', '80a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/TaskizeConfig',
                component: ComponentCreator('/docs/api/web-app/schemas/TaskizeConfig', '69c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/Ticket',
                component: ComponentCreator('/docs/api/web-app/schemas/Ticket', '727'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/TicketBase',
                component: ComponentCreator('/docs/api/web-app/schemas/TicketBase', 'c25'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/TicketsResponse',
                component: ComponentCreator('/docs/api/web-app/schemas/TicketsResponse', '5fd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/Tolerance',
                component: ComponentCreator('/docs/api/web-app/schemas/Tolerance', '778'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/ToleranceRule',
                component: ComponentCreator('/docs/api/web-app/schemas/ToleranceRule', 'd8d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/ToleranceRuleBlock',
                component: ComponentCreator('/docs/api/web-app/schemas/ToleranceRuleBlock', '7e2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/ToleranceRules',
                component: ComponentCreator('/docs/api/web-app/schemas/ToleranceRules', '455'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/ToleranceRuleWithValue',
                component: ComponentCreator('/docs/api/web-app/schemas/ToleranceRuleWithValue', 'e0b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/TransactionsItems',
                component: ComponentCreator('/docs/api/web-app/schemas/TransactionsItems', '465'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/web-app/schemas/ValuationManifestResponse',
                component: ComponentCreator('/docs/api/web-app/schemas/ValuationManifestResponse', '715'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/action-logs/GetActionLogs',
                component: ComponentCreator('/docs/api/workflow/endpoints/action-logs/GetActionLogs', 'a7c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/application-metadata/ListAccessControlledResources',
                component: ComponentCreator('/docs/api/workflow/endpoints/application-metadata/ListAccessControlledResources', 'ff7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/event-handlers/CreateEventHandler',
                component: ComponentCreator('/docs/api/workflow/endpoints/event-handlers/CreateEventHandler', '463'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/event-handlers/DeleteEventHandler',
                component: ComponentCreator('/docs/api/workflow/endpoints/event-handlers/DeleteEventHandler', '916'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/event-handlers/GetEventHandler',
                component: ComponentCreator('/docs/api/workflow/endpoints/event-handlers/GetEventHandler', '99c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/event-handlers/ListEventHandlers',
                component: ComponentCreator('/docs/api/workflow/endpoints/event-handlers/ListEventHandlers', '143'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/event-handlers/UpdateEventHandler',
                component: ComponentCreator('/docs/api/workflow/endpoints/event-handlers/UpdateEventHandler', 'a22'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/task-definitions/CreateTaskDefinition',
                component: ComponentCreator('/docs/api/workflow/endpoints/task-definitions/CreateTaskDefinition', 'b58'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/task-definitions/DeleteTaskDefinition',
                component: ComponentCreator('/docs/api/workflow/endpoints/task-definitions/DeleteTaskDefinition', '475'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/task-definitions/GetTaskDefinition',
                component: ComponentCreator('/docs/api/workflow/endpoints/task-definitions/GetTaskDefinition', '0b5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/task-definitions/ListTaskDefinitions',
                component: ComponentCreator('/docs/api/workflow/endpoints/task-definitions/ListTaskDefinitions', '0d6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/task-definitions/ListTasksForTaskDefinition',
                component: ComponentCreator('/docs/api/workflow/endpoints/task-definitions/ListTasksForTaskDefinition', 'c8f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/task-definitions/UpdateTaskDefinition',
                component: ComponentCreator('/docs/api/workflow/endpoints/task-definitions/UpdateTaskDefinition', 'bd2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/tasks/CreateTask',
                component: ComponentCreator('/docs/api/workflow/endpoints/tasks/CreateTask', 'c0b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/tasks/DeleteTask',
                component: ComponentCreator('/docs/api/workflow/endpoints/tasks/DeleteTask', '057'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/tasks/DeleteTasks',
                component: ComponentCreator('/docs/api/workflow/endpoints/tasks/DeleteTasks', '776'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/tasks/GetTask',
                component: ComponentCreator('/docs/api/workflow/endpoints/tasks/GetTask', '5d7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/tasks/GetTaskHistory',
                component: ComponentCreator('/docs/api/workflow/endpoints/tasks/GetTaskHistory', '027'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/tasks/ListTasks',
                component: ComponentCreator('/docs/api/workflow/endpoints/tasks/ListTasks', '38d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/tasks/UpdateTask',
                component: ComponentCreator('/docs/api/workflow/endpoints/tasks/UpdateTask', 'd53'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/workers/CreateWorker',
                component: ComponentCreator('/docs/api/workflow/endpoints/workers/CreateWorker', 'b33'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/workers/DeleteWorker',
                component: ComponentCreator('/docs/api/workflow/endpoints/workers/DeleteWorker', '585'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/workers/GetWorker',
                component: ComponentCreator('/docs/api/workflow/endpoints/workers/GetWorker', '547'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/workers/GetWorkerResult',
                component: ComponentCreator('/docs/api/workflow/endpoints/workers/GetWorkerResult', 'b0b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/workers/ListWorkers',
                component: ComponentCreator('/docs/api/workflow/endpoints/workers/ListWorkers', '92c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/workers/RunWorker',
                component: ComponentCreator('/docs/api/workflow/endpoints/workers/RunWorker', '8e7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/endpoints/workers/UpdateWorker',
                component: ComponentCreator('/docs/api/workflow/endpoints/workers/UpdateWorker', 'a05'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/error-codes',
                component: ComponentCreator('/docs/api/workflow/error-codes', 'a9a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/intro',
                component: ComponentCreator('/docs/api/workflow/intro', '2ce'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/AccessControlledAction',
                component: ComponentCreator('/docs/api/workflow/schemas/AccessControlledAction', '579'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/AccessControlledResource',
                component: ComponentCreator('/docs/api/workflow/schemas/AccessControlledResource', '8aa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/ActionDefinition',
                component: ComponentCreator('/docs/api/workflow/schemas/ActionDefinition', '3fb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/ActionDefinitionResponse',
                component: ComponentCreator('/docs/api/workflow/schemas/ActionDefinitionResponse', 'b46'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/ActionDetails',
                component: ComponentCreator('/docs/api/workflow/schemas/ActionDetails', '817'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/ActionDetailsResponse',
                component: ComponentCreator('/docs/api/workflow/schemas/ActionDetailsResponse', '13e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/ActionId',
                component: ComponentCreator('/docs/api/workflow/schemas/ActionId', 'd83'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/ActionLog',
                component: ComponentCreator('/docs/api/workflow/schemas/ActionLog', 'fd1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/ActionLogItem',
                component: ComponentCreator('/docs/api/workflow/schemas/ActionLogItem', '61b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/ActionLogOrigin',
                component: ComponentCreator('/docs/api/workflow/schemas/ActionLogOrigin', '1ad'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/ChangeItem',
                component: ComponentCreator('/docs/api/workflow/schemas/ChangeItem', '9f0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/CreateChildTaskConfiguration',
                component: ComponentCreator('/docs/api/workflow/schemas/CreateChildTaskConfiguration', '27f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/CreateChildTasksAction',
                component: ComponentCreator('/docs/api/workflow/schemas/CreateChildTasksAction', '760'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/CreateChildTasksActionResponse',
                component: ComponentCreator('/docs/api/workflow/schemas/CreateChildTasksActionResponse', '90f'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/CreateEventHandlerRequest',
                component: ComponentCreator('/docs/api/workflow/schemas/CreateEventHandlerRequest', '6f3'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/CreateNewTaskActivity',
                component: ComponentCreator('/docs/api/workflow/schemas/CreateNewTaskActivity', 'd28'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/CreateNewTaskActivityResponse',
                component: ComponentCreator('/docs/api/workflow/schemas/CreateNewTaskActivityResponse', '771'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/CreateTaskDefinitionRequest',
                component: ComponentCreator('/docs/api/workflow/schemas/CreateTaskDefinitionRequest', '2fe'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/CreateTaskRequest',
                component: ComponentCreator('/docs/api/workflow/schemas/CreateTaskRequest', 'e90'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/CreateWorkerRequest',
                component: ComponentCreator('/docs/api/workflow/schemas/CreateWorkerRequest', '580'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/DeletedEntityResponse',
                component: ComponentCreator('/docs/api/workflow/schemas/DeletedEntityResponse', '783'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/DeleteTasksRequest',
                component: ComponentCreator('/docs/api/workflow/schemas/DeleteTasksRequest', 'a1b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/EventHandler',
                component: ComponentCreator('/docs/api/workflow/schemas/EventHandler', '31d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/EventHandlerMapping',
                component: ComponentCreator('/docs/api/workflow/schemas/EventHandlerMapping', 'f12'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/EventMatchingPattern',
                component: ComponentCreator('/docs/api/workflow/schemas/EventMatchingPattern', 'fe5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/Fail',
                component: ComponentCreator('/docs/api/workflow/schemas/Fail', 'faa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/FailResponse',
                component: ComponentCreator('/docs/api/workflow/schemas/FailResponse', '34d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/FieldMapping',
                component: ComponentCreator('/docs/api/workflow/schemas/FieldMapping', 'f85'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/GetWorkerResultResponse',
                component: ComponentCreator('/docs/api/workflow/schemas/GetWorkerResultResponse', 'fdc'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/GroupReconciliation',
                component: ComponentCreator('/docs/api/workflow/schemas/GroupReconciliation', '2fa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/GroupReconciliationResponse',
                component: ComponentCreator('/docs/api/workflow/schemas/GroupReconciliationResponse', 'ee5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/HealthCheck',
                component: ComponentCreator('/docs/api/workflow/schemas/HealthCheck', '526'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/HealthCheckResponse',
                component: ComponentCreator('/docs/api/workflow/schemas/HealthCheckResponse', '1b4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/IdentifierPartSchema',
                component: ComponentCreator('/docs/api/workflow/schemas/IdentifierPartSchema', '446'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/IdSelectorDefinition',
                component: ComponentCreator('/docs/api/workflow/schemas/IdSelectorDefinition', 'ec4'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/InitialState',
                component: ComponentCreator('/docs/api/workflow/schemas/InitialState', 'e6b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/Link',
                component: ComponentCreator('/docs/api/workflow/schemas/Link', 'e6b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/LuminesceView',
                component: ComponentCreator('/docs/api/workflow/schemas/LuminesceView', '077'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/LuminesceViewResponse',
                component: ComponentCreator('/docs/api/workflow/schemas/LuminesceViewResponse', '5a8'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/LusidProblemDetails',
                component: ComponentCreator('/docs/api/workflow/schemas/LusidProblemDetails', 'd6e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/LusidValidationProblemDetails',
                component: ComponentCreator('/docs/api/workflow/schemas/LusidValidationProblemDetails', 'c57'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/PagedResourceListOfEventHandler',
                component: ComponentCreator('/docs/api/workflow/schemas/PagedResourceListOfEventHandler', '986'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/PagedResourceListOfTask',
                component: ComponentCreator('/docs/api/workflow/schemas/PagedResourceListOfTask', 'd88'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/PagedResourceListOfTaskDefinition',
                component: ComponentCreator('/docs/api/workflow/schemas/PagedResourceListOfTaskDefinition', '561'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/PagedResourceListOfWorker',
                component: ComponentCreator('/docs/api/workflow/schemas/PagedResourceListOfWorker', '726'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/Parameter',
                component: ComponentCreator('/docs/api/workflow/schemas/Parameter', '6a1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/ParameterValue',
                component: ComponentCreator('/docs/api/workflow/schemas/ParameterValue', '138'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/ReadOnlyStates',
                component: ComponentCreator('/docs/api/workflow/schemas/ReadOnlyStates', 'cfa'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/ResourceId',
                component: ComponentCreator('/docs/api/workflow/schemas/ResourceId', 'de5'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/ResourceListOfAccessControlledResource',
                component: ComponentCreator('/docs/api/workflow/schemas/ResourceListOfAccessControlledResource', '286'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/ResourceListOfChangeItem',
                component: ComponentCreator('/docs/api/workflow/schemas/ResourceListOfChangeItem', 'd55'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/ResourceListOfTask',
                component: ComponentCreator('/docs/api/workflow/schemas/ResourceListOfTask', 'a87'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/ResultantChildTaskConfiguration',
                component: ComponentCreator('/docs/api/workflow/schemas/ResultantChildTaskConfiguration', 'b72'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/ResultField',
                component: ComponentCreator('/docs/api/workflow/schemas/ResultField', 'a01'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/ResultMatchingPattern',
                component: ComponentCreator('/docs/api/workflow/schemas/ResultMatchingPattern', '223'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/RunWorkerAction',
                component: ComponentCreator('/docs/api/workflow/schemas/RunWorkerAction', '550'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/RunWorkerActionResponse',
                component: ComponentCreator('/docs/api/workflow/schemas/RunWorkerActionResponse', 'a82'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/RunWorkerRequest',
                component: ComponentCreator('/docs/api/workflow/schemas/RunWorkerRequest', 'b38'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/RunWorkerResponse',
                component: ComponentCreator('/docs/api/workflow/schemas/RunWorkerResponse', '5e2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/SchedulerJob',
                component: ComponentCreator('/docs/api/workflow/schemas/SchedulerJob', 'dad'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/SchedulerJobResponse',
                component: ComponentCreator('/docs/api/workflow/schemas/SchedulerJobResponse', '65c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/Sleep',
                component: ComponentCreator('/docs/api/workflow/schemas/Sleep', 'c09'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/SleepResponse',
                component: ComponentCreator('/docs/api/workflow/schemas/SleepResponse', '743'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/Stack',
                component: ComponentCreator('/docs/api/workflow/schemas/Stack', '489'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/Task',
                component: ComponentCreator('/docs/api/workflow/schemas/Task', '50c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/TaskActivity',
                component: ComponentCreator('/docs/api/workflow/schemas/TaskActivity', '482'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/TaskActivityResponse',
                component: ComponentCreator('/docs/api/workflow/schemas/TaskActivityResponse', '591'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/TaskDefinition',
                component: ComponentCreator('/docs/api/workflow/schemas/TaskDefinition', 'c80'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/TaskDefinitionVersion',
                component: ComponentCreator('/docs/api/workflow/schemas/TaskDefinitionVersion', '75b'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/TaskFieldDefinition',
                component: ComponentCreator('/docs/api/workflow/schemas/TaskFieldDefinition', '936'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/TaskInstanceField',
                component: ComponentCreator('/docs/api/workflow/schemas/TaskInstanceField', '269'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/TaskStateDefinition',
                component: ComponentCreator('/docs/api/workflow/schemas/TaskStateDefinition', 'ddb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/TaskSummary',
                component: ComponentCreator('/docs/api/workflow/schemas/TaskSummary', '6dd'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/TaskTransitionDefinition',
                component: ComponentCreator('/docs/api/workflow/schemas/TaskTransitionDefinition', '175'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/TransitionTriggerDefinition',
                component: ComponentCreator('/docs/api/workflow/schemas/TransitionTriggerDefinition', '035'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/TriggerParentTaskAction',
                component: ComponentCreator('/docs/api/workflow/schemas/TriggerParentTaskAction', '5fb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/TriggerParentTaskActionResponse',
                component: ComponentCreator('/docs/api/workflow/schemas/TriggerParentTaskActionResponse', '6e7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/TriggerSchema',
                component: ComponentCreator('/docs/api/workflow/schemas/TriggerSchema', '61e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/UpdateEventHandlerRequest',
                component: ComponentCreator('/docs/api/workflow/schemas/UpdateEventHandlerRequest', 'cc0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/UpdateMatchingTasksActivity',
                component: ComponentCreator('/docs/api/workflow/schemas/UpdateMatchingTasksActivity', '2ca'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/UpdateMatchingTasksActivityResponse',
                component: ComponentCreator('/docs/api/workflow/schemas/UpdateMatchingTasksActivityResponse', 'dae'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/UpdateTaskDefinitionRequest',
                component: ComponentCreator('/docs/api/workflow/schemas/UpdateTaskDefinitionRequest', '7a0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/UpdateTaskRequest',
                component: ComponentCreator('/docs/api/workflow/schemas/UpdateTaskRequest', 'b70'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/UpdateWorkerRequest',
                component: ComponentCreator('/docs/api/workflow/schemas/UpdateWorkerRequest', 'a0e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/ValueConstraints',
                component: ComponentCreator('/docs/api/workflow/schemas/ValueConstraints', '82d'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/VersionInfo',
                component: ComponentCreator('/docs/api/workflow/schemas/VersionInfo', 'af0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/Worker',
                component: ComponentCreator('/docs/api/workflow/schemas/Worker', 'ae6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/WorkerConfiguration',
                component: ComponentCreator('/docs/api/workflow/schemas/WorkerConfiguration', 'a1c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/WorkerConfigurationResponse',
                component: ComponentCreator('/docs/api/workflow/schemas/WorkerConfigurationResponse', '150'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/api/workflow/schemas/WorkerStatusTriggers',
                component: ComponentCreator('/docs/api/workflow/schemas/WorkerStatusTriggers', 'c6f'),
                exact: true,
                sidebar: "defaultSidebar"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/docs/excel',
    component: ComponentCreator('/docs/excel', '119'),
    routes: [
      {
        path: '/docs/excel',
        component: ComponentCreator('/docs/excel', 'a50'),
        routes: [
          {
            path: '/docs/excel',
            component: ComponentCreator('/docs/excel', '737'),
            routes: [
              {
                path: '/docs/excel/',
                component: ComponentCreator('/docs/excel/', '735'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/excel/functions/Allocations',
                component: ComponentCreator('/docs/excel/functions/Allocations', '026'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/excel/functions/Blocks',
                component: ComponentCreator('/docs/excel/functions/Blocks', 'de6'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/excel/functions/Corporate Actions',
                component: ComponentCreator('/docs/excel/functions/Corporate Actions', '21c'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/excel/functions/Executions',
                component: ComponentCreator('/docs/excel/functions/Executions', 'be2'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/excel/functions/Instrument Properties',
                component: ComponentCreator('/docs/excel/functions/Instrument Properties', '025'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/excel/functions/Instruments',
                component: ComponentCreator('/docs/excel/functions/Instruments', '14a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/excel/functions/Orders',
                component: ComponentCreator('/docs/excel/functions/Orders', '51a'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/excel/functions/Placements',
                component: ComponentCreator('/docs/excel/functions/Placements', '360'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/excel/functions/Portfolio Group Properties',
                component: ComponentCreator('/docs/excel/functions/Portfolio Group Properties', '6eb'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/excel/functions/Portfolio Groups',
                component: ComponentCreator('/docs/excel/functions/Portfolio Groups', 'cbe'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/excel/functions/Portfolio Properties',
                component: ComponentCreator('/docs/excel/functions/Portfolio Properties', '5e9'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/excel/functions/Portfolios',
                component: ComponentCreator('/docs/excel/functions/Portfolios', '78e'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/excel/functions/Properties',
                component: ComponentCreator('/docs/excel/functions/Properties', '2d7'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/excel/functions/Quotes',
                component: ComponentCreator('/docs/excel/functions/Quotes', '6b1'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/excel/functions/Reconciliation',
                component: ComponentCreator('/docs/excel/functions/Reconciliation', '0ae'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/excel/functions/Reference Portfolios',
                component: ComponentCreator('/docs/excel/functions/Reference Portfolios', '842'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/excel/functions/Scopes',
                component: ComponentCreator('/docs/excel/functions/Scopes', 'cf0'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/excel/functions/Templates',
                component: ComponentCreator('/docs/excel/functions/Templates', 'cca'),
                exact: true,
                sidebar: "defaultSidebar"
              },
              {
                path: '/docs/excel/functions/Utilities',
                component: ComponentCreator('/docs/excel/functions/Utilities', '235'),
                exact: true,
                sidebar: "defaultSidebar"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '*',
    component: ComponentCreator('*'),
  },
];
